<script setup>
import {ref, computed, onMounted} from 'vue'
import {useStore} from 'vuex'
import {Toast} from 'vant'
import {useI18n} from 'vue-i18n'
import {doSell, getMemberAmount, getPaymentList, getQuickAmount} from '@/api/api'
import QuickAmountPicker from './QuickAmountPicker.vue'

const store = useStore()

// mapstate--皮肤对应的图片
const themeImgs = computed(() => store.state.themeImgs)

const userInfos = computed(() => store.state.userInfos)

const { t, locale } = useI18n()

// 只能输入整数跟2位小数
const formatter = (value) => value.match(/^\d*(\.?\d{0,2})/g)[0]

const configData = ref({})

const currentAmount = ref('')

//  获取输入值
const handleInput = (event) => {
  amountFilter(event, -1)
}

//  选择金额
const handleSelected = (item, index) => {
  amountFilter(item.amount, index)
}

//  选择金额的类型转换
const amountTypeTrans = (amount) => {
  if (typeof amount === 'number') {
    return amount.toString()
  }
  return amount
}

//  选中样式
const amountFilter = (e, i) => {
  const amount = amountTypeTrans(e)
  if(i !== -1) {
    currentAmount.value = currentAmount.value === amount ? '' : amount
  }
}

// 定义收款方式列表
const paymentWayList = ref([
  {
    id: 3,
    icon: themeImgs.value.commonPaymentIconVn3,
    name: 'Bank'
  },
  {
    id: 2,
    icon: themeImgs.value.commonPaymentIconVn2,
    name: 'Momo'
  },
  {
    id: 1,
    icon: themeImgs.value.commonPaymentIconVn1,
    name: 'Zalo'
  }
])

// 存储多选的收款方式id值
const paymentIdArr = ref([])
// 选择收款方式
const selectPaymentWay = (i) => {
  if (paymentIdArr.value.includes(i)) {
    //includes()方法判断是否包含某一元素,返回true或false表示是否包含元素，对NaN一样有效
    //filter()方法用于把Array的某些元素过滤掉，filter()把传入的函数依次作用于每个元素，然后根据返回值是true还是false决定保留还是丢弃该元素：生成新的数组
    paymentIdArr.value = paymentIdArr.value.filter(function (ele) {
      return ele !== i;
    });
  } else {
    paymentIdArr.value.push(i);
  }
  console.log('paymentIdArr', paymentIdArr)
}

//`获取快捷金额
const initConfig = () => {
  getQuickAmount({ key: 'withdrawa_key' }).then(res => {
    if (res.result === 200000) {
      configData.value = res.data.data
    }
  })
}

// 可售余额
const availableBalance = ref(0)

// 获取余额
const handleGetMemberAmount = () => {
  getMemberAmount().then(res => {
    if (res.result == 200000) {
      availableBalance.value = res.data.info.currency_money
    }
  })
}

const userAmount = store.state.memberAmountObj.currency_money

const systemConfig = store.state.sysConfig

// 获取收付款列表数据
const paymentList = ref([])
const handleGetPaymentList = () => {
  getPaymentList().then(res => {
    if (res.result === 200000) {
      const arr = []
      if (Object.keys(res.data).length > 0) {
        Object.values(res.data).forEach(item => {
          arr.push(item.type)
        })
      }
      paymentList.value = arr
      // paymentList.value = res.data
    }
  })
}

onMounted(() => {
  initConfig()
  handleGetMemberAmount()
  handleGetPaymentList()
})

//  出售密码
const isPayPasswordShow = ref(false)
const pay_password = ref('')
const loading = ref(false)
const btnClock = ref(false)
//  出售执行
const handleSDosale = () => {

  if (loading.value) {
    return
  }

  loading.value = true
  let params = {
    amount: currentAmount.value,
    pay_type: paymentIdArr.value.sort((a, b) => {
      return a - b
    }).toString(),
    type: 2,
    pay_password: pay_password.value
  }
  doSell(params).then(res => {
    loading.value = false
    btnClock.value = false
    if (res.result === 200000) {
      isPayPasswordShow.value = false
      pay_password.value = ''
      currentAmount.value = ''
      paymentIdArr.value = []
      Toast.success(t('上架成功'))
    } else {
      Toast.fail(res.msg)
    }
  }).catch(error => {
    loading.value = false
    btnClock.value = false
    Toast.fail(t('操作失败，请检查网络重试或者联系客服'))
  })
}

const onBeforeClose = (action) => {
  // 点击了确定按钮
  if (action === "confirm") {
    if (!pay_password.value) {
      Toast.fail(t('请输入支付密码'))
      return false
    } else {
      handleSDosale()
    }
  }
  // 点击了取消按钮
  else {
    isPayPasswordShow.value = false
    pay_password.value = ''
    btnClock.value = false
  }
}

//  提交验证
const onSubmit = () => {
  if (userInfos.value.is_name === 1) {
    return Toast.fail(t('请先进行实名认证'))
  }
  if (userInfos.value.is_name === 3) {
    return Toast.fail(t('实名审核中'))
  }
  if (userInfos.value.is_withdraw === 1) {
    return Toast.fail(t('该功能已禁用'))
  }

  if (!currentAmount.value) {
    return Toast.fail(t('请输入出售数量'))
  }
  if (parseFloat(systemConfig.min_amount) > parseFloat(currentAmount.value)) {
    return Toast.fail(t('出售数量不能小于单次最小数量'))
  }

  if (parseFloat(availableBalance.value) < parseFloat(currentAmount.value)) {
    return Toast.fail(t('当前账户余额不足'))
  }

  if (!paymentIdArr.value.length) {
    return Toast.fail(t('请选择收款方式'))
  }

  const isIncluded = paymentIdArr.value.every(type => paymentList.value.includes(type))
  if (!isIncluded) {
    store.commit('setPaymentDialogFlag', true)
    return Toast.fail(t('请先添加收付款方式'))
  }

  isPayPasswordShow.value = true
}

</script>

<template>
  <div class="sell-box">
    <p class="sell-title">Số tiền nhanh</p>
    <QuickAmountPicker
        :data="configData.dispensingJson"
        :amount="currentAmount"
        @handleAmount="handleSelected"
    />
    <p class="sell-title">Số tiền bất kỳ</p>
    <van-field
        v-model="currentAmount"
        type="number"
        :formatter="formatter"
        label-class="pay-fixed-label"
        input-align="right"
        :colon="true"
        :placeholder="`Nhập số lượng bán (B)`"
        :clearable="true"
        clear-icon="clear"
        @update:model-value="handleInput"
    >
      <template #left-icon>
        <div class="field-prefix">
          <van-image :src="themeImgs.commonTansIcon2"/>
          <span>{{ currentAmount || '0.00' }}</span>
        </div>
      </template>
    </van-field>

    <div class="quick-recharge-info">
      <span>Số dư có thể bán</span>
      <p>{{ userAmount }} B</p>
    </div>
    <div class="quick-recharge-info">
      <span>Số lượng bán tối thiểu một lần</span>
      <p>{{ systemConfig.min_amount }} B</p>
    </div>
    <div class="quick-recharge-info">
      <span>Phương thức nhận tiền</span>
      <div class="payment-method-box">
        <div v-for="type in paymentWayList" :key="type" :class="{'active':paymentIdArr.includes(type.id)}"
             class="method-item" @click="selectPaymentWay(type.id)">
          <van-image :src="type.icon"/>
        </div>
      </div>
    </div>

    <div class="sale-btn-box">
      <van-button :disabled="btnClock" block @click="onSubmit">
        {{ t('出售') }}
      </van-button>
    </div>
  </div>

  <!-- 支付密码弹出框 -->
  <van-dialog
      v-model:show="isPayPasswordShow"
      :before-close="onBeforeClose"
      show-cancel-button :title="t('请输入支付密码')"
      teleport="body"
      class="sellCoin-payPsd-dialog"
  >
    <van-field
        v-model="pay_password"
        class="cancel-order-dialog-cell"
        :label="t('支付密码')"
        maxlength="6"
        :placeholder="t('请输入支付密码')"
        type="password"
    />
  </van-dialog>
</template>

<style scoped lang="less">
.sell-box {
  padding: 0 15px;

  .sell-title {
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: rgba(0, 0, 0, 0.8);
    margin: 10px 0;
  }

  .field-prefix {
    display: flex;
    align-items: center;

    span {
      margin-left: 5px;
    }
  }

  .van-field__left-icon {
    .van-image {
      width: 20px;
      height: 20px;
    }
  }

  .quick-recharge-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;

    span {
      font-family: PingFang SC;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #666;
    }

    p {
      font-family: PingFang SC;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      color: #09794D;
    }

    .payment-method-box {
      display: flex;
      align-items: center;

      .method-item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        margin-right: 15px;

        &.active {
          border: 1px solid var(--textColor);
          border-radius: 8px;
        }

        .van-image {
          width: 24px;
          height: 24px;
        }
      }
    }
  }

  .sale-btn-box {
    margin-top: 48px;
    border-radius: 8px;

    .van-button {
      border-radius: 8px;
      background: #2EBD85;
    }
  }
}
</style>

<style lang="less">
.sellCoin-payPsd-dialog {
  .van-dialog__header {
    padding: 16px 0;
    font-weight: 500;
    font-size: 18px;
    //color: var(--orderDialogTextColor);
    color: #535C66;
  }

  .van-button--default {
    font-family: PingFang SC;
    font-size: 17px;
    font-weight: 400;
    color: #535C66;
  }

  .cancel-order-dialog-cell {
    flex-direction: column;
    background: none;

    input::placeholder {
      color: rgba(83, 92, 102, 0.6);
    }

    textarea::placeholder {
      color: rgba(83, 92, 102, 0.6);
    }

    .van-field__label {
      margin-bottom: 7px;

      label {
        color: #535C66;
        font-family: PingFang SC;
        font-size: 16px;
        font-weight: 500;
        white-space: nowrap;
      }
    }

    .van-field__control {
      color: #535C66;
      font-family: PingFang SC;
      font-size: 14px;
      font-weight: 400;
    }
  }
}

.sell-box {
  .van-field__left-icon {
    display: flex;
    align-items: center;
    margin-right: 21px;
  }
}
</style>