<script setup>
import {computed, ref, watch, defineProps} from 'vue'
import {useStore} from 'vuex'
import {Toast} from 'vant'
import { useI18n } from 'vue-i18n'
import moment from 'moment'
import useClipboard from 'vue-clipboard3'
import OrderList from './OrderList.vue'
import PaySheet from './PaySheet.vue'
import { getCreateOrderPay, postCreateCreateOrder } from '@/api/api'
import MessageIcon from '@/assets/theme/green/images/checkoutCounter/amount_icon.png'

// const initCurrentData = {
//   count_currency: '',
//   created_at: '',
//   currency: '',
//   freeze_currency: '',
//   id: 0,
//   order_sn: '',
//   pay_type: '',
//   status: '',
//   traded_currency: '',
//   type: 0
// }

const props = defineProps({
  showPayMessage: {
    type: Boolean,
    default: false
  }
})

const { t } = useI18n()
const store = useStore()

// mapstate--皮肤对应的图片
const themeImgs = computed(() => store.state.themeImgs)

const uid = computed(() => store.state.loginInfos.uid)

const quickSellNotify = computed(() => store.state.quickSellNotify)

const publicConfig = computed(() => store.state.publicConfig)

//  匹配订单分页条件
const matchPage = ref(1)
//  匹配订单
const matchSellCurrencyList = ref([])
const matchItems = computed(() => {
  return matchSellCurrencyList.value.slice(0, matchPage.value * 6)
})

//  分页
const updatePage = (isMatch) => {
  if (isMatch) {
    matchPage.value = matchItems.value.length === matchSellCurrencyList.value.length ? 1 : matchPage.value + 1
  } else {
    // sellPage.value = displayedItems.value.length === sellCurrencyList.value.length ? 1 : sellPage.value + 1
  }
}

const handleExternalAddress = (url) => {
  window.location.href = url
}

//  三方传来的订单信息
const thirdOrderData = ref({})

//  获取卖币列表
const initFetch = () => {
  getCreateOrderPay({
    ...thirdOrderData.value,
    uid: uid.value
  }).then(res => {
    if (res.result === 200000) {
      if (res.data.data && res.data.data.payUrl) handleExternalAddress(res.data.data.payUrl)
      matchSellCurrencyList.value = res.data.match_sell_currency || []
      // sellCurrencyList.value =  res.data.sell_currency
      const quickSellPayInfo = sessionStorage.getItem('quickSellPayInfo')
      if (!quickSellPayInfo) {
        handleMatchOrder(matchSellCurrencyList.value)
      }
    }
  })
}
const handleMatchOrder = (list) => {
  const newData = list.find((item) => numberFilter(thirdOrderData.value.amount) === numberFilter(item.currency))
  if (newData) {
    getSellOrder(newData)
  } else {
    if (publicConfig.value.is_smart_push_checkout_orders === '0') {
      store.commit('setQuickSellNotify', '6')
    }
  }
}


const token = computed(() => store.state.loginInfos.token)
watch(() => [token.value, props.showPayMessage, quickSellNotify.value], () => {
  const checkoutCounterData = sessionStorage.getItem('checkoutCounterData')
  thirdOrderData.value = JSON.parse(checkoutCounterData)
  // console.log('checkoutCounterData', checkoutCounterData)
  if (token.value && checkoutCounterData && !props.showPayMessage && quickSellNotify.value !== '1') {
    initFetch()
  }
}, { immediate: true })

//  订单匹配
// watch(() => [matchSellCurrencyList.value, quickSellNotify.value], () => {
//   const newData = matchSellCurrencyList.value.find((item) => numberFilter(thirdOrderData.value.amount) === numberFilter(item.currency))
//   if (newData) {
//     getSellOrder(newData)
//   } else {
//     if (publicConfig.value.is_smart_push_checkout_orders === '0' && quickSellNotify.value === '0') {
//       console.log('会执行多次吗？')
//       store.commit('setQuickSellNotify', '6')
//     }
//   }
// })

//  当前选择的订单1
// const currentData1 = ref(initCurrentData)
//  当前选择的订单2
// const currentData2 = ref(initCurrentData)

// const handleCurrentOrder = (type, item) => {
//   if (type === 1) {
//     currentData2.value = initCurrentData
//     currentData1.value = item
//   }
//
//   if (type === 2) {
//     currentData1.value = initCurrentData
//     currentData2.value = item
//   }
// }


//  拉取卖币订单
const sellPayInfo = ref({})

const numberFilter = (value) => {
  if (typeof value === 'string') {
    return parseFloat(value)
  }
  return value
}

//  买单执行
const getSellOrder = (data) => {
  postCreateCreateOrder({
    order_sn: thirdOrderData.value.order_sn,
    sell_id: data.id
  }).then(res => {
    if (res.result === 200000) {
      // console.log('res.data.info', res.data.info)
      // sellPayInfo.value = res.data.info
      store.commit('setQuickSellNotify', '1')
      sessionStorage.setItem('quickSellPayInfo', JSON.stringify(res.data.info))
    } else {
      Toast.fail(res.msg)
    }
  })
}

// const onSubmit = () => {
//   // store.commit('setQuickSellNotify', '3')
//   if (orderData.value.id === 0) {
//     return Toast.fail('Vui lòng chọn số tiền thanh toán')
//   }
//   getSellOrder()
// }

// 复制
const {toClipboard} = useClipboard()
//  copy订单信息
const handleCopy = async (content) => {
  try {
    await toClipboard(content)
    Toast.success(t('复制成功'))
  } catch (e) {
    console.error(e)
  }
}

//  时间转换
const formattedDate = (time) => {
  return moment(time * 1000).format('YYYY-MM-DD HH:mm:ss')
}
</script>

<template>
  <div class="order-box">
    <div class="order-header">
      <van-image :src="require('@siteResources/logo/home_logo.png')" class="logo" />
      <div class="semicircle"></div>
    </div>
    <div class="third-order-info">
      <h6>Số lượng xu cần mua</h6>
      <h3>₫ {{ thirdOrderData.amount }}</h3>
      <div class="copy-text-box">
        <p>{{ thirdOrderData.order_sn }}</p>
        <van-image
            :src="themeImgs.commonCopyIcon"
            @click.prevent="handleCopy(thirdOrderData.order_sn)"
        />
      </div>
      <div class="create-order-time">
        <span>Thời gian tạo đơn hàng：</span>
        <span>
          {{ formattedDate(thirdOrderData.time) }}
        </span>
      </div>
    </div>

    <div class="order-content">
      <div class="order-title">
        <div class="order-title-icon">
          <van-icon :name="MessageIcon" />
        </div>
        <span>
          Không có số lượng xu bạn cần mua , Vui lòng chọn đơn  hàng nhanh sau đây
        </span>
      </div>

<!--      <div class="order-title order-list-title">-->
<!--        <div class="order-title-icon">-->
<!--          <van-icon :name="themeImgs.commonPaymentIconVn3" />-->
<!--        </div>-->
<!--        <span>Bank</span>-->
<!--      </div>-->

<!--      <div class="order-title">-->
<!--        <span>Số lượng xu tương tự</span>-->
<!--      </div>-->
<!--      <OrderList-->
<!--          v-model:current-data="orderData"-->
<!--          :list="matchItems"-->
<!--          :count="matchSellCurrencyList.length"-->
<!--          :page="matchPage"-->
<!--          @update-page="updatePage(true)"-->
<!--      />-->

<!--      <div class="order-title">-->
<!--        <span>Số tiền nhanh</span>-->
<!--      </div>-->
<!--      <OrderList-->
<!--          v-model:current-data="orderData"-->
<!--          :count="sellCurrencyList.length"-->
<!--          :page="sellPage"-->
<!--          :list="displayedItems"-->
<!--          @update-page="updatePage(false)"-->
<!--      />-->
    </div>
<!--    <div class="submit-box">-->
<!--      <van-button type="success" class="submit-btn" @click="onSubmit">-->
<!--        bán-->
<!--      </van-button>-->
<!--    </div>-->
  </div>

  <PaySheet
      :third-order="thirdOrderData.order_sn"
      :current-data="sellPayInfo"
      :message="matchSellCurrencyList.length > 0 ? 'vui lòng thử mua lại với số tiền sau' : 'vui lòng thử lại sau.'"
  >
    <template #list v-if="quickSellNotify === '6' && matchSellCurrencyList.length > 0">
      <OrderList
          :list="matchItems"
          :count="matchSellCurrencyList.length"
          :page="matchPage"
          @update-page="updatePage(true)"
      />
    </template>
  </PaySheet>

</template>

<style scoped lang="less">
.order-box {
  //padding: 20px 15px;
  //position: relative;
  height: 100%;

  .order-header {
    width: 100%;
    //position: fixed;
    height: 73px;
    display: flex;
    justify-content: center;
    //align-items: center;
    background: #C8F3E2;
    //z-index: 1;
    position: relative;
    .semicircle {
      position: absolute;
      bottom: -73px;
      background: #C8F3E2;
      width: 100%;
      height: 146px;
      border-radius: 50%;
      z-index: -1;
    }
    .logo {
      display: block;
      width: 110px;
      margin-top: 20px;
      :deep(.van-image__img) {
        height: auto;
      }
    }
  }

  .third-order-info {
    margin: 83px 10px 0 10px;
    box-shadow: 0 1px 4px 0 rgba(94, 94, 94, 0.45);
    border-radius: 8px;
    text-align: center;
    padding: 40px 30px;
    h6 {
      font-family: PingFang SC;
      font-size: 16px;
      font-weight: 500;
      line-height: 16px;
      color: #000;
    }
    h3 {
      font-family: PingFang SC;
      font-size: 26px;
      font-weight: 500;
      color: #2EBD85;
      margin: 10px 0;
    }

    .copy-text-box {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 16px;
      p {
        font-size: 16px;
        line-height: 16px;
        color: #000000;
        margin-right: 10px;
      }
      .van-image {
        width: 16px;
        height: 16px;
      }
    }

    .create-order-time {
      span {
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 16px;
        text-align: left;
        &:first-child {
          font-size: 16px;
          color: #000000;
        }
        &:last-child {
          font-size: 12px;
          color: rgba(0, 0, 0, 0.6);
        }
      }
    }
  }

  .order-content {
    padding: 25px 15px;
    height: 100%;
    overflow: auto;
  }

  .order-title {
    //margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    .order-title-icon {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: #C0FCE5;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
    }

    span {
      width: calc(100% - 40px);
      font-family: PingFang SC;
      font-size: 13px;
      font-weight: 600;
      line-height: 20px;
      color: #000000;
    }
  }

  .order-list-title {
    align-items: center;
  }

  .submit-box {
    z-index: 1;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 80px;
    display: flex;

    .submit-btn {
      width: calc(100% - 30px);
      height: 40px;
      //border-top-left-radius: 8px;
      //border-top-right-radius: 8px;
      border-radius: 8px;
      background: #2EBD85;
      line-height: 40px;
      text-align: center;
      font-family: PingFang SC;
      font-size: 16px;
      font-weight: 500;
      color: #fff;
      margin: 20px auto;
    }
  }
}
</style>

<style lang="less">
.order-message-icon {
  .van-icon__image {
    width: 17px;
  }
}
</style>
