<template>
  <div class="order-wrap">
    <div class="order-header-box">
      <van-nav-bar
          :border="false"
          left-arrow
          :title="t('资金明细')"
          @click-left="onClickLeft"
      />
      <div class="first-tab-wrap" @click="showTypeActions=true">{{ curTypeName }}
        <van-image :src="themeImgs.mySolidDownIcon"/>
      </div>
      <div class="date-box" @click="showDateCalendar=true">
        <div class="date-item-box">
          <van-image :src="themeImgs.commonCalendarIcon"/>
          <span class="date">{{ startDate }}</span>
          <van-image :src="themeImgs.commonArrowDownIcon"/>
        </div>
        <p>{{ t('至') }}</p>
        <div class="date-item-box">
          <van-image :src="themeImgs.commonCalendarIcon"/>
          <span class="date">{{ endDate }}</span>
          <van-image :src="themeImgs.commonArrowDownIcon"/>
        </div>
      </div>
    </div>

    <div class="order-main-wrap">
      <div v-if="rebateList.length>0" class="order-content-wrap">
        <van-list
            v-model:loading="loadingMore"
            :finished="finishedMore"
            :immediate-check="false"
            :finished-text="t('没有更多了')"
            @load="onLoadMore"
        >
          <div v-for="(item,index) in rebateList" :key="index" class="order-item-box">
            <div class="item-box">
              <span>{{ item.type }}</span>
              <span class="status">{{ item.status }}</span>
            </div>

            <div class="item-box">
              <span class="money">{{ item.currency_money }}</span>
              <span class="date">{{ item.created_at }}</span>
            </div>
          </div>
        </van-list>
      </div>
      <van-empty
          v-else
          :image="themeImgs.commonEmptyImg"
          class="custom-image"
          :description="t('暂无数据') + '～'"
      />
    </div>
    <!-- 日历插件 -->
    <van-calendar v-model:show="showDateCalendar" :max-date="maxDate" :min-date="minDate" :show-confirm="false" allow-same-day
                  type="range" @confirm="handleGetStartEndDate"/>
    <!-- 查询类型 -->
    <van-action-sheet
        v-model:show="showTypeActions"
        :actions="typeActions"
        :cancel-text="t('取消')"
        close-on-click-action
        @cancel="showTypeActions=false"
        @select="handleSelectActions"
    />
  </div>
</template>

<script>
import {computed, onMounted, reactive, ref} from 'vue'
import {getFundRecord} from '@/api/api'
import moment from 'moment'
import useClipboard from 'vue-clipboard3'
import {Toast} from 'vant'
import {useStore} from "vuex";
import { useI18n } from 'vue-i18n'

export default {
  setup() {
    const store = useStore();
    const { t } = useI18n()

    // 点击导航栏左侧返回键/取消按钮
    const onClickLeft = () => {
      history.back()
    }
    // 是否显示日历插件
    const showDateCalendar = ref(false)
    // 存储开始日期
    const startDate = ref(moment().format("YYYY-MM-DD"))
    // 存储结束日期
    const endDate = ref(moment().format("YYYY-MM-DD"))
    // 日期组件的最小日期--半年前
    const minDate = new Date(new Date().setMonth(new Date().getMonth() - 6));
    // 日期组件的最大日期--今天
    const maxDate = new Date()
    // 选择日期区间后
    const handleGetStartEndDate = (date) => {
      apiParams.page = 1
      apiParams.pageSize = 20
      rebateList.value = []
      finishedMore.value = false
      const [start, end] = date
      startDate.value = moment(start).format("YYYY-MM-DD")
      endDate.value = moment(end).format("YYYY-MM-DD")
      showDateCalendar.value = false
      handleGetFundRecord()
    }
    // 当前交易类型type
    const curTypeName = ref(t('全部'))
    // 是否显示交易类型动作面板
    const showTypeActions = ref(false)
    // 交易类型actions
    const typeActions = [
      {name: t('全部'), type: ""},
      {name: t('人工加款'), type: 1},
      {name: t('人工扣款'), type: 2},
      // {name: t('买币-成功'), type: 4},
      {name: 'Giao dịch mua thành công-Thành công', type: 4}, //  ctoc买币成功
      {name: 'Mua xu nhanh chóng-Thành công', type: 37}, // 快捷买币成功
      {name: t('上架货币'), type: 3},
      {name: t('上架货币强制退回'), type: 13},
      {name: t('卖币成功'), type: 14},
      {name: t('api充值'), type: 9},
      {name: t('api提现'), type: 7},
      {name: t('佣金'), type: 15},
      {name: t('用户转账'), type: 17},
      {name: t('用户收款'), type: 18},
      {name: t('代理转账'), type: 21},
      {name: t('代理收款'), type: 22},
      {name: 'tiền thưởng đăng ký', type: 39},
      {name: 'Chiết khẩu nạp tiền', type: 40},
      {name: 'tiền phí mua nhanh', type: 41}
      // {name: t('USDT充值'), type: 35},
      // {name: t('USDT提现'), type: 34},
      // {name: t('USDT提现退回'), type: 36}
    ]
    // 选择交易类型
    const handleSelectActions = action => {
      apiParams.type = action.type
      apiParams.page = 1
      apiParams.pageSize = 20
      rebateList.value = []
      finishedMore.value = false
      curTypeName.value = action.name
      handleGetFundRecord()
    }
    // 存储返佣列表数据
    const rebateList = ref([])
    // 存储接口默认请求参数
    const apiParams = reactive({
      type: "",
      page: 1,
      pageSize: 20,
      start: `${startDate.value} 00:00:00`,
      end: `${endDate.value} 23:59:59`
    })
    // 获取返佣列表数据
    const handleGetFundRecord = (more) => {
      apiParams.start = `${startDate.value} 00:00:00`
      apiParams.end = `${endDate.value} 23:59:59`
      console.log('查看加载更多的more', more)
      if (more) {
        apiParams.pageSize += 20
      }
      getFundRecord(apiParams).then(res => {
        if (res.result == 200000) {
          if (!more) {
            rebateList.value = res.data.list.list
          } else {
            rebateList.value = res.data.list.list
            loadingMore.value = false
            if (res.data.list.list.length >= res.data.list.count) {
              finishedMore.value = true
            }
          }
        } else {
          Toast.fail(res.msg)
        }
      })
    }
    onMounted(() => {
      handleGetFundRecord()
    })
    // 上拉加载
    const loadingMore = ref(false);
    const finishedMore = ref(false);
    const onLoadMore = () => {
      console.log('加载更多')
      handleGetFundRecord(true)
    };
    // 复制
    const {toClipboard} = useClipboard()
    const handleCopy = async (content) => {
      try {
        await toClipboard(content)
        Toast.success(t('复制成功'))
      } catch (e) {
        console.error(e)
      }
    }
    // mapstate--皮肤对应的图片
    const themeImgs = computed(() => store.state.themeImgs)
    return {
      onClickLeft,
      showDateCalendar,
      startDate,
      endDate,
      minDate,
      maxDate,
      handleGetStartEndDate,
      rebateList,
      loadingMore,
      finishedMore,
      onLoadMore,
      handleCopy,
      apiParams,
      curTypeName,
      showTypeActions,
      typeActions,
      handleSelectActions,
      themeImgs,
      t
    }
  }
}
</script>

<style lang="less" scoped>
.order-wrap {
  //margin-top: 60px;
  height: 100%;
  overflow: hidden;
  .order-header-box {
    position: fixed;
    width: 100%;
    height: 145px;
    top: 0;
    z-index: 1;
    background: var(--headBgColor);

    .first-tab-wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      //background: var(--headBgColor);
      font-family: PingFang SC;
      font-size: 15px;
      font-weight: 500;
      line-height: 21px;
      height: 21px;
      //color: var(--textCommonColor);
      color: rgba(0, 0, 0, 1);

      .van-image {
        width: 16px;
        height: 16px;
        margin-left: 2px;
      }
    }

    .date-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 351px;
      height: 36px;
      padding: 0 20px;
      margin: 30px auto 23px;
      background: linear-gradient(90deg, #00C800 0%, #FDD631 100%);
      border-radius: 17px;
      //color: var(--textCommonColor);
      color: #FFF;

      .date-item-box {
        display: flex;
        align-items: center;

        .van-image {
          width: 16px;
          height: 16px;
        }

        p {
          font-family: PingFangSC;
          font-size: 16px;
          //color: var(--textCommonColor);
          color: #FFF;
        }

        .date {
          margin: 0 4px 0 10px;
          font-size: 14px;
          font-family: 'DINOffcPro';
          //color: var(--textCommonColor);
        }
      }
    }
  }

  .order-main-wrap {
    padding-top: 145px;
    //height: calc(100vh - 161px);
    //background: var(--mainBgColor);
    //overflow: hidden;
    height: 100%;
    overflow: auto;
    .order-content-wrap {
      //height: 100%;
      padding: 0 24px 24px;
      //overflow: scroll;

      .order-item-box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 82px;
        border-bottom: 1px solid rgba(151, 151, 151, 0.1);

        &:last-child {
          border-bottom: none;
        }

        .item-box {
          display: flex;
          justify-content: space-between;


          &:last-of-type {
            margin-top: 8px;

            span {
              align-self: end;
            }
          }

          span {
            color: var(--textCommonColor);

            &.date {
              opacity: 0.5;
            }
          }

          .status {
            font-size: 16px;
          }

          .money {
            font-family: 'DIN Offc Pro';
            font-size: 20px;
            font-weight: 500;
          }
        }
      }
    }
  }

  :deep(.van-action-sheet__item) {
    padding: 5px 0;
  }
}
</style>
