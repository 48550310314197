export const useGetAvatar = () => {
    const getAvatar = (id) => {
        let avatar = ''
        try {
            avatar = require(`@/assets/images/avatars/${id}.jpeg`)
        } catch (e) {
            avatar = require(`@/assets/images/avatars/1.jpeg`)
        }
        // return avatar
        return require(`@/assets/theme/green/images/common/avatar.png`)
    }

    return {
        getAvatar
    }
}
