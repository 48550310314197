<script setup>
import {computed, onMounted, ref} from 'vue'
import BuyCtoc from './BuyCtoc.vue'
import FastRecharge from './FastRecharge.vue'
import {getQuickAmount} from '@/api/api'

//  顶部外层tabs
const tabs = [
  { id: 1, label: 'Mua nhanh' },
  { id: 2, label: 'Mua CtoC' },
]

//  buy的tab,这是tab的索引值
const activeTab = ref(0)

//  tab的切换
const handleTab = (currentItem) => {
  activeTab.value = currentItem.name
  if (currentItem.name === 0) {
    initConfig()
  }
}

const component = computed(() => {
  if (activeTab.value === 1) {
    return BuyCtoc
  }
  return FastRecharge
})

//`获取快捷金额
const configData = ref({})
const initConfig = () => {
  getQuickAmount({ key: 'paymen_key' }).then(res => {
    if (res.result === 200000) {
      configData.value = res.data.data
    }
  })
}

onMounted(() => {
  console.log('这是买币页面 onMounted')
  initConfig()
})

</script>

<template>
  <div class="buy-box">
    <van-tabs
        v-model:active="activeTab"
        offset-top="64"
        class="buy-tabs"
        background="none"
        :line-height="0"
        title-active-color="#fff"
        title-inactive-color="rgba(0, 0, 0, 0.6)"
        @click-tab="handleTab">
      <van-tab
          v-for="item in tabs"
          :title="item.label"
          title-class="buy-tab">
        <template #default>
          <component :is="component" :config-data="configData" />
        </template>
      </van-tab>
    </van-tabs>
  </div>
</template>

<style scoped lang="less">
.buy-box {
  padding: 0 15px 74px 15px;
  margin-top: 20px;
}
</style>
<style lang="less">
.buy-tabs {
  .van-tabs__wrap {
    border-radius: 6px;
    background: linear-gradient(90deg, #EDD33A 0%, #86E8C2 53.22%, #EDD33A 100% );
  }
  .buy-tab {
    border-radius: 6px;
    &.van-tab--active {
      background: #09794D !important;
      border-radius: 6px;
    }
    //&:first-child {
    //  background: linear-gradient(90deg, #EDD33A 0%, #86E8C2 53.22%);
    //}
    //&:nth-child(2) {
    //  background: linear-gradient(90deg, #86E8C2 48.47%, #EDD33A 100%);
    //}
  }
}
</style>