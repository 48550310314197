<script setup>
import {defineProps, onBeforeUnmount, onMounted, ref, onActivated, onDeactivated} from 'vue'
import {useRouter, useRoute} from 'vue-router'
import TradeCount from './TradeCount.vue'
import PayTypeCount from './PayTypeCount.vue'
import CategoryChart from '@/viViews/RealTimeTrade/components/CategoryChart.vue'

const props = defineProps({
  isHome: {
    type: Boolean,
    default: true,
  },
  type: {
    type: Number,
    default: 0
  }
})

const route = useRoute()

//  总的数据
const totalVolumeData = ref({
  tradeVolume: 0,
  orderVolume: 0,
  successRate: '0%'
})

//  分类的统计
const categories = ref({
  zaloOrder: 0,
  zaloRate: '0%',
  momoOrder: 0,
  momoRate: '0%',
  bankOrder: 0,
  bankRate: '0%',
})

//  成单量
let totalOrderVolume = { min: 4932840000, max: 7203940000 }

//  总成单量到成交量的差额
const countRange = { min: 1000000, max: 200000000 }

//  生成随机数
const getRandomInt = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

//  成功率
const rateFilter = (v, o) => {
  return `${((v / o) * 100).toFixed(2)}%`
}

//  差额
const generateCategoryData = (order) => {
  //  差额
  const diffCount = getRandomInt(countRange.min, countRange.max)
  const trade = order - diffCount
  return rateFilter(trade, order)
}

//  总的成交量
const initCountTrade = () => {
  //  成单量
  const countOrder = getRandomInt(totalOrderVolume.min, totalOrderVolume.max)
  //  差额
  const diffCount = getRandomInt(countRange.min, countRange.max)
  //  成交量
  const tradeCount = countOrder - diffCount
  //  成功率
  const rate = rateFilter(tradeCount, countOrder)
  totalVolumeData.value = {
    tradeVolume: tradeCount,
    orderVolume: countOrder ,
    successRate: rate
  }
  initEveryPayCountTrade(tradeCount, countOrder, rate)
}

//  每种支付类型的数据
const initEveryPayCountTrade = (tradeCount, countOrder) => {
  //  成单量总数
  // const orderCount = totalVolumeData.value.orderVolume

  const zaloOrder = Math.floor(getRandomInt(countOrder * 0.4, countOrder * 0.3))
  const zaloRate = generateCategoryData(zaloOrder)

  const momoOrder = Math.floor(getRandomInt(countOrder * 0.4, countOrder * 0.3))
  const momoRate = generateCategoryData(momoOrder)

  const bankOrder = Math.floor(countOrder - zaloOrder - momoOrder)
  const bankRate = generateCategoryData(bankOrder)

  categories.value = {
    zaloOrder: zaloOrder,
    zaloRate: zaloRate,
    momoOrder: momoOrder,
    momoRate: momoRate,
    bankOrder: bankOrder,
    bankRate: bankRate,
  }
  // console.log('111111', categories.value)
}

let timer = null
//  初始化
const initCount = () => {
  if (timer !== null) {
    clearTime()
  }
  initCountTrade()
  const mill = 5 * 1000
  timer = setTimeout(initCount, mill)
}

const $router = useRouter()

onActivated(() => {
  initCount()
})

onDeactivated(() => {
  clearTime()
})
const clearTime = () => {
  clearTimeout(timer)
  timer = null
}
</script>

<template>
  <TradeCount :data="totalVolumeData" />
  <PayTypeCount v-if="route.path === '/home'" :data="categories" />
  <CategoryChart v-if="route.path === '/realTimeTrade' && categories.zaloOrder > 0" :data="categories" :type="props.type" />
</template>

<style scoped lang="less">

</style>