<script setup>
import {defineProps, computed} from 'vue'

const props = defineProps({
  data: {
    type: Object,
    default: {
      tradeVolume: 0,
      orderVolume: 0,
      successRate: '0%'
    }
  }
})

//  交易量
const tradeCountItems = computed(() => {
  return [
    { id: 1, label: '24H Giao dịch', count: formatVolume(props.data.tradeVolume) },
    { id: 2, label: '24H đơn hàng', count: formatVolume(props.data.orderVolume) },
    { id: 1, label: 'Tỉ lệ thành công', count: props.data.successRate }
  ]
})

const formatVolume = (volume) => {
  return (volume / 1000).toFixed(2) + 'K';
}

</script>

<template>
  <div class="trade-count-box">
    <div class="trade-count-item" v-for="item in tradeCountItems" :key="item.id">
      <span>{{ item.label }}</span>
      <p>{{ item.count }}</p>
    </div>
  </div>
</template>

<style scoped lang="less">
.trade-count-box {
  height: 63px;
  margin: 20px 7px 0 7px;
  position: relative;
  background: white;
  z-index: 1;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  &::before {
    content: "";
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    background: linear-gradient(91.75deg, #2EBD85 -0.65%, #FDD431 100%);;
    z-index: -1;
    border-radius: inherit;
  }
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid transparent;
    background: white;
    background-clip: padding-box;
    z-index: -1;
    border-radius: inherit;
  }

  .trade-count-item {
    text-align: center;
    position: relative;
    flex: 1;
    span {
      font-family: PingFang SC;
      font-size: 12px;
      font-weight: 500;
      //line-height: 12px;
      text-align: center;
      color: rgba(0, 0, 0, 0.6);
      display: block;
    }
    p {
      margin: 10px 0 0 0;
      font-family: Roboto;
      font-size: 12px;
      font-weight: 500;
      line-height: 12px;
      text-align: center;
      color: #000;
    }

    &::after {
      position: absolute;
      content: '';
      right: 0;
      width: 1px;
      height:14px;
      background: #D9D9D9;
      top: 50%;
      transform: translateY(-50%);
    }
    &:last-child {
      padding-right: 0;
      &::after {
        display: none;
      }
    }
  }
}
</style>