<template>
  <div class="helpCenter-wrap">
    <van-nav-bar
        :border="false"
        left-arrow
        :title="t('帮助中心')"
        @click-left="onClickLeft"
    />
    <div class="helpCenter-main-wrap">
      <van-tabs v-model:active="tabActive" :lazy-render="false" line-height="0" line-width="0" :ellipsis="false"
                @change="handleScrollTop">
        <van-tab :title="t('买币')">
          <van-image :src="store.state.publicConfig.buy_pic" class="content-img"/>
        </van-tab>
        <van-tab :title="t('卖币')">
          <van-image :src="store.state.publicConfig.sell_pic" class="content-img"/>
        </van-tab>
        <van-tab :title="t('快捷买币')" v-if="locale === 'vi'">
          <van-image :src="store.state.publicConfig.quick_buy_pic" class="content-img"/>
        </van-tab>
        <van-tab :title="t('充值')">
          <van-image :src="store.state.publicConfig.recharge_pic" class="content-img"/>
        </van-tab>
        <van-tab :title="t('提现')">
          <van-image :src="store.state.publicConfig.withdraw_pic" class="content-img"/>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script setup>
import {inject, ref} from 'vue'
import {useStore} from "vuex";
import { useI18n } from 'vue-i18n'

const { t, locale } = useI18n()

// 点击导航栏左侧返回键/取消按钮
const onClickLeft = () => {
  history.back()
}
const store = useStore();
const windowScrollTop = inject('$windowScrollTop')
const handleScrollTop = () => {
  document.getElementsByClassName('van-tabs__content')[0].scrollTo(0, 0)
}
const tabActive = ref(0);
</script>

<style lang="less" scoped>
.helpCenter-wrap {
  .helpCenter-main-wrap {
    height: calc(100vh - 44px);
    padding: 24px 16px;
    overflow: hidden;

    :deep(.van-tabs) {
      height: 100%;
      padding-top: 16px;
      border-radius: 8px;
      background: rgba(230, 255, 245, .6);
      overflow: hidden;

      .van-tabs__nav {
        background: rgba(230, 255, 245, .6);
        border-radius: 8px;

        .van-tab--grow {
          padding: 12px 0;
        }
      }

      .van-tab {
        .van-tab__text {
          min-width: 70px;
          height: 30px;
          padding: 0 10px;
          line-height: 30px;
          text-align: center;
          border-radius: 23px;
          font-family: SF Pro Display;
          font-size: 16px;
          font-weight: 500;
          color: #2EBD85;
        }

        &.van-tab--active {
          .van-tab__text {
            background: #2EBD85;
            color: #fff;
          }
        }
      }

      .van-tabs__content {
        height: calc(100% - 44px);
        padding: 17px 27px;
        overflow: scroll;
      }
    }
  }
}
</style>
