<template>
  <div class="order-wrap">
    <div class="order-header-box">
      <van-nav-bar
          :border="false"
          left-arrow
          :title="$t('代理佣金')"
          @click-left="onClickLeft"
      />
      <div class="first-tab-wrap">
        <div :class="{'active':firstTabIndex==''}" class="tab-item" @click="changeFirstTab('')">{{ t('全部') }}</div>
        <div :class="{'active':firstTabIndex==1}" class="tab-item" @click="changeFirstTab(1)">{{ t('买单佣金') }}</div>
        <div :class="{'active':firstTabIndex==2}" class="tab-item" @click="changeFirstTab(2)">{{ t('卖单佣金') }}</div>
      </div>

      <div class="date-wrap">
        <div class="date-box" @click="showDateCalendar=true">
          <div class="date-item-box">
            <van-image :src="themeImgs.commonCalendarIcon"/>
            <span class="date">{{ startDate }}</span>
            <van-image :src="themeImgs.commonArrowDownIcon"/>
          </div>
          <p>{{ t('至') }}</p>
          <div class="date-item-box">
            <van-image :src="themeImgs.commonCalendarIcon"/>
            <span class="date">{{ endDate }}</span>
            <van-image :src="themeImgs.commonArrowDownIcon"/>
          </div>
        </div>

        <van-field v-model="apiParams.order_sn" autocomplete="off" clearable :placeholder="t('请输入搜索的订单编号')">
          <template #button>
            <van-button size="small" @click="handleSearch">{{ t('搜索') }}</van-button>
          </template>
        </van-field>

      </div>
    </div>


    <div class="order-main-wrap">
      <div v-if="rebateList.length > 0" class="order-content-wrap">
        <div class="commission-paid">
          {{ t('已派发佣金') }}：<span>{{ commissionPaidMoney }}</span>{{ locale !== 'vi' ? store.state.publicConfig.title : '' }}
        </div>
        <van-list
            v-model:loading="loadingMore"
            :finished="finishedMore"
            :immediate-check="false"
            :finished-text="t('没有更多了')"
            @load="onLoadMore"
        >
          <div v-for="(item,index) in rebateList" :key="index" class="order-item-box">
            <div class="item-box">
              <span class="label">{{ t('订单号') }}：</span>
              <span class="content">{{ item.order_sn }}</span>
            </div>
            <div class="item-box">
              <span class="label">{{ t('返佣账号') }}：</span>
              <span class="content">{{ item.username }}</span>
            </div>
            <div class="item-box">
              <span class="label">{{ t('交易金额') }}：</span>
              <span class="content">{{ item.amount }} {{ locale !== 'vi' ? store.state.publicConfig.title : '' }}</span>
            </div>
            <div class="item-box">
              <span class="label">{{ t('买卖类型') }}：</span>
              <span class="content">{{ item.type == 1 ? t('买单') : t('卖单') }}</span>
            </div>
            <div class="item-box">
              <span class="label">{{ t('返佣比例')}}：</span>
              <span class="content">{{ item.rebate }}</span>
            </div>
            <div class="item-box">
              <span class="label">{{ t('返佣金额')}}：</span>
              <span class="content">{{ item.rebate_amount }} {{ locale !== 'vi' ? store.state.publicConfig.title : '' }}</span>
            </div>
            <div class="item-box">
              <span class="label">{{ t('全部')}}返佣方式：</span>
              <span class="content">{{ item.rebate_type == 1 ? t('日结') : item.rebate_type == 2 ? t('周结') : t('月结') }}</span>
            </div>
            <div class="item-box">
              <span class="label">{{ t('返佣时间')}}：</span>
              <span class="time">{{ item.updated_at }}</span>
            </div>
            <div class="item-box">
              <span class="label">{{ t('返佣派发')}}：</span>
              <span :class="item.status == 1 ? 'active' : 'no-active'">{{ item.status == 1 ? t('已返佣') : t('未返佣') }}</span>
            </div>
          </div>
        </van-list>
      </div>
      <van-empty
          v-else
          :image="themeImgs.commonEmptyImg"
          class="custom-image"
          :description="t('暂无数据') + '～'"
      />
    </div>
    <!-- 日历插件 -->
    <van-calendar v-model:show="showDateCalendar" :max-date="maxDate" :min-date="minDate" :show-confirm="false" allow-same-day
                  type="range" @confirm="handleGetStartEndDate"/>
  </div>
</template>

<script>
import {computed, onMounted, reactive, ref} from 'vue'
import {getRebateList} from '@/api/api'
import moment from 'moment'
import useClipboard from 'vue-clipboard3'
import {Toast} from 'vant'
import {useStore} from "vuex";
import { useI18n } from 'vue-i18n'

export default {
  setup() {
    const { t, locale } = useI18n()
    const store = useStore();
    // 点击导航栏左侧返回键/取消按钮
    const onClickLeft = () => {
      history.back()
    }
    // 第一层tab索引值
    const firstTabIndex = ref('')
    // 点击第一层tab
    const changeFirstTab = id => {
      firstTabIndex.value = id
      startDate.value = moment().format("YYYY-MM-DD")
      endDate.value = moment().format("YYYY-MM-DD")
      apiParams.pageSize = 20
      apiParams.order_sn = ''
      rebateList.value = []
      handleGetRebateList()
    }
    // 是否显示日历插件
    const showDateCalendar = ref(false)
    // 存储开始日期
    const startDate = ref(moment().format("YYYY-MM-DD"))
    // 存储结束日期
    const endDate = ref(moment().format("YYYY-MM-DD"))
    // 日期组件的最小日期--半年前
    const minDate = new Date(new Date().setMonth(new Date().getMonth() - 6));
    // 日期组件的最大日期--今天
    const maxDate = new Date()
    // 选择日期区间后
    const handleGetStartEndDate = (date) => {
      const [start, end] = date
      startDate.value = moment(start).format("YYYY-MM-DD")
      endDate.value = moment(end).format("YYYY-MM-DD")
      showDateCalendar.value = false
      handleGetRebateList()
    }
    // 存储返佣列表数据
    const rebateList = ref([])
    // 存储已返佣的金额
    const commissionPaidMoney = ref()
    // 存储接口默认请求参数
    const apiParams = reactive({
      type: 1,
      order_sn: '',
      page: 1,
      pageSize: 20,
      start: `${startDate.value} 00:00:00`,
      end: `${endDate.value} 23:59:59`
    })
    // 获取返佣列表数据
    const handleGetRebateList = (more) => {
      apiParams.type = firstTabIndex.value
      apiParams.start = `${startDate.value} 00:00:00`
      apiParams.end = `${endDate.value} 23:59:59`
      if (more) {
        apiParams.pageSize += 20
      }
      // const arr = []
      getRebateList(apiParams).then(res => {
        if (res.result == 200000) {
          commissionPaidMoney.value = res.data.list.count_amount
          // for(let i = 0; i < 30; i++) {
          //   arr.push({
          //     id: i + 1,
          //     order_sn: i + 'werwrwerwer',
          //     username: i + '2222',
          //     amount: i + 100,
          //     type: 1,
          //     rebate: 30,
          //     rebate_amount: 1000 + i,
          //     rebate_type: 1,
          //     updated_at: '2024-09-43 32: 33: 33',
          //     status: 1
          //   })
          // }
          //
          // console.log('arr', arr)
          // rebateList.value = arr
          if (!more) {
            rebateList.value = res.data.list.list
            if (res.data.list.list.length >= res.data.list.count) {
              finishedMore.value = true
            }
          } else {
            rebateList.value = res.data.list.list
            loadingMore.value = false
            if (res.data.list.list.length >= res.data.list.count) {
              finishedMore.value = true
            }
          }
        }
      })
    }
    onMounted(() => {
      handleGetRebateList()
    })
    // 订单状态过滤器
    const orderStatusFilter = (status) => {
      if (status < 5 || status == 10) {
        return t('进行中')
      }
      if (status == 5 || status == 9) {
        return t('已完成')
      }
      if (status == 6 || status == 7 || status == 8) {
        return t('已取消')
      }
      if (status == 12) {
        return t('人工退回')
      }
      if (status == 13) {
        return t('人工打币')
      }
    }
    // 挂单状态过滤器
    const hangOrderStatusFilter = (status) => {
      if (status == 1) {
        return t('出售中')
      }
      if (status == 2) {
        return t('已取消')
      }
      if (status == 3) {
        return t('已完成')
      }
      if (status == 4) {
        return t('已冻结')
      }
      if (status == 5) {
        return t('交易中')
      }
    }
    // api订单状态过滤器
    const apiOrderStatusFilter = (status) => {
      if (status == 1) {
        return t('待支付')
      }
      if (status == 2) {
        return t('成功')
      }
      if (status == 3) {
        return t('失败')
      }
    }
    // 上拉加载
    const loadingMore = ref(false);
    const finishedMore = ref(false);
    const onLoadMore = () => {
      handleGetRebateList(true)
    };
    // 复制
    const {toClipboard} = useClipboard()
    const handleCopy = async (content) => {
      try {
        await toClipboard(content)
        Toast.success(t('复制成功'))
      } catch (e) {
        console.error(e)
      }
    }
    // 搜索
    const handleSearch = () => {
      handleGetRebateList()
    }
    // mapstate--皮肤对应的图片
    const themeImgs = computed(() => store.state.themeImgs)
    return {
      onClickLeft,
      firstTabIndex,
      changeFirstTab,
      showDateCalendar,
      startDate,
      endDate,
      minDate,
      maxDate,
      handleGetStartEndDate,
      rebateList,
      commissionPaidMoney,
      orderStatusFilter,
      hangOrderStatusFilter,
      loadingMore,
      finishedMore,
      onLoadMore,
      handleCopy,
      handleSearch,
      apiParams,
      apiOrderStatusFilter,
      themeImgs,
      store,
      t,
      locale
    }
  }
}
</script>

<style lang="less" scoped>
.order-wrap {
  //margin-top: 60px;
  height: 100%;
  overflow: hidden;
  .order-header-box {
    position: fixed;
    width: 100%;
    height: 218px;
    top: 0;
    z-index: 1;
    background: var(--headBgColor);
    .first-tab-wrap {
      display: flex;
      align-items: center;
      justify-content: space-around;
      background: var(--headBgColor);

      .tab-item {
        height: 36px;
        line-height: 36px;
        color: var(--textCommonColor);

        &.active {
          color: var(--textColor);
          font-weight: 600;
        }
      }
    }

    .date-wrap {
      padding: 0 12px;
      margin-bottom: 12px;

      .date-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 36px;
        padding: 0 20px;
        margin: 28px 0 22px;
        //background: var(--boxBgColor1);
        background: linear-gradient(90deg, #00C800 0%, #FDD631 100%);
        border-radius: 17px;
        //color: var(--textCommonColor);
        color: #FFF;

        .date-item-box {
          display: flex;
          align-items: center;

          .van-image {
            width: 16px;
            height: 16px;
          }

          p {
            font-family: PingFangSC;
            font-size: 16px;
            //color: var(--textCommonColor);
            color: #FFF;
          }

          .date {
            margin: 0 4px 0 10px;
            font-size: 14px;
            font-family: 'DINOffcPro';
            //color: var(--textCommonColor);
          }
        }
      }

      .van-cell {
        height: 36px;
        padding: 0 0 0 10px;
        //background: var(--orderSearchNumInputBgColor);
        border-radius: 17px;
        background: rgba(215, 215, 215, 0.3);


        ::v-deep {
          .van-button {
            background: rgba(46, 189, 133, 1);
            font-family: PingFang SC;
            font-size: 14px;
            font-weight: 600;
            color: #FFF;
          }

          .van-field__control {
            height: 36px;
          }
        }
      }
    }
  }

  .order-main-wrap {
    //height: calc(100vh - 46px - 36px);
    //background: var(--mainBgColor);
    padding-top: 218px;
    height: 100%;
    overflow: auto;

    .order-content-wrap {
      //height: 100%;
      padding: 0 24px 24px;
      //color: var(--textCommonColor);
      //overflow: scroll;

      .commission-paid {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        font-family: PingFang SC;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        color: #666;

        span {
          margin-right: 1px;
          font-family: DIN Offc Pro;
          font-size: 20px;
          line-height: 18px;
        }
      }

      .order-item-box {
        display: block;
        padding: 10px 0;
        border-bottom: rgba(215, 215, 215, .4);

        &:last-child {
          border-bottom: none;
        }

        .item-box {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-family: PingFang SC;
          font-size: 14px;
          font-weight: 400;
          line-height: 16px;
          color: #979797;
          margin-bottom: 6px;

          &:last-of-type {
            margin-bottom: 0;
          }


          .time {
            color: #666666;
          }

          .active {
            color: #00C800;
            font-size: 16px;
          }

          .no-active {
            color: #EEBE00;
            font-size: 16px;
          }
        }
      }
    }
  }
}
</style>
