<script setup>
import {defineProps} from "vue";
import {useRouter} from 'vue-router'

const props = defineProps({
  data: {
    type: Array,
    default: []
  }
})

const $router = useRouter()

//  banner跳转
const goTobanner = (item) => {
  $router.push({
    path: "bannerDetail",
    query: {
      pic: item.url,
      title: item.title
    },
  });
}
</script>

<template>
  <van-swipe :autoplay="3000" class="help-swiper" indicator-color="white">
    <van-swipe-item v-for="(item,index) in props.data" :key="index">
      <van-image v-if="!item.url" :src="item.pic" class="avatar"/>
      <a v-else-if="item.url_type === 2" rel="noopener noreferrer" target="_blank" @click="goTobanner(item)">
        <van-image :src="item.pic" class="avatar"/>
      </a>
      <a v-else :href="item.url" rel="noopener noreferrer" target="_blank">
        <van-image :src="item.pic" class="avatar"/>
      </a>
    </van-swipe-item>
  </van-swipe>

<!--  <div-->
<!--      class="help-content-1"-->
<!--      v-for="(item, index) in props.data"-->
<!--      :key="index">-->
<!--    <van-image v-if="!item.url" :src="item.pic" class="avatar"/>-->
<!--    <a v-else-if="item.url_type === 2" rel="noopener noreferrer" target="_blank" @click="goTobanner(item)">-->
<!--      <van-image :src="item.pic" class="avatar"/>-->
<!--    </a>-->
<!--    <a v-else :href="item.url" rel="noopener noreferrer" target="_blank">-->
<!--      <van-image :src="item.pic" class="avatar"/>-->
<!--    </a>-->
<!--  </div>-->
</template>

<style scoped lang="less">
.help-swiper {
  padding-bottom: 10px;
}
</style>