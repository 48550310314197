<template>
  <div class="personalReport-wrap">
    <van-nav-bar
        :border="false"
        fixed
        left-arrow
        :title="t('个人报表')"
        @click-left="onClickLeft"
    />

    <div class="personalReport-main-wrap">
      <div class="date-box" @click="showDateCalendar=true">
        <div class="date-item-box">
          <van-image :src="themeImgs.commonCalendarIcon"/>
          <span class="date">{{ startDate }}</span>
          <van-image :src="themeImgs.commonArrowDownIcon"/>
        </div>
        <p>{{ t('至') }}</p>
        <div class="date-item-box">
          <van-image :src="themeImgs.commonCalendarIcon"/>
          <span class="date">{{ endDate }}</span>
          <van-image :src="themeImgs.commonArrowDownIcon"/>
        </div>
      </div>

      <div class="report-list-wrap">
        <div class="list-item-box">
          <div class="item-box">
            <span class="label">{{ t('买单总额') }}</span>
            <span class="amount">{{ reportData.buy_amount }}<span v-if="locale !== 'vi'">{{ coinName }}</span></span>
          </div>
          <div class="slider"></div>
        </div>

        <div class="list-item-box">
          <div class="item-box">
            <span class="label">{{ t('卖单总额') }}</span>
            <span class="amount">{{ reportData.sell_amount }}<span v-if="locale !== 'vi'">{{ coinName }}</span></span>
          </div>
          <div class="slider"></div>
        </div>

        <div class="list-item-box">
          <div class="item-box">
            <span class="label">{{ t('API充值总额') }}</span>
            <span class="amount">{{ reportData.recharge_amount }}<span v-if="locale !== 'vi'">{{ coinName }}</span></span>
          </div>
          <div class="slider"></div>
        </div>

        <div class="list-item-box">
          <div class="item-box">
            <span class="label">{{ t('API充值返点') }}</span>
            <span class="amount">{{ reportData.recharge_rebate }}<span v-if="locale !== 'vi'">{{ coinName }}</span></span>
          </div>
          <div class="slider"></div>
        </div>

        <div class="list-item-box">
          <div class="item-box">
            <span class="label">{{ t('API提现总额') }}</span>
            <span class="amount">{{ reportData.withdraw_amount }}<span v-if="locale !== 'vi'">{{ coinName }}</span></span>
          </div>
          <div class="slider"></div>
        </div>

        <div class="list-item-box">
          <div class="item-box">
            <span class="label">{{ t('交易纠纷订单总额') }}</span>
            <span class="amount">{{ reportData.dispute_amount }}<span v-if="locale !== 'vi'">{{ coinName }}</span></span>
          </div>
          <div class="slider"></div>
        </div>

<!--        <div class="list-item-box">-->
<!--          <div class="item-box">-->
<!--            <span class="label">{{ t('转账总额') }}</span>-->
<!--            <span class="amount">{{ reportData.transfer_out }}<span v-if="locale !== 'vi'">{{ coinName }}</span></span>-->
<!--          </div>-->
<!--          <div class="slider"></div>-->
<!--        </div>-->

<!--        <div class="list-item-box">-->
<!--          <div class="item-box">-->
<!--            <span class="label">{{ t('收款总额') }}</span>-->
<!--            <span class="amount">{{ reportData.transfer_in }}<span v-if="locale !== 'vi'">{{ coinName }}</span></span>-->
<!--          </div>-->
<!--          <div class="slider"></div>-->
<!--        </div>-->

        <!--    比特币充值/提现    start   -->
<!--        <div class="list-item-box">-->
<!--          <div class="item-box">-->
<!--            <span class="label">{{ t('USDT充值') }}</span>-->
<!--            <span class="amount">{{ reportData.bitcoin }}<span v-if="locale !== 'vi'">{{ coinName }}</span></span>-->
<!--          </div>-->
<!--          <div class="slider"></div>-->
<!--        </div>-->
<!--        <div class="list-item-box">-->
<!--          <div class="item-box">-->
<!--            <span class="label">{{ t('USDT提现') }}</span>-->
<!--            <span class="amount">{{ reportData.bitcoin_withdraw }}<span v-if="locale !== 'vi'">{{ coinName }}</span></span>-->
<!--          </div>-->
<!--          <div class="slider"></div>-->
<!--        </div>-->
        <!--    比特币充值/提现    end   -->

        <!--  充值手续费, 充值返点汇总 start -->
        <div class="list-item-box">
          <div class="item-box">
            <span class="label">Tổng số xu mua nhanh</span>
            <span class="amount">
              {{ reportData.rechargeAmount }}
              <span v-if="locale !== 'vi'">{{ coinName }}</span>
            </span>
          </div>
          <div class="slider"></div>
        </div>
<!--        <div class="list-item-box">-->
<!--          <div class="item-box">-->
<!--            <span class="label">Số tiền rút</span>-->
<!--            <span class="amount">-->
<!--              {{ reportData.withdrawAmount }}-->
<!--              <span v-if="locale !== 'vi'">{{ coinName }}</span>-->
<!--            </span>-->
<!--          </div>-->
<!--          <div class="slider"></div>-->
<!--        </div>-->

        <div class="list-item-box">
          <div class="item-box">
            <span class="label"> Phí nạp tiền</span>
            <span class="amount">
              {{ reportData.rechargeFee }}
              <span v-if="locale !== 'vi'">{{ coinName }}</span>
            </span>
          </div>
          <div class="slider"></div>
        </div>
        <div class="list-item-box">
          <div class="item-box">
            <span class="label">Chiết khẩu nạp tiền</span>
            <span class="amount">
              {{ reportData.rechargeRebate }}
              <span v-if="locale !== 'vi'">{{ coinName }}</span>
            </span>
          </div>
          <div class="slider"></div>
        </div>
        <!--  充值手续费, 充值返点汇总 end -->
      </div>
    </div>
    <!-- 日历插件 -->
    <van-calendar v-model:show="showDateCalendar" :max-date="maxDate" :min-date="minDate" :show-confirm="false" allow-same-day
                  type="range" @confirm="handleGetStartEndDate"/>
  </div>
</template>

<script setup>
import {computed, onMounted, ref} from 'vue'
import {getReport} from '@/api/api'
import moment from 'moment'
import {useStore} from "vuex";
import { useI18n } from 'vue-i18n'


const { t, locale } = useI18n()
const store = useStore();
// 点击导航栏左侧返回键/取消按钮
const onClickLeft = () => {
  history.back()
}
// 请求数据接口
const handleGetReport = () => {
  let params = {}
  params.start = `${startDate.value} 00:00:00`
  params.end = `${endDate.value} 23:59:59`
  getReport(params).then(res => {
    if (res.result == 200000) {
      reportData.value = res.data.list
    }
  })
}
onMounted(() => {
  handleGetReport()
})
// 存储内容
const reportData = ref('')
// 是否显示日历插件
const showDateCalendar = ref(false)
// 存储开始日期
const startDate = ref(moment().format("YYYY-MM-DD"))
// 存储结束日期
const endDate = ref(moment().format("YYYY-MM-DD"))
// 日期组件的最小日期--半年前
const minDate = new Date(new Date().setMonth(new Date().getMonth() - 6));
// 日期组件的最大日期--今天
const maxDate = new Date()
// 选择日期区间后
const handleGetStartEndDate = (date) => {
  const [start, end] = date
  startDate.value = moment(start).format("YYYY-MM-DD")
  endDate.value = moment(end).format("YYYY-MM-DD")
  showDateCalendar.value = false
  handleGetReport()
}
// mapstate--皮肤对应的图片
const themeImgs = computed(() => store.state.themeImgs)

const coinName = computed(() => store.state.publicConfig.title + '币')
</script>

<style lang="less" scoped>
.personalReport-wrap {
  padding-bottom: 60px;

  .personalReport-main-wrap {
    margin-top: 60px;

    .date-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 351px;
      height: 36px;
      padding: 0 20px;
      margin: auto;
      background: linear-gradient(90deg, #00C800 0%, #FDD631 100%);
      border-radius: 17px;
      //color: var(--textCommonColor);
      color: #FFF;

      .date-item-box {
        display: flex;
        align-items: center;

        .van-image {
          width: 16px;
          height: 16px;
        }

        p {
          font-family: PingFangSC;
          font-size: 16px;
          //color: var(--textCommonColor);
          color: #FFF;
        }

        .date {
          margin: 0 4px 0 10px;
          font-size: 14px;
          font-family: 'DINOffcPro';
          //color: var(--textCommonColor);
          //opacity: 0.9;
        }
      }
    }

    .report-list-wrap {
      padding: 14px 35px 0;

      .list-item-box {
        height: 45px;
        margin-bottom: 45px;

        .item-box {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 15px;
          //color: var(--textCommonColor);

          .label {
            font-family: PingFang SC;
            font-size: 16px;
            line-height: 22.4px;
            color: rgba(102, 102, 102, 1);
          }

          .amount {
            font-family: DIN Offc Pro;
            font-size: 16px;
            font-weight: 700;
            line-height: 20.61px;
            color: rgba(51, 51, 51, 1);
          }
        }

        .slider {
          width: 100%;
          height: 8px;
          //background: var(--btnBgColor);
          background: rgba(253, 212, 49, 1);
          border-radius: 4px;
        }
      }
    }
  }
}
</style>
