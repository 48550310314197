<template>
  <div class="buyCoin-wrap">
    <van-nav-bar
        :border="false"
        left-arrow
        :title="t('购买')"
        @click-left="onClickLeft"
    />

    <div class="buyCoin-main-wrap">
      <van-field v-model="buyAmountValue"
                 :formatter="formatter"
                 :placeholder="locale === 'vi' ? t('输入购买数量') : `${t('输入购买数量')}（${store.state.publicConfig.title}）`"
                 :readonly="sellerInfos.type==2"
                 autocomplete="off" center
      >
        <template #left-icon>
          <van-image :src="themeImgs.commonTransIcon"/>
        </template>
      </van-field>

      <p v-if="sellerInfos.type==1" class="buy-amount">
        {{ t('可购总数') }}：{{ sellerInfos.currency }}
        {{ locale !== 'vi' ? store.state.publicConfig.title : '' }}
      </p>

      <p v-if="siteName === 'qtpay'" class="buy-amount">
        {{ t('请按照订单金额支付') }}
      </p>
      <p v-else class="buy-amount">
        {{ t('请按照订单金额支付') }}
<!--        ：{{ sellerInfos.min_amount }}-->
<!--        {{ locale !== 'vi' ? store.state.publicConfig.title : '' }}-->
      </p>
      <div class="buyer-info-wrap">
        <div class="item-box">
          <span class="label">{{ t('卖家') }}</span>
          <div class="right-box">
            <van-image round :src="getAvatar(sellerInfos.avatarNew)" />
            <span>{{ sellerInfos.nick_name }}</span>
          </div>
        </div>
        <div class="item-box">
          <span class="label">{{ t('出售数量') }}</span>
          <div class="right-box">
            <span>{{ sellerInfos.count_currency }}</span>
          </div>
        </div>
<!--        <div class="item-box">-->
<!--          <span class="label">{{ t('出售方式') }}</span>-->
<!--          <div class="right-box">-->
<!--            <span>{{ sellerInfos.type == 1 ? t('可拆售') : t('不可拆售') }}</span>-->
<!--          </div>-->
<!--        </div>-->
        <div class="item-box">
          <span class="label">{{ t('收款方式') }}</span>
          <div class="right-box">

            <van-image v-for="(item1,index1) in sortArrayDescending(sellerInfos.pay_type, 'index')" :key="index1"
                       :src="themeImgs[locale !== 'vi' ? `commonPaymentIcon${item1}` : `commonPaymentIconVn${item1}`]"/>
          </div>
        </div>
      </div>
      <div v-for="(item,index) in paymentList" :key="index" class="payment-box">
        <van-image v-if="locale !== 'vi'" :src="themeImgs[`commonPaymentIcon${item.type}`]"/>
        <van-image v-else :src="themeImgs[`commonPaymentIconVn${item.type}`]"/>

        <span v-if="locale !== 'vi'">{{ item.type == 1 ? t('支付宝') : item.type == 2 ? t('微信') : t('银行卡') }}</span>
        <span>{{ item.card && accountFilter(item.card) || '******' }}</span>
        <van-button :disabled="btnClock" block class="pay-btn" @click="handleDoBuy(item)">{{ t('支付') }}</van-button>
      </div>
      <div v-if="hintMsg.tradingFloor" class="buy-message">
        Để ý: {{ hintMsg.tradingFloor }}
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, ref, watch, onMounted} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import {doBuy, getHint, getPaymentList} from '@/api/api'
import {Toast} from 'vant'
import {useStore} from 'vuex'
import { useGetAvatar} from "@hooks";
import { useI18n } from 'vue-i18n'
import {sortArrayDescending} from "@/utils";

const { t, locale } = useI18n()
const $route = useRoute()
const $router = useRouter()
const store = useStore()
const { getAvatar } = useGetAvatar()
const siteName = ref(siteChannel)
// // 获取卖家卖币信息
// const handleGetSellerInfos = () => {
//     getSellerInfo({id:$route.query.id}).then(res => {
//         if(res.result == 200000) {
//             sellerInfos.value = res.data.list
//             if(res.data.list.type == 2) {
//                 buyAmountValue.value = res.data.list.currency
//             }
//             handleGetPaymentList()
//         }
//     })
// }
// onMounted(() => {
//     handleGetSellerInfos()
// })

const hintMsg = ref({})

const handleGetHint = () => {
  getHint().then(res => {
    if (res.result == 200000) {
      hintMsg.value = res.data
    }
  })
}

onMounted(() => {
  handleGetHint()
})

// 点击导航栏左侧返回键
const onClickLeft = () => {
  history.back()
}
// 购买数量
const buyAmountValue = ref('')
// 只能输入整数跟2位小数
const formatter = (value) => value.match(/^\d*(\.?\d{0,2})/g)[0]
// 存储收付款列表数据
const paymentList = ref()
// 获取收付款列表数据
const handleGetPaymentList = () => {
  getPaymentList().then(res => {
    if (res.result == 200000) {
      // paymentList.value = sortArrayDescending(res.data, 'type')
      let buyerPayArr = []
      // console.log('111', sortArrayDescending(sellerInfos.pay_type, 'index'))
      Object.values(res.data).forEach(item => {
        sellerInfos.value.pay_type.map(Number).forEach(num => {
          if (item.type == num) {
            buyerPayArr.push(item)
          }
        })
      })
      paymentList.value = sortArrayDescending(buyerPayArr, 'type')
    }
  })
}

// 存储卖家信息
const sellerInfos = ref({})

watch(() => $route.query.sellerInfos, (val) => {
  if (val) {
    sellerInfos.value = JSON.parse(val)

    if (sellerInfos.value.type == 2) {
      buyAmountValue.value = sellerInfos.value.currency
    }

    handleGetPaymentList()
  }
}, {
  immediate: true
})

// 点击支付，购买货币
const handleDoBuy = (item) => {
  if (btnClock.value) return
  if (!buyAmountValue.value) {
    return Toast.fail(t('请输入购买数量'))
  } else if (buyAmountValue.value < Number(sellerInfos.value.min_amount)) {
    return Toast.fail(t('购买数量不能小于最小数量'))
  }
  btnClock.value = true
  let params = {
    id: sellerInfos.value.id,
    type: item.type,
    amount: buyAmountValue.value
  }
  doBuy(params).then(res => {
    btnClock.value = false
    if (res.result == 200000) {
      $router.push({
        path: 'buyerOrderDetails',
        query: {
          order_sn: res.data.info
        }
      })
    } else {
      Toast.fail(res.msg)
    }
  }).catch(error => {
    btnClock.value = false
    Toast.fail(t('操作失败，请检查网络重试或者联系客服'))
  })
}
// 将手机号或邮箱中间部分替换为*
const accountFilter = (account) => {
  if (account) {
    let start = account.slice(0, 4);
    let end = account.slice(-4);
    return `${start}******${end}`;
  }
}
// 按钮锁
const btnClock = ref(false)
// mapstate--皮肤对应的图片
const themeImgs = computed(() => store.state.themeImgs)
</script>

<style lang="less" scoped>
.buyCoin-wrap {
  .buyCoin-main-wrap {
    min-height: calc(100vh - 44px);
    padding: 32px 16px 16px;
    //background: var(--mainBgColor);

    ::v-deep {
      .van-cell {
        height: 50px;
        border-radius: 8px;
        background: #E6FFF5;

        &::after {
          border-bottom: none;
        }

        .van-field__left-icon {
          display: flex;
          align-items: center;

          .van-image {
            width: 32px;
            height: 32px;
          }
        }

        .van-field__label {
          width: 38%;
          font-weight: 500;
          font-size: 26px;
          color: var(--textColor);
        }
      }
    }

    .buy-amount {
      margin-top: 10px;
      //color: var(--textColor);
      color: #2EBD85;
      font-family: PingFang SC;
      font-size: 14px;
      font-weight: 500;
    }

    .buyer-info-wrap {
      margin-top: 16px;
      margin-bottom: 24px;

      .item-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 14px;
        //color: var(--textCommonColor);
        color: #535C66;

        font-family: PingFang SC;
        font-size: 16px;
        line-height: 22.4px;
        font-weight: 400;

        .label {
          font-weight: 500;
        }

        .right-box {
          display: flex;
          align-items: center;

          .van-image {
            width: 24px;
            height: 24px;
            margin-right: 12px;

            &:last-of-type {
              margin-right: 0;
            }
          }

          span {
            font-size: 16px;
          }
        }
      }
    }

    .payment-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;

      .van-image {
        width: 24px;
        height: 24px;
        margin-right: 34px;
      }

      span {
        display: inline-block;
        font-family: PingFang SC;
        font-size: 16px;
        font-weight: 500;
        line-height: 22.4px;
        text-align: left;
        color: #535C66;

        &:first-of-type {
          width: 50px;
          margin-right: 20px;
        }

        &:last-of-type {
          flex: 1;
        }
      }

      .pay-btn {
        width: 62px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        color: #fff;
        border-radius: 8px;
        background: #2EBD85;
      }
    }

    .buy-message {
      color: red;
      font-size: 14px;
      font-family: "PingFang SC";
      line-height: 24px;
    }
  }
}
</style>
