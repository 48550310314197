<template>
  <van-tabbar
      route
      :border="false"
      class="footer-wrap"
      :before-change="beforeChange">
    <van-tabbar-item to="/home">
      <span>Trang chủ</span>
      <template #icon="props">
        <img :src="props.active ? themeImgs.footerHomeActive : themeImgs.footerHome" />
      </template>
    </van-tabbar-item>
    <van-tabbar-item  :to="{path: '/quickTrade', query:{id: 0}}">
      <span>Giao dịch</span>
      <template #icon="props">
        <img :src="props.active ? themeImgs.footerTradeActive : themeImgs.footerTrade "/>
      </template>
    </van-tabbar-item>
    <!-- <van-image v-if="showScan" round :src="themeImgs.footerScanIcon" @click="openScanCodeUrl" /> -->
    <van-tabbar-item>
      <span>CSKH</span>
      <template #icon="props">
        <img :src="props.active ? themeImgs.footerServiceActive : themeImgs.footerService" />
      </template>
    </van-tabbar-item>
    <van-tabbar-item to="/my">
      <span>Cá nhân</span>
      <template #icon="props">
        <img
            :src="props.active ? themeImgs.footerMyActive : themeImgs.footerMy"
        />
      </template>
    </van-tabbar-item>
  </van-tabbar>

  <van-share-sheet
      v-model:show="showScanActions"
      title=""
      :options="options"
      @select="onSelect"
      @close="onClosed"
      class="scan-sheet"
  />

  <input @change="fileInputChange" type="file" id="qrcodeFileInput" accept="image/*">
  <div id="qrcodeResult" style="display: none;"></div>
</template>

<script>
import { defineComponent,ref, computed, watch } from "vue"
import { Tabbar, TabbarItem, Toast } from "vant"
// import {getMemberInfo} from '@/api/api'
import { useStore } from 'vuex'
import { useRouter } from "vue-router"
import footerHome from "@/assets/theme/green/images/tabBar/home_icon.png";
import footerHomeActive from "@/assets/theme/green/images/tabBar/home_active_icon.png";
import footerTrade from "@/assets/theme/green/images/tabBar/trade_icon.png";
import footerTradeActive from "@/assets/theme/green/images/tabBar/trade_active_icon.png";
import footerService from "@/assets/theme/green/images/tabBar/service_icon.png";
import footerServiceActive from "@/assets/theme/green/images/tabBar/service_active_icon.png";
import footerMy from "@/assets/theme/green/images/tabBar/my_icon.png";
import {setShowServicePopup} from "@/store/mutations";
import {Html5Qrcode} from "html5-qrcode";

export default defineComponent({
  methods: {setShowServicePopup},
  computed: {
    footerMy() {
      return footerMy
    },
    footerServiceActive() {
      return footerServiceActive
    },
    footerService() {
      return footerService
    },
    footerTradeActive() {
      return footerTradeActive
    },
    footerTrade() {
      return footerTrade
    },
    footerHomeActive() {
      return footerHomeActive
    },
    footerHome() {
      return footerHome
    }
  },
  components: {
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
  },
  setup() {
    const store = useStore()
    const $router = useRouter()

    const token = computed(() => store.state.loginInfos.token)
    // onMounted(()=>{
    //     // handleGetOrderRemind()
    //     judgeFromZH()
    // })

    /*        // 存储未处理订单的数量
          const UntreatedNum = ref()
          // 获取订单状态提醒
          const handleGetOrderRemind = () => {
              getOrderRemind().then(res => {
                  if(res.result == 200000) {
                      if(res.data.count>0) {
                          UntreatedNum.value = res.data.count
                      } else {
                          UntreatedNum.value = ""
                      }
                  }
              })
          }
          // 计算属性实现视图更新--新订单提醒
          const orderRemindObj = computed(() => store.state.orderRemind)
          watch(orderRemindObj, (newValue) =>{
              handleGetOrderRemind()
          }, {
              deep: true // name是一个对象，需要进行深度监听
          })*/

    watch(() => store.state.isScanShow, (newVal) => {
      if (store.state.isScanShow) {
        openScanCodeUrl()
      }
    })
    // 点击扫码
    const openScanCodeUrl = () => {
      if (store.state.isApp) {
        // 调用APP端的方法并传参给APP端
        if (window.Certification && window.Certification.scanQRCode) { // 安卓
          // 传参给Android
          try {
            window.Certification.scanQRCode();
          } catch (error) {
            console.log(error);
          }
        } else if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.scanQRCode) {
          // 传参给IOS
          try {
            window.webkit.messageHandlers.scanQRCode.postMessage({});
          } catch (error) {
            console.log(error);
          }
        }
      } else {
        showScanActions.value = true
      }
    }

    const beforeChange = async index => {
      if ([1, 3].includes(index)) {
        if (!token.value) {
          store.commit('setLoginRegisterInfo', { flag: true, type: 1 }) // 打开登录注册弹框
          return false
        }

        // if (index === 1) {
        //   const res = await getMemberInfo()
        //   if (res.result === 200000) {
        //     if (res.data.is_name == 1) {
        //       Toast.fail('Vui lòng xác thực danh tính trước')
        //       return false
        //     } else if (res.data.is_name == 3) {
        //       Toast.fail('Đang kiểm tra danh tính')
        //       return false
        //     }
        //   }
        // }
      }

      if (index === 2) {
        setShowServicePopup(true)
      }

      return true
    }
    // mapstate--皮肤对应的图片
    const themeImgs = computed(() => store.state.themeImgs)

    // 是否显示扫码---综合集成过来的，不显示
    // const showScan = ref(true)
    // const judgeFromZH = () => {
    //     if (window.parent.frames['agIframe']) {
    //         showScan.value = false
    //     } else {
    //         showScan.value = true
    //     }
    // }


    const showScanActions = ref(false)
    const options = [
      { id: 'pic', name: 'Album', icon: 'poster' },
      { id: 'scan', name: 'Quét mã', icon: 'qrcode' },
    ]

    const onSelect = (option) => {
      showScanActions.value = false
      store.commit('setIsScanShow', false)

      if (option.id === 'scan') {
        $router.push('/scanPay')
      } else {
        store.commit('setIsScanSuccess', false)
        document.getElementById('qrcodeFileInput').click()
      }
    };

    const onClosed = (event) => {
      store.commit('setIsScanShow', false)
    }

    const fileInputChange = (event) => {
      const file = event.target.files[0]
      if (!file) return;

      const html5QrCode = new Html5Qrcode(/* element id */ 'qrcodeResult' );
      html5QrCode.scanFile(file, false)
          .then(decodedText => {
            console.log('decodedText', decodedText)
            if (decodedText.includes('order')) {
              $router.replace({ query: { data: JSON.stringify(decodedText) }} )
              store.commit('setIsScanSuccess', true)
            } else {
              Toast.fail(`Dùng mã QR đúng`)
            }
          })
          .catch(err => {
            // 未能解析到二维码
            Toast.fail(`Không tìm thấy mã QR`)
            console.error('Không thể quét mã QR', err)
          });
    }

    const setShowServicePopup = (flag) => {
      store.commit('setShowServicePopup', flag)
    }
    return {
      // openScanCodeUrl,
      beforeChange,
      themeImgs,
      // showScan,
      store,
      setShowServicePopup,
      options,
      showScanActions,
      onSelect,
      fileInputChange,
      onClosed
    };
  },
});
</script>

<style lang="less" scoped>
.footer-wrap {
  box-shadow: 0px -8px 20px rgba(29, 58, 88, 0.05);
  backdrop-filter: blur(27.1828px);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  height: 74px;
  .van-tabbar-item {
    padding: 7px 0;
    &:first-child {
      border-top-left-radius: 8px;
    }
    &:last-child {
      border-top-right-radius: 8px;
    }
    img {
      width: 32px;
      height: 32px;
    }
  }
  .van-tabbar-item__text {
    span {
      font-size: 12px;
      color: rgba(0, 0, 0, 0.8);
    }
  }
  .van-tabbar-item--active {
    span {
      color: #2EBD85;
    }
  }
  .van-image {
    width: 56px;
    height: 56px;
    margin-top: -25px;
  }
}
@media only screen and (min-width: 750px) {
  .footer-wrap {
    max-width: 375px;
    margin: 0 auto !important;
    left: auto;
  }
}
:global(.scan-sheet .van-share-sheet__options) {
  justify-content: space-evenly;
}
#qrcodeFileInput {
  display: none;
}
</style>
