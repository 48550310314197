<template>
  <div class="modifyPaymentPassword-wrap">
    <van-nav-bar
        :border="false"
        left-arrow
        :title="t('修改支付密码')"
        @click-left="onClickLeft"
    />
    <div class="modifyPaymentPassword-main-wrap">
      <van-field
          v-model="changeParams.password"
          :formatter="formatter"
          :type="inputOldPwType"
          autocomplete="off"
          clearable
          :label="t('旧支付密码')"
          maxlength="6"
          :placeholder="t('请输入旧密码')"
          type="password"
      >
        <template #button>
                    <span @click="changeOldPassWordType">
                      <van-image v-if="!showOldEye" :src="themeImgs.commonEyeOpened" />
                      <van-image v-else :src="themeImgs.commonEyeClosed" />
                    </span>
        </template>
      </van-field>
      <van-field
          v-model="changeParams.new_password"
          :formatter="formatter"
          :type="inputPwType"
          autocomplete="off"
          clearable
          :label="t('新支付密码')"
          maxlength="6"
          :placeholder="t('请输入新密码')"
      >
        <template #button>
                    <span @click="changePassWordType">
                      <van-image v-if="!showEye" :src="themeImgs.commonEyeOpened" />
                      <van-image v-else :src="themeImgs.commonEyeClosed" />
                    </span>
        </template>
      </van-field>
      <van-field
          v-model="changeParams.new_confirm_password"
          :formatter="formatter"
          :type="inputCPwType"
          autocomplete="off"
          clearable
          :label="t('确认支付密码')"
          maxlength="6"
          :placeholder="t('确认支付密码')"
      >
        <template #button>
                    <span @click="changeCPassWordType">
                      <van-image v-if="!showCEye" :src="themeImgs.commonEyeOpened" />
                      <van-image v-else :src="themeImgs.commonEyeClosed" />
                    </span>
        </template>
      </van-field>
      <div class="comfirm-btn-box">
        <van-button block @click="handleChangePassword">{{ t('确认') }}</van-button>
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, reactive, ref} from 'vue'
import {changePayPassword} from '@/api/api'
import {Toast} from 'vant'
import {useStore} from "vuex";
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const store = useStore()

// 点击导航栏左侧返回键/取消按钮
const onClickLeft = () => {
  history.back()
}
// 过滤输入的中文
const formatter = (value) => value.replace(/[\u4e00-\u9fa5]/g, "");
const inputOldPwType = ref("password");
const inputPwType = ref("password");
const inputCPwType = ref("password");
const showOldEye = ref(true);
const showEye = ref(true);
const showCEye = ref(true);

const changeOldPassWordType = () => {
  showOldEye.value = !showOldEye.value;
  if (showOldEye.value) {
    inputOldPwType.value = "password";
  } else {
    inputOldPwType.value = "text";
  }
};

const changePassWordType = () => {
  showEye.value = !showEye.value;
  if (showEye.value) {
    inputPwType.value = "password";
  } else {
    inputPwType.value = "text";
  }
};

const changeCPassWordType = () => {
  showCEye.value = !showCEye.value;
  if (showCEye.value) {
    inputCPwType.value = "password";
  } else {
    inputCPwType.value = "text";
  }
};

const changeParams = reactive({
  password: '',
  new_password: '',
  new_confirm_password: ''
})

// 修改密码
const handleChangePassword = () => {
  if (!changeParams.password) {
    return Toast.fail(t('请输入旧密码'))
  } else if (!changeParams.new_password) {
    return Toast.fail(t('请输入新密码'))
  } else if (!changeParams.new_confirm_password) {
    return Toast.fail(t('请再次输入新密码'))
  } else if (changeParams.new_password != changeParams.new_confirm_password) {
    return Toast.fail(t('两次新密码不一致'))
  } else {
    changePayPassword(changeParams).then(res => {
      if (res.result == 200000) {
        Toast.success(t('修改成功'))
        history.back()
      } else {
        Toast.fail(res.msg)
      }
    })
  }
}

const themeImgs = computed(() => store.state.themeImgs)
</script>

<style lang="less" scoped>
.modifyPaymentPassword-wrap {
  .modifyPaymentPassword-main-wrap {
    height: calc(100vh - 44px);
    margin-top: 8px;
    //background: var(--mainBgColor);

    ::v-deep {
      .van-cell {
        display: flex;
        align-items: center;
        height: 50px;
        background: #FFF;
        box-shadow: 0 0.7px 0 0 #F3F4F7 inset;

        .van-field__label {
          width: 100px;
          font-family: PingFang SC;
          font-size: 16px;
          font-weight: 500;
          color: #535C66;
        }

        .van-field__button {
          span {
            display: flex;
            align-items: center;
            height: 100%;
          }
        }

        input {
          font-family: PingFangSC;
          font-size: 16px;
          color: #535C66;

          &::placeholder {
            font-weight: 400;
            font-size: 13px;
            color: rgba(83, 92, 102, 0.5);
            letter-spacing: -1px;
          }
        }
      }
    }

    .van-image {
      width: 32px;
      height: 32px;
    }

    .comfirm-btn-box {
      width: 343px;
      height: 44px;
      margin: 20px auto 0;

      .van-button {
        border-radius: 8px;
        background: #2EBD85;
        font-family: PingFang SC;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
}
</style>
