<script setup>
import {ref, watch, defineProps, defineEmits} from 'vue'
import HelpContentImg1 from '@/assets/images/help/help-content-1.png'
import HelpContentImg2 from '@/assets/images/help/help-content-2.png'
import HelpContentImg3 from '@/assets/images/help/help-content-3.png'
import HelpContentImg4 from '@/assets/images/help/help-content-4.png'
import HelpContentImg5 from '@/assets/images/help/help-content-5.png'
import HelpContentImg6 from '@/assets/images/help/help-content-6.png'
import HelpContentImg7 from '@/assets/images/help/help-content-7.png'
import HelpContentImg8 from '@/assets/images/help/help-content-8.png'


const props = defineProps({
  isShow: {
    type: Boolean,
    default: false
  },
  currentItem: {
    type: Object,
    default: {
      id: 0,
      label: ''
    }
  }
})

const emit = defineEmits(['update'])
const show = ref(false)

watch(() => props.isShow, val => {
  show.value = val
})

const closeDialog = () => {
  emit('update:isShow', false)
}

</script>

<template>
  <van-popup
      v-model:show="show"
      class="help-content-popup"
      position="bottom"
      @closed="closeDialog"
  >
    <div class="popup-header">câu hỏi</div>
    <div class="popup-content">
      <div class="content-title">
        {{ props.currentItem.id }}. {{ props.currentItem.label }}
      </div>
      <div class="content">
        <template v-if="props.currentItem.id === 1">
          <p>Nền tảng này không tính bất kỳ khoản phí xử lý nào đối với người dùng mua hoặc bán tiền trên nền tảng 88PAY.</p>
          <p>Nếu bạn có bất kỳ câu hỏi nào, vui lòng liên hệ với dịch vụ khách hàng trực tuyến của chúng tôi để biết thêm thông tin.</p>
        </template>
        <template v-if="props.currentItem.id === 2">
          <p>
            88PAY hỗ trợ giao dịch bằng thẻ ngân hàng, Zalo, Momo, và nhiều phương thức thanh toán khác, liên tục mở rộng thêm các phương thức thanh toán, cung cấp cho mọi người môi trường giao dịch an toàn, nhanh chóng và thuận tiện.
          </p>
        </template>
        <template v-if="props.currentItem.id === 3">
          <p>
            Nếu bạn chưa nhận được thanh toán cho việc đặt hàng, vui lòng bấm nhanh vào góc dưới bên phải để hủy đơn hàng và liên hệ ngay với bộ phận chăm sóc khách hàng trực tuyến để xử lý.
          </p>
          <div class="content-img">
            <van-image :src="HelpContentImg1" />
          </div>
        </template>
        <template v-if="props.currentItem.id === 6">
          <p>
            1: Đăng ký tài khoản và vào sidebar
          </p>
          <div class="content-img">
            <van-image :src="HelpContentImg2" />
          </div>
          <p>
            2: Bấm vào cổng xác thực tên thật
          </p>
          <div class="content-img">
            <van-image :src="HelpContentImg3" />
          </div>

          <p>
            3: Nhập tên và số CMND của bạn
          </p>
          <div class="content-img">
            <van-image :src="HelpContentImg4" />
          </div>

          <p>
            4: Tải lên ảnh mặt trước CMND của bạn
          </p>
          <div class="content-img">
            <van-image :src="HelpContentImg5" />
          </div>

          <p>
            5: Tải lên ảnh mặt sau CMND của bạn
          </p>
          <div class="content-img">
            <van-image :src="HelpContentImg6" />
          </div>

          <p>
            6: Nhấp vào Xác nhận để tham gia kinh doanh nhận dạng khuôn mặt
          </p>
          <div class="content-img">
            <van-image :src="HelpContentImg7" />
          </div>

          <p>
            7: Sau khi thông tin được tải lên, hãy đợi nhân viên xem xét. Nếu quá trình xác minh thông tin thành công, bạn có thể bắt đầu sử dụng.
          </p>
          <div class="content-img">
            <van-image :src="HelpContentImg8" />
          </div>
        </template>
      </div>
    </div>
  </van-popup>
</template>

<style lang="less" scoped>
.popup-header {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  color: #000;
  margin-bottom: 10px;
}
.popup-content {
  font-family: PingFang SC;
  .content-title {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: #16A26B;
  }
  .content {
    margin: 10px 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #000;
    .content-img {
      width: 224px;
      margin: 10px auto;
      img {
        width: 100%;
      }
    }
  }
}
</style>

<style lang="less">
.help-content-popup {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 15px;
  max-height: 90vh;
}
</style>