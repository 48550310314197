<template>
  <div class="fromThird-wrap">
    <van-image :src="require('@siteResources/fromThirdPay/third-logo.png')" class="logo"/>

    <div class="money-box">
      <p class="money">{{ payDataParams.amount }}</p>
      <p class="label">{{ t('充值金额') }}</p>
      <div class="order-time-box">
        <span class="tip">{{ t('订单时效') }}：</span>
        <span class="downtime">{{ downTime }}</span>
      </div>
    </div>

    <div class="order-detail-box">
      <div class="channel">{{ payDataParams.channel }}</div>

      <div class="order-num-box">
        <div class="label">{{ t('订单编号') }}：</div>
        <div class="num-box" @click="handleCopy(payDataParams.order_sn)">
          <span>{{ payDataParams.order_sn }}</span>

          <van-image :src="themeImgs.commonCopyIcon" class="copy" />
        </div>
      </div>

      <div class="order-num-box">
        <span>{{ t('订单时间') }}：</span>
        <span>{{ payDataParams.order_time }}</span>
      </div>
    </div>

    <div class="balance-box">
      <span class="label">{{ moneyName }}{{ t('pay余额') }}</span>
      <span class="balance">{{ payDataParams.member_amount }}</span>
    </div>

    <div class="btn btn1" @click="showPayPasswordDialog=true">{{ t('确认支付') }} <span v-if="locale !== 'vi'">{{ payDataParams.amount }}</span></div>
    <div class="btn btn2" @click="goUrl">{{ t('获得更多') }}{{ moneyName }}{{ t('币') }}</div>
    <!-- 支付密码弹出框 -->
    <van-dialog v-model:show="showPayPasswordDialog" :before-close="onBeforeClose" class="formThirdPay-dialog" show-cancel-button
                :title="t('支付密码')">
      <van-field
          v-model="payDataParams.pay_password"
          class="cancel-order-dialog-cell"
          :label="t('支付密码')"
          maxlength="6"
          :placeholder="t('请输入支付密码')"
          type="password"
      />
    </van-dialog>
    <!-- 余额不足弹出框 -->
    <van-dialog v-model:show="showNoMoneyDialog" class="formThirdPay-dialog" :confirm-button-text="t('去充值')" show-cancel-button
                :title="t('温馨提示')" @cancel="showNoMoneyDialog=false" @confirm="goUrl">
      <p class="tips-content">{{ t('您的余额不足，请充值') }}</p>
    </van-dialog>
  </div>
</template>

<script setup>
import {computed, onBeforeMount, onMounted, reactive, ref} from 'vue'
import {fromThirdPay} from "@/api/api"
import useClipboard from 'vue-clipboard3'
import {Toast} from 'vant'
import {useRouter} from "vue-router"
import {useStore} from 'vuex'
import { useI18n } from 'vue-i18n'

const { t, locale } = useI18n()
const store = useStore()
const $router = useRouter()
// 盘口标识
const siteName = ref(siteChannel)
// 货币名字
const moneyName = computed(() => {
  const coinNameMap = {
    agpay: 'AG',
    qtpay: 'QT',
    aipay: 'AI',
    '88pay': '',
  }
  return coinNameMap[siteName]
})
// 当前支付数据接口请求参数
const payDataParams = reactive({
  channel: '',
  three_username: '',
  actname: '',
  mcode: '',
  time: '',
  ip: '',
  order_sn: '',
  member_amount: '',
  order_time: '',
  password: '',
  pay_password: '',
  sign: '',
  amount: '',
  notifyUrl: ''
})
// 封装获取url参数
const getUrlParams = (url) => {
  let urlStr = url.split('?')[1]
  const urlSearchParams = new URLSearchParams(urlStr)
  const result = Object.fromEntries(urlSearchParams.entries())
  return result
}
const curToken = ref('')
// 获取支付数据
const getPayData = () => {
  payDataParams.channel = getUrlParams(location.href)['channel']
  payDataParams.three_username = getUrlParams(location.href)['three_username']
  payDataParams.actname = getUrlParams(location.href)['actname']
  payDataParams.ip = getUrlParams(location.href)['ip']
  payDataParams.mcode = getUrlParams(location.href)['mcode']
  payDataParams.time = getUrlParams(location.href)['time']
  payDataParams.sign = getUrlParams(location.href)['sign']
  payDataParams.order_sn = getUrlParams(location.href)['order_sn']
  payDataParams.member_amount = getUrlParams(location.href)['member_amount']
  payDataParams.amount = getUrlParams(location.href)['amount']
  payDataParams.notifyUrl = getUrlParams(location.href)['notifyUrl']
  curToken.value = getUrlParams(location.href)['token']
  payDataParams.order_time = timestampToTime(Number(getUrlParams(location.href)['order_time']), true)
}

// 时间戳转换日期格式方法
function timestampToTime(timestamp, state = false) {
  var date = new Date(timestamp * 1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
  var Y = date.getFullYear() + '-';
  var M = ((date.getMonth() + 1) > 9 ? (date.getMonth() + 1) : '0' + (date.getMonth() + 1)) + '-';
  var D = (date.getDate() > 9 ? date.getDate() : '0' + date.getDate());
  var h = (date.getHours() > 9 ? date.getHours() : '0' + date.getHours()) + ':';
  var m = (date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes()) + ':';
  var s = date.getSeconds() > 9 ? date.getSeconds() : '0' + date.getSeconds();
  return state ? Y + M + D + ' ' + h + m + s : Y + M + D;
}

// 倒计时时间
const downTime = ref('00:00')
// 定时器
let downTimer = null
// 倒计时函数
const timeDown = (time) => {
  clearInterval(downTimer)
  if (time <= 0) return
  downTimer = setInterval(() => {
    time--
    if (time <= -1) {
      clearInterval(downTimer)
    } else {
      let second = Math.floor(time % 60);     // 计算秒 ，取余
      let minite = Math.floor((time / 60) % 60); //计算分 ，换算有多少分，取余，余出多少秒
      minite = minite.toString().length == 1 ? '0' + minite : minite;
      second = second.toString().length == 1 ? '0' + second : second;
      downTime.value = minite + ":" + second
    }
  }, 1000)
}
onBeforeMount(() => {
  getPayData()
})
onMounted(() => {
  timeDown(getUrlParams(location.href)['order_time'])
})
// 跳转首页
const goUrl = () => {
  store.dispatch("saveLoginInfosActions", {token: curToken.value});
  $router.push('/home')
  localStorage.setItem('isFromThird', true)
  let urlStr = window.location.href.split('?')[1]
  sessionStorage.setItem('fromThirdUrlStr', urlStr)
}
// 复制
const {toClipboard} = useClipboard()
const handleCopy = async (content) => {
  try {
    await toClipboard(content)
    Toast.success(t('复制成功'))
  } catch (e) {
    console.error(e)
  }
}
// 是否显示支付密码框
const showPayPasswordDialog = ref(false)
// 取消默认关闭弹框事件
const onBeforeClose = (action) => {
  // 点击了确定按钮
  if (action === "confirm") {
    if (!payDataParams.pay_password) {
      return Toast.fail(t('请输入支付密码'))
    } else {
      btnClock.value = true
      delete payDataParams.member_amount
      delete payDataParams.password
      delete payDataParams.order_time
      fromThirdPay(payDataParams).then(res => {
        btnClock.value = false
        if (res.result == 200000) {
          showPayPasswordDialog.value = false
          payDataParams.pay_password = ''
          Toast.success(t('支付成功'))
        } else if (res.result == 100028) {
          showNoMoneyDialog.value = true
        } else {
          Toast.fail(res.msg)
        }
      }).catch(error => {
        btnClock.value = false
        Toast.fail(t('操作失败，请检查网络重试或者联系客服'))
      })
    }
  }
  // 点击了取消按钮
  else {
    showPayPasswordDialog.value = false
    payDataParams.pay_password = ''
    btnClock.value = false
  }
}
// 按钮锁
const btnClock = ref(false)
// 余额不足提示框
const showNoMoneyDialog = ref(false)

const themeImgs = computed(() => store.state.themeImgs)

</script>

<style lang="less" scoped>
.fromThird-wrap {
  height: 100vh;
  padding: 50px 16px;
  background: #F8F9FA;

  .logo {
    display: block;
    width: 61px;
    //height: 69px;
    margin: auto;
  }

  .money-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .money {
      margin-bottom: 8px;
      font-family: PingFang SC;
      font-size: 48px;
      font-weight: 500;
      line-height: 46px;
      text-align: center;
      color: #333333;
    }

    .label {
      margin-bottom: 4px;
      font-family: PingFang SC;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      text-align: center;
      color: #535353;
    }

    .order-time-box {
      display: flex;
      align-items: center;
      font-family: PingFang SC;
      font-weight: 500;
      line-height: 22px;
      text-align: center;
      font-size: 16px;

      .tip {
        color: #535353;
      }

      .downtime {
        color: #F50000;
      }
    }
  }

  .order-detail-box {
    margin-top: 20px;
    padding: 14px 10px 20px;
    background: rgba(244, 244, 244, .6);
    border-radius: 8px;

    .channel {
      margin-bottom: 15px;
      font-family: PingFang SC;
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      color: #666;
    }

    .order-num-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 15px;
      font-family: PingFang SC;
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      color: #666;


      span {
        font-weight: 400;
      }

      .num-box {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .copy {
          width: 16px;
          height: 16px;
          margin-left: 4px;
        }
      }
    }
  }

  .balance-box {
    display: flex;
    align-items: center;
    margin-top: 20px;
    padding: 23px 10px;
    background: rgba(244, 244, 244, .6);
    border-radius: 8px;
    color: #666;
    font-family: PingFang SC;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;

    .label {
      margin-right: 10px;
    }
  }

  .btn {
    height: 44px;
    margin-top: 20px;
    line-height: 44px;
    border-radius: 8px;
    text-align: center;
    font-size: 16px;
    font-family: PingFang SC;
    font-size: 16px;
    font-weight: 400;
    color: #333;

    &.btn1 {
      background: #2EBD85;
    }

    &.btn2 {
      background: #FDD431;
    }
  }

  :deep(.formThirdPay-dialog) {
    background: #fff;

    .van-dialog__header {
      padding: 15px 0;
      font-family: PingFang SC;
      font-size: 18px;
      font-weight: 600;
      line-height: 25px;
      text-align: center;
      color: #535C66;
    }

    .tips-content {
      padding: 30px 0;
      color: #02318B;
      text-align: center;
      font-family: PingFang SC;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: rgba(83, 92, 102, 0.9);
    }

    .van-cell {
      background: inherit;
    }

    //.van-cell__title {
    //  color: #02318B;
    //}

    .van-field__control {
      color: #535c66;
    }

    .van-dialog__cancel, .van-dialog__confirm{
      color: #535C66;
      font-family: PingFang SC;
      font-size: 17px;
      font-weight: 400;
    }
  }
}
</style>
