<script setup>
import {computed, ref} from 'vue'
import {useStore} from 'vuex'
import {useI18n} from 'vue-i18n'
import {Toast} from 'vant'
import QuickAmountPicker from './QuickAmountPicker.vue'
import BuyFast from './BuyFast.vue'
import PayDialog from './PayDialog.vue'
import {postQuickAmount} from '@/api/api'

const props = defineProps({
  configData: {
    type: Object,
    default: {}
  }
})

const { t, locale } = useI18n()

const store = useStore()

// mapstate--皮肤对应的图片
const themeImgs = computed(() => store.state.themeImgs)

// 只能输入整数跟2位小数
const formatter = (value) => value.match(/^\d*(\.?\d{0,2})/g)[0]

//  计算实际获取金额
const realAmount = computed(() => {
  const amount = parseFloat(currentAmount.value || 1)
  //  充值手续费
  const feeRate = props.configData.rechargeFeeRate / 100

  //  充值赠送返点
  const giveRate = props.configData.rechargeGiveRebate / 100
  if (props.configData.action) {
    return (amount + amount * giveRate).toFixed(2)
  }
  return  (amount - amount * feeRate).toFixed(2)
})

const currentAmount = ref('')

//  获取输入值
const handleInput = (event) => {
  amountFilter(event.target.value, -1)
}

//  支付方式选择
const payTypeItems = [
  { id: 3, icon: themeImgs.value.commonPaymentIconVn3, name: 'Bank', bankId: '11' },
  { id: 2, icon: themeImgs.value.commonPaymentIconVn2, name: 'Momo', bankId: '12' },
  { id: 1, icon: themeImgs.value.commonPaymentIconVn1, name: 'Zalo', bankId: '13' }
]

const currentPayType = ref(payTypeItems[0])
const onTab = (record) => {
  currentPayType.value = record
}

//  选择金额
const handleSelected = (item, index) => {
  amountFilter(item.amount, index)
}

//  选中样式
const amountFilter = (e, i) => {
  if(i !== -1) {
    currentAmount.value = currentAmount.value === e ? '' : e
  }
}

const isPay = ref(false)
//  提交
const onSubmit = () => {
  if (!currentAmount.value) {
    return Toast.fail(t('请输入购买数量'))
  }
  if (parseFloat(currentAmount.value) < parseFloat(props.configData.minRechargeAmount)) {
    return Toast.fail(t('购买数量不能小于最小数量'))
  }
  doBuy()
}

const loading = ref(false)

const payUrl = ref('')
//  快捷买币执行
const doBuy = () => {
  loading.value = true
  postQuickAmount({
    type: currentPayType.value.bankId,
    amount: currentAmount.value
  }).then(res => {
    if (res.result === 200000) {
      payUrl.value = res.data.data.payUrl
      isPay.value = true
    } else {
      Toast.fail(res.msg)
    }
    loading.value = false
  })
}

</script>

<template>
  <div class="momo-pay-box">
    <BuyFast
        :current-data="currentPayType"
        :data="payTypeItems"
        @onTab="onTab"
    />

    <van-field
        v-model="currentAmount"
        type="number"
        :formatter="formatter"
        label="Số tiền nạp"
        label-class="pay-fixed-label"
        :colon="true"
        placeholder="Vui lòng nhập số tiền nạp"
        :clearable="true"
        clear-icon="clear"
        @input="handleInput"
    >
      <template #right-icon>
        <span class="pay-fixed-right">₫</span>
      </template>
    </van-field>

    <QuickAmountPicker
        :data="props.configData.rechargeJson"
        :amount="currentAmount"
        @handleAmount="handleSelected"
    />

    <div class="quick-recharge-info">
      <span>Số tiền mua tối thiểu</span>
      <p>{{ props.configData.minRechargeAmount }} B</p>
    </div>
    <div class="quick-recharge-info">
      <span>Đơn giá tham khảo</span>
      <p>1₫=1B</p>
    </div>
    <div class="quick-recharge-info">
      <span>Thực tế nhận được</span>
      <p>{{ realAmount }} B</p>
    </div>
    <div class="sale-btn-box">
      <van-button
          :loading="loading"
          block @click="onSubmit">
        {{ t('购买') }}
      </van-button>
    </div>
  </div>

  <PayDialog
      v-model:show="isPay"
      :current-pay-type="currentPayType"
      :pay-info="payUrl"
  />
</template>

<style scoped lang="less">
.momo-pay-box {
  margin-top: 16px;
  .pay-fixed-right {
    color: rgba(0, 0, 0, 0.8);
  }

  .quick-recharge-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    span {
      font-family: PingFang SC;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #666;
    }
    p {
      font-family: PingFang SC;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      color: #09794D;
    }
  }

  .sale-btn-box {
    margin-top: 48px;
    border-radius: 8px;

    .van-button {
      border-radius: 8px;
      background: #2EBD85;
    }
  }
}
</style>

<style lang="less">
.momo-pay-box {
  .van-field {
    background: #ECFDF6;
    border-radius: 8px;
  }
  .pay-fixed-label label {
    color: rgba(0, 0, 0, 0.8);
  }
  .van-field__control {
    color: #000;
    //font-weight: 700;
  }
  .van-icon__image {
    width: 16px;
    height: 16px;
  }
}
</style>