<template>
  <div class="orderDetails-wrap">
    <van-nav-bar
        :border="false"
        left-arrow
        title="Chi tiết đơn hàng"
        @click-left="onClickLeft"
    >
      <template #right>
        <a :href="serviceUrl" target="_blank">
          <van-icon :name="themeImgs.commonServiceIcon"/>
        </a>
      </template>
    </van-nav-bar>
    <div class="orderDetails-main-wrap">
      <div class="progress-wrap">
        <div :class="{'active': orderDetails.status === 2}" class="step-item-box">
          <div :style="`background:url(${orderDetails.status >= 2 ? themeImgs.orderDetailsProcessActiveIcon : themeImgs.orderDetailsProcessIcon})`"
               class="step-icon order-icon"></div>
          <span>Chờ trả</span>
        </div>
        <div :class="{'active': orderDetails.status === 3}" class="step-item-box">
          <div :style="`background:url(${orderDetails.status >= 3 ? themeImgs.orderDetailsProcessActiveIcon : themeImgs.orderDetailsProcessIcon})`"
               class="step-icon order-icon"></div>
          <span>Đã trả</span>
        </div>
        <div :class="{'active': orderDetails.status === 4}" class="step-item-box">
          <div :style="`background:url(${orderDetails.status >= 4 ? themeImgs.orderDetailsProcessActiveIcon : themeImgs.orderDetailsProcessIcon})`"
               class="step-icon order-icon"></div>
          <span>Xác nhận tiền</span>
        </div>
        <div :class="{'active': orderDetails.status === 5}" class="step-item-box">
          <div :style="`background:url(${(orderDetails.status >= 5) ? themeImgs.orderDetailsProcessActiveIcon : themeImgs.orderDetailsProcessIcon})`"
               class="step-icon order-icon"></div>
          <span>
            Đơn quá hạn
<!--            {{ !orderDetails.voucher ? 'Hủy bỏ' : 'Quá thời gian' }}-->
          </span>
        </div>
      </div>
      <div class="order-detail-wrap">
        <div class="item-box">
          <span>{{ t('订单编号') }}：{{ orderDetails.order_sn }}</span>
          <van-image :src="themeImgs.commonCopyIcon" @click="handleCopy(orderDetails.order_sn)"/>
        </div>

        <div class="main-detail-box">
          <div class="detail-box">
            <div class="detail-item-box">
              <span class="label">Tài khoản người mua</span>
              <div class="right-box">
                <span>{{ orderDetails.username }}</span>
                <van-image :src="themeImgs.commonCopyIcon" @click="handleCopy(orderDetails.buy_name)"/>
              </div>
            </div>
<!--            <div class="detail-item-box">-->
<!--              <span class="label">{{ t('买家昵称') }}</span>-->
<!--              <div class="right-box">-->
<!--                <span>{{ orderDetails.buy_nick_name }}</span>-->
<!--              </div>-->
<!--            </div>-->
            <div class="detail-item-box">
              <span class="label">Tổng tiền đơn</span>
              <div class="right-box">
                <span>{{ orderDetails.sell_amount }}</span>
              </div>
            </div>
            <div class="detail-item-box">
              <span class="label">Số tiền thực tế</span>
              <div class="right-box">
                <span>{{ orderDetails.actual_amount }}</span>
              </div>
            </div>
            <div class="detail-item-box">
              <span class="label">{{ t('付款方式') }}</span>
              <div class="right-box">
                <span>{{ t('银行卡') }}</span>
              </div>
            </div>
            <div class="detail-item-box">
              <span class="label">Tên ngân hàng</span>
              <div class="right-box">
                <span>{{ orderDetails.bank }}</span>
              </div>
            </div>
            <div class="detail-item-box">
              <span class="label">{{ t('银行卡号') }}</span>
              <div class="right-box">
                <span>{{ orderDetails.card }}</span>
                <van-image :src="themeImgs.commonCopyIcon" @click="handleCopy(orderDetails.card)"/>
              </div>
            </div>
            <div class="detail-item-box">
              <span class="label">Thời gian tạo</span>
              <div class="right-box">
                <span>{{ orderDetails.created_at }}</span>
              </div>
            </div>
          </div>
          <p v-if="[2, 3].includes(orderDetails.status)" class="timer">
            {{ orderDetails.status === 2 ? t('等待买家付款') : t('买家已付款') }}
          </p>
          <div v-if="orderDetails.status !== 2 && orderDetails.voucher" class="view-voucher-btn-outer">
            <div class="view-voucher-btn" @click="checkShowPaymentVoucher">
              {{ t('查看凭证') }}
            </div>
          </div>
          <div class="btn-box">
            <div v-if="orderDetails.status === 2" class="cancel-btn btn" @click="handleCancelOrder">
              {{ t('取消订单') }}
            </div>
            <div v-if="orderDetails.status === 3" class="comfirm-btn btn" @click="showPayDialog = true">
              {{ t('确认打币') }}
            </div>
          </div>
        </div>

        <!-- 订单已取消 -->
<!--        <div v-if="orderDetails.status === 5" class="main-detail-box">-->
<!--          <div class="detail-box">-->
<!--            <div class="detail-item-box">-->
<!--              <span class="label">{{ t('创建时间') }}</span>-->
<!--              <div class="right-box">-->
<!--                <span>{{ orderDetails.created_at }}</span>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="detail-item-box">-->
<!--              <span class="label">{{ t('取消时间') }}</span>-->
<!--              <div class="right-box">-->
<!--                <span>{{ orderDetails.updated_at }}</span>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="voucher">{{ t('订单已取消') }}</div>-->
<!--          </div>-->
<!--          <div v-if="orderDetails.content" class="detail-box">-->
<!--            <div class="detail-item-box">-->
<!--              <span class="label">{{ t('撤单备注') }}</span>-->
<!--              <div class="right-box">-->
<!--                <span>{{ orderDetails.content }}</span>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </div>
    <!-- 付款凭证弹框 -->
    <van-dialog
        v-model:show="showPaymentVoucherDialog"
        class="code-dialog"
        closeOnClickOverlay
        :title="t('买家付款凭证')"
        :show-confirm-button="false"
    >
      <div :style="`background:url(${themeImgs.commonCodeBgImg})`" class="code-box">
        <van-image :src="orderDetails.voucher" @click="openImagePreview(orderDetails.pay_pic)"/>
        <span class="name">{{ orderDetails.username }}</span>
      </div>
    </van-dialog>

    <!-- 打币温馨提示 -->
    <van-dialog
        v-model:show="showPayDialog"
        :before-close="onBeforePayClose"
        show-cancel-button
        title="Nhắc nhở quan trọng">
      <div class="pay-desc">
        {{ hintMsg.confirmMoneyInfoBySell ? hintMsg.confirmMoneyInfoBySell : t('请核实资金到账后再确认打币，切勿未收到转账直接打币造成损失。') }}
      </div>
    </van-dialog>

    <!-- 支付密码弹出框 -->
    <van-dialog
        v-model:show="showPayPasswordDialog"
        :before-close="onBeforeClose"
        show-cancel-button
        :title="t('请输入支付密码')">
      <van-field
          v-model="pay_password"
          class="cancel-order-dialog-cell"
          :label="t('支付密码')"
          maxlength="6"
          :placeholder="t('请输入支付密码')"
          type="password"
      />
    </van-dialog>
  </div>
</template>

<script>
import {computed, onMounted, ref, watchEffect} from 'vue'
import {getQuickSellOrderDetails, getHint, postConfirmOrder, postCancelOrder} from '@/api/api'
import {ImagePreview, Step, Steps, Toast} from 'vant'
import {onBeforeRouteLeave, useRoute, useRouter} from 'vue-router'
import useClipboard from 'vue-clipboard3'
import {useStore} from 'vuex'
import { useI18n } from 'vue-i18n'

export default {
  components: {
    [Step.name]: Step,
    [Steps.name]: Steps
  },
  setup() {
    const { t, locale } = useI18n()
    const $route = useRoute()
    const $router = useRouter()
    const store = useStore()
    const {toClipboard} = useClipboard()
    const hintMsg = ref({})
    // 点击导航栏左侧返回键
    const onClickLeft = () => {
      if (history.state.back && history.state.back.indexOf($route.path) > -1 || !history.state.back) {
        $router.push('/order')
      } else {
        history.back()
      }
    }
    const handleGetHint = () => {
      getHint().then(res => {
        if (res.result == 200000) {
          hintMsg.value = res.data
        }
      })
    }
    onMounted(() => {
      handleGetHint()
    })
    // 定义在线客服地址
    const serviceUrl = ref(store.state.sysConfig.manage)
    // 获取订单详情
    const handleGetOrderDetails = () => {
      getQuickSellOrderDetails({
        order_sn: $route.query.order_sn
      }).then(res => {
        if (res.result === 200000) {
          orderDetails.value = res.data.info
          // status：1发起订单 2卖家确认 3买家已转账 4买家上传凭证 5卖家打币 6卖家取消 7买家取消 8系统取消 9系统自动打币 10卖家暂停打币
        } else {
          Toast.fail(res.msg)
        }
      })
    }

    // 当路由参数变化时，页面数据无法更新的时候
    watchEffect(() => {
      if ($route.path === '/quickSellOrderDetails') {
        handleGetOrderDetails()
      }
    })

    // 存储订单信息
    const orderDetails = ref({})

    // 复制
    const handleCopy = async (content) => {
      try {
        await toClipboard(content)
        Toast.success(t('复制成功'))
      } catch (e) {
        console.error(e)
      }
    }

    // 当前进度索引值
    const stepActiveIndex = ref(1)

    // 是否显示支付密码弹框
    const showPayPasswordDialog = ref(false)

    // 支付密码变量
    const pay_password = ref('')

    // 取消默认关闭弹框事件
    const onBeforeClose = (action) => {
      // 点击了确定按钮
      if (action === "confirm") {
        if (!pay_password.value) {
          Toast.fail(t('请输入支付密码'))
          return false
        } else {
          store.commit('setUntreatedNum', 0)
          handleComfirmSellerPay()
        }
      }
      // 点击了取消按钮
      else {
        showPayPasswordDialog.value = false
        pay_password.value = ''
      }
    }

    // 卖家确认打币
    const handleComfirmSellerPay = () => {
      postConfirmOrder({
        id: orderDetails.value.id,
        pay_password: pay_password.value
      }).then(res => {
        if (res.result === 200000) {
          showPayPasswordDialog.value = false
          pay_password.value = ''
          Toast.success(t('打币成功'))
          handleGetOrderDetails()
        } else {
          Toast.fail(res.msg)
        }
      })
    }

    //  取消订单
    const handleCancelOrder = () => {
      postCancelOrder({
        id: orderDetails.value.id
      }).then(res => {
        if (res.result === 200000) {
          Toast.success('thành công')
          handleGetOrderDetails()
        } else {
          Toast.fail(res.msg)
        }
      })
    }

    onBeforeRouteLeave((to, from, next) => {
      if (to.path == "/buyCoin") {
        next('/home')
      } else {
        next()
      }
    })

    // 温馨提示
    const showPayDialog = ref(false)

    // 取消默认关闭弹框事件
    const onBeforePayClose = (action) => {
      // 点击了确定按钮
      if (action === "confirm") {
        showPayDialog.value = false
        showPayPasswordDialog.value = true
      }
      // 点击了取消按钮
      else {
        showPayDialog.value = false
      }
    }

    // mapstate--皮肤对应的图片
    const themeImgs = computed(() => store.state.themeImgs)

    // 图片预览
    const openImagePreview = (url) => {
      ImagePreview({
        images: [url],
        closeable: true,
      })
    }

    // 是否显示付款凭证弹框
    const showPaymentVoucherDialog = ref(false)

    //  查看凭证
    const checkShowPaymentVoucher = () => {
      showPaymentVoucherDialog.value = true
    }
    return {
      onClickLeft,
      orderDetails,
      stepActiveIndex,
      handleCopy,
      checkShowPaymentVoucher,
      showPaymentVoucherDialog,
      handleComfirmSellerPay,
      serviceUrl,
      themeImgs,
      showPayPasswordDialog,
      showPayDialog,
      pay_password,
      onBeforeClose,
      onBeforePayClose,
      store,
      hintMsg,
      openImagePreview,
      t,
      locale,
      handleCancelOrder
    }
  }
}
</script>

<style lang="less" scoped>
.orderDetails-wrap {
  height: 100%;
  overflow: hidden;

  :deep(.van-nav-bar) {
    .van-nav-bar__right {
      .van-icon {
        width: 24px;
        height: 24px;

        .van-icon__image {
          width: 24px;
          height: 24px;
        }
      }
    }
  }

  .orderDetails-main-wrap {
    height: calc(100% - 44px);
    padding: 10px 16px 24px;
    //background: var(--mainBgColor);
    overflow: scroll;

    .progress-wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 60px;
      padding: 0 41px;
      //background: var(--boxBgColor);
      background: #E3E3E3;
      border-radius: 17px;

      :deep(.van-steps) {
        background: inherit;
        border-radius: 8px;

        .van-step__circle-container {
          background: inherit;

          .van-image {
            width: 24px;
            height: 24px;
          }
        }
      }

      .step-item-box {
        display: flex;
        flex-direction: column;
        align-items: center;

        .step-icon {
          position: relative;
          width: 24px;
          height: 24px;
          margin-bottom: 6px;
          background-size: 100% 100% !important;
        }

        span {
          font-size: 10px;
          //color: var(--textCommonColor);
          color: #979797;
          font-weight: 400;
        }

        &:not(:last-child) {
          .step-icon {
            &:after {
              position: absolute;
              content: '';
              width: 50px;
              height: 0;
              border: 1px solid #D2D2D2;
              top: 12px;
              left: 24px;
            }
          }
        }

        &.active {
          .step-icon {
            &:after {
              //border: 1px solid var(--textColor);
              border: 1px solid #FFF;
            }
          }

          span {
            //color: var(--textColor);
            color: #666666;
            font-weight: 600;
          }
        }
      }
    }

    .order-detail-wrap {
      margin-top: 18px;

      .item-box {
        display: flex;
        align-items: center;
        margin-bottom: 6px;
        font-family: PingFang SC;
        font-size: 12px;
        font-weight: 500;
        line-height: 16.8px;

        span {
          margin-right: 6px;
        }

        .van-image {
          width: 16px;
          height: 16px;
          //margin-left: 6px;
        }
      }

      .main-detail-box {
        .detail-box {
          margin-bottom: 10px;
          padding: 14px 10px;
          //background: var(--boxBgColor);
          background: #F4F4F4;
          border-radius: 8px;
          font-family: PingFang SC;
          color: #666;

          &:last-child {
            margin-bottom: 0;
          }

          .detail-item-box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 6px;
            //color: var(--textCommonColor);
            font-family: PingFang SC;


            &:last-child {
              margin-bottom: 0;
            }

            .label {
              flex: 0 0 40%;
              font-size: 16px;
              font-weight: 500;
            }

            .right-box {
              display: flex;
              align-items: center;

              span {
                font-size: 16px;
                font-weight: 400;
              }

              .van-image {
                width: 16px;
                height: 16px;
                margin-left: 6px;
              }
            }
          }

          .voucher {
            font-weight: 500;
            font-size: 18px;
            //color: var(--textColor);
            //color: #666666;
            text-align: center;
          }
        }

        .timer {
          display: flex;
          align-items: center;
          margin-bottom: 6px;
          font-size: 12px;
          font-weight: 500;
          color: var(--textColor);

          .van-count-down {
            line-height: unset;
            color: var(--textColor);
            font-size: 12px;
            font-weight: 500;
          }
        }

        .btn-box {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 15px;

          .btn {
            width: 104px;
            height: 30px;
            line-height: 30px;
            border-radius: 8px;
            text-align: center;
            font-weight: 500;
            font-family: PingFang SC;
            font-size: 16px;
          }

          .cancel-btn {
            //background: var(--cancelBtnBgColor);
            //color: var(--textColor);
            margin-right: 20px;
            background: rgba(151, 151, 151, .6);
            color: #fff;
          }

          .upload-btn {
            //background: var(--btnBgColor);
            color: #fff;
            background: #2EBD85;
          }

          .comfirm-btn {
            background: #2EBD85;
            color: #fff;
          }
        }

        .view-voucher-btn-outer {
          position: relative;
          height: 24px;
          margin-top: 10px;

          .view-voucher-btn {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            //display: inline-block;
            font-family: PingFang SC;
            font-size: 16px;
            font-weight: 500;
            line-height: 22.4px;
            text-align: center;
            color: #535C66;

            &::after {
              content: '';
              position: absolute;
              left: 0;
              bottom: -1px;
              width: 100%;
              height: 1px;
              background: #535C66;
            }
          }
        }


        .tip {
          margin-top: 10px;
          margin-bottom: 30px;
          //color: var(--textCommonColor);
          font-family: PingFang SC;
          font-size: 14px;
          font-weight: 400;
          color: #B2B2B2;
          text-align: center;
        }
      }
    }
  }

  :deep(.van-dialog) {
    &.code-dialog {
      .van-dialog__header {
        //background: var(--orderDialogHeaderBgColor);
        //background: linear-gradient(180deg, #DBDBDB 0%, #C0C0C0 100%);
      }

      .van-dialog__confirm {
        //background: var(--orderDialogHeaderBgColor);
        //color: var(--orderDialogTextColor);
      }
    }

    .van-dialog__header {
      padding: 16px 0;
      font-weight: 500;
      font-size: 18px;
      //color: var(--orderDialogTextColor);
      color: #535C66;
    }

    .van-button__text {
      font-family: PingFang SC;
      font-size: 17px;
      font-weight: 400;
      color: #535C66;
    }

    .code-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 275px;
      background-size: 100% 100% !important;

      .van-image {
        width: 158px;
        height: 158px;
        border-radius: 8px;
      }

      .name {
        margin-top: 20px;
        font-size: 16px;
        color: rgba(83, 92, 102, 0.7);
      }
    }

    .cancel-order-dialog-cell {
      flex-direction: column;
      background: none;

      input::placeholder {
        color: rgba(83, 92, 102, 0.6);
      }

      textarea::placeholder {
        color: rgba(83, 92, 102, 0.6);
      }

      .van-field__label {
        margin-bottom: 7px;

        label {
          color: #535C66;
          font-family: PingFang SC;
          font-size: 16px;
          font-weight: 500;
          white-space: nowrap;
        }
      }

      .van-field__control {
        color: #535C66;
        font-family: PingFang SC;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
}

.pay-desc {
  padding: 0 5% 5% 5%;
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: rgba(83, 92, 102, 0.9);
}
</style>
