<script setup>
import {computed, defineEmits, ref, defineProps} from 'vue'
import {useStore} from 'vuex'

const props = defineProps({
  amount: {
    type: String,
    default: ''
  },
  data: {
    type: Array,
    default: []
  }
})

const emit = defineEmits(['handleAmount'])

const store = useStore()

// mapstate--皮肤对应的图片
const themeImgs = computed(() => store.state.themeImgs)

//  金额选中
const handleSelected = (item, index) => {
  emit('handleAmount', item, index)
}

const formatVolume = (volume) => {
  return (volume / 1000).toFixed(2) + 'K';
}
</script>

<template>
  <div class="quick-amounts">
    <div
        v-for="(item, index) in props.data"
        class="quick-button"
        :class="{'quick-button-selected': parseInt(props.amount) === item.amount}"
        @click="handleSelected(item, index)">
      <span>{{ formatVolume(item.amount) }}</span>
      <van-icon
          v-show="item.isHot"
          :name="themeImgs.commonHot"
          :size="16"
      />
    </div>
  </div>
</template>

<style scoped lang="less">
.quick-amounts {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-top: 16px;
  .quick-button {
    border: 1px solid rgba(83, 92, 102, 0.2);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 104px;
    height: 40px;
    position: relative;
    span {
      font-family: DIN Offc Pro;
      font-size: 16px;
      font-weight: 700;
      text-align: center;
    }
    i {
      position: absolute;
      top: -8px;
      right: 0;
    }
    &.quick-button-selected {
      background: #2EBD85;
      border-color: #2EBD85;
    }
  }
}
</style>