<script setup>
import {defineProps, defineEmits, computed} from 'vue'
import LoadMoreIcon from '@/assets/theme/green/images/checkoutCounter/load_more.png'

const props = defineProps({
  list: {
    type: Array,
    default: []
  },
  currentData: {
    type: Object,
    default: {
      count_currency: '',
      created_at: '',
      currency: '',
      freeze_currency: '',
      id: 0,
      order_sn: '',
      pay_type: '',
      status: '',
      traded_currency: '',
      type: 0
    }
  },
  count: {
    type: Number,
    default: 0
  }
})

const emit = defineEmits(['update:currentData', 'updatePage'])

//  是否展示加载更多
const canLoadMore = computed(() => {
  // console.log('222', props.count > 6)
  return props.count > 6
})

</script>

<template>
  <div class="order-list-box">
    <div class="order-list">
      <!--  @click="emit('update:currentData', item)" -->
      <div
          v-if="props.list.length > 0"
          v-for="(item, index) in props.list"
          :key="index"
          class="order-list-item"
          :class="{ 'active-item': props.currentData.id === item.id }">
        ₫ {{ item.currency }}
      </div>
    </div>
<!--    <div v-if="props.list.length === 0" class="empty">-->
<!--      Chưa có đơn hàng-->
<!--    </div>-->
  </div>
  <div
      v-show="canLoadMore"
      class="order-load-more"
      @click="emit('updatePage')">
    <van-icon :name="LoadMoreIcon" class="more-icon" />
    <span>{{ count === props.list.length ? 'Ẩn' : 'Hơn' }}</span>
  </div>
</template>

<style scoped lang="less">
.order-list-box {
  margin-top: 10px;
  position: relative;
  padding: 0 5px;
  height: 90px;
  overflow-x: hidden;
  overflow-y: auto;
  .order-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    .order-list-item {
      height: 37px;
      border: 1px solid rgba(83, 92, 102, 0.2);
      border-radius: 10px;
      line-height: 37px;
      text-align: center;
      font-family: DIN Offc Pro;
      font-size: 13px;
      font-weight: 700;
      color: rgba(0, 0, 0, 0.8);
      display: inline-block;
      white-space: pre;
      overflow: hidden;
      text-overflow: ellipsis;

      &.active-item {
        background: #2EBD85;
        border-color: #2EBD85;
      }
    }
  }

  .empty {
    position: absolute;
    left: 50%;
    font-size: 14px;
    color: #979797;
    font-family: DIN Offc Pro;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
.order-load-more {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  height: 40px;

  span {
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: 500;
    color: #2EBD85;
    line-height: 40px;
    text-align: center;
    margin-left: 5px;
  }
}
</style>

<style lang="less">
.order-load-more .van-icon__image {
  width: 16px;
  height: 16px;
}
</style>
