<template>
  <div class="identityVerification-wrap">
    <van-nav-bar
        :border="false"
        left-arrow
        :title="t('身份信息核验')"
        @click-left="onClickLeft"
    />
    <div class="identityVerification-main-wrap">
      <div class="identityVerification-main-box">
        <h3>{{ t('为了防范身份信息被冒用，保障资金安全，需进行人脸识别，确保是本人操作') }}</h3>
        <van-image :src="themeImgs.myRecognitionImg"/>
        <div class="step-box">
          <p>
            <span class="num">①</span>
            <span>{{ t('保持光线充足') }}</span>
          </p>
          <p>
            <span class="num">②</span>
            <span>{{ t('竖直持握手机，正对镜头') }}</span>
          </p>
          <p>
            <span class="num">③</span>
            <span>{{ t('跟随提示做出动作') }}</span>
          </p>
        </div>
        <div class="start-btn-box">
          <van-button block @click="handleBegin">{{ t('开始识别') }}</van-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed} from 'vue'
import {useStore} from 'vuex'
import {useRouter} from 'vue-router'

import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const store = useStore()

const $router = useRouter()
// 点击导航栏左侧返回键/取消按钮
const onClickLeft = () => {
  // history.back()
  $router.push({
    path: 'certification',
    query: {
      id: 1
    }
  })
}

const handleBegin = () => {
  if (store.state.isApp) {
    let certificationParams = window.localStorage.getItem('certificationParams') && JSON.parse(window.localStorage.getItem('certificationParams'))
    certificationParams.token = store.state.loginInfos.token
    certificationParams.apiURL = BASE_DEFINED_URL
    certificationParams.uploadURL = BASE_DEFINED_URL
    // 调用APP端的方法并传参给APP端
    if (window.Certification && window.Certification.setCertification) { // 安卓
      // 传参给Android
      try {
        window.Certification.setCertification(JSON.stringify(certificationParams));
      } catch (error) {
        console.log(error);
      }
    } else if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.setCertification) {
      // 传参给IOS
      try {
        window.webkit.messageHandlers.setCertification.postMessage(JSON.stringify(certificationParams));
      } catch (error) {
        console.log(error);
      }
    }
  } else {
    $router.push('/recordVideo')
  }
}

// mapstate--皮肤对应的图片
const themeImgs = computed(() => store.state.themeImgs)

</script>

<style lang="less" scoped>
.identityVerification-wrap {
  .identityVerification-main-wrap {
    height: calc(100vh - 46px);
    padding: 0 16px;
    //background: var(--mainBgColor);

    .identityVerification-main-box {
      h3 {
        padding: 32px 18px;
        font-weight: 500;
        color: var(--textCommonColor);
        font-size: 16px;
      }

      .van-image {
        display: block;
        width: 227px;
        height: 164px;
        margin: 0 auto;
      }

      .step-box {
        padding-top: 62px;

        p {
          display: flex;
          align-items: center;
          padding-left: 85px;
          margin-bottom: 14px;

          span {
            font-weight: 500;
            color: var(--textCommonColor);

            &.num {
              margin-right: 8px;
              font-size: 22px;
              color: var(--textColor);
            }

            &:last-child {
              padding-top: 5px;
            }
          }
        }
      }

      .start-btn-box {
        margin-top: 38px;
        border-radius: 6px;
        height: 44px;

        .van-button {
          border-radius: 6px;
          background: #2EBD85;
          font-family: PingFang SC;
          font-size: 16px;
          font-weight: 500;
          line-height: 44px;
          text-align: center;
          color: #FFF;
        }
      }
    }
  }
}
</style>
