<script setup>
import {computed} from 'vue'
import {useStore} from 'vuex'
import Loading1 from '@/assets/theme/green/images/checkoutCounter/loading1.gif'
import Loading2 from '@/assets/theme/green/images/checkoutCounter/loading2.png'
import Loading3 from '@/assets/theme/green/images/checkoutCounter/loading3.png'
import PaySuccessImg from "@/assets/theme/green/images/checkoutCounter/pay_success.png";
import NoDataStatusImg from "@/assets/theme/green/images/checkoutCounter/no_data_statuspng.png";

const props = defineProps({
  message: {
    type: String,
    default: ''
  }
})

const store = useStore()

const quickSellNotify = computed(() => store.state.quickSellNotify)

const viewInfo = computed(() => {
  console.log('quickSellNotify', quickSellNotify.value)
  if (quickSellNotify.value === '3') {
    return {
      img: Loading3,
      text: `Vui lòng không đóng trang <br/>
             đơn hàng đang được xác minh... <br/>
             vui lòng kiên nhẫn chờ đợi.～`
    }
  }
  if (quickSellNotify.value === '2') {
    return {
      img: PaySuccessImg,
      text: 'thanh toán thành công～'
    }
  }

  if (quickSellNotify.value === '6') {
    return {
      img: NoDataStatusImg,
      text: `Chưa có đơn hàng nào khớp, ${props.message}`
    }
  }
  return {
    img: Loading2,
    text: 'Đơn hàng đang được tạo <br/> vui lòng đợi trong giây lát～'
  }
})
</script>

<template>
  <div class="loading-box">
    <van-image v-if="quickSellNotify !== '2'" class="loading-icon" :src="Loading1" />
    <van-image :src="viewInfo.img" />
    <span v-html="viewInfo.text"></span>
  </div>
</template>

<style scoped lang="less">
.loading-box {
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding: 0 15px;
  overflow: hidden;
  .loading-icon {
    width: 60px;
    margin: 0 auto;
  }
  span {
    font-family: PingFang SC;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    text-align: center;
    color: rgba(0, 0, 0, 0.8);
  }
}
</style>
