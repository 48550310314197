<script setup>
import { defineProps, defineEmits, watch, ref, computed } from 'vue'
import {useStore} from 'vuex'
import {Toast} from 'vant'
import QRCode from 'qrcode'
import useClipboard from 'vue-clipboard3'
import { useI18n } from 'vue-i18n'

const emit = defineEmits(['update:show'])
const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
  currentPayType: {
    type: Object,
    default: {
      id: 0,
      icon: '',
      name: '',
      bankId: ''
    }
  },
  payInfo: {
    type: String,
    default: ''
  }
})

const { t, locale } = useI18n()

const isShow = ref(false)

// const qrcodeUrl = ref('')
//  二维码渲染
// const renderQrcode = () => {
//   if (props.payInfo.qrcodeField) {
//     QRCode.toDataURL(props.payInfo.qrcodeField, { errorCorrectionLevel: 'L' }).then(url => {
//       qrcodeUrl.value = url
//     }).catch(err => {
//       console.error(err)
//       Toast.fail('Failed to generate QR code')
//     })
//   }
// }

watch(() => props.show, () => {
  isShow.value = props.show
  // if (props.currentPayType.bankId === '11') {
  //   renderQrcode()
  // }
})

// const qrCode = computed(() => {
//   return qrcodeUrl.value || props.payInfo.qrcodeField
// })

const store = useStore()
// mapstate--皮肤对应的图片
const themeImgs = computed(() => store.state.themeImgs)

//  下载二维码
// const handleDownload = () => {
//   // 创建一个隐藏的<a>标签
//   const link = document.createElement('a')
//   link.href = qrCode.value
//   link.download = 'pay-qrcode'
//
//   document.body.appendChild(link)
//   link.click()
//   document.body.removeChild(link)
// }

//  关闭二维码
const handleClose = () => {
  console.log('333333')
  // qrcodeUrl.value = ''
  emit('update:show', false)
}

// 复制
// const {toClipboard} = useClipboard()
// const handleCopy = async (content) => {
//   try {
//     await toClipboard(content)
//     Toast.success(t('复制成功'))
//   } catch (e) {
//     console.error(e)
//   }
// }
</script>

<template>
  <van-popup
      v-model:show="isShow"
      @close="handleClose"
      position="bottom"
      round
      class="paying-box-dialog"
  >
    <div class="paying-box">
<!--      <van-image class="pay-close" :src="themeImgs.homeCloseIcon"></van-image>-->
      <div class="pay-header">
        <div class="pay-close" @click="handleClose">X</div>
<!--        <van-icon class="pay-icon" :name="props.currentPayType.icon" />-->
<!--        <span>{{ props.currentPayType.name }}</span>-->
      </div>
<!--      <div class="pay-card pay-info">-->
<!--        <div class="pay-info-item">-->
<!--          <span>Số tài khoảnSố tài khoản</span>-->
<!--          <div class="copy-text-box">-->
<!--            <p>{{ props.payInfo.realName }}</p>-->

<!--            <van-image-->
<!--                :src="themeImgs.commonCopyIcon"-->
<!--                @click.prevent="handleCopy(props.payInfo.realName)"-->
<!--            />-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="pay-info-item">-->
<!--          <span>Nội dung lời nhắn</span>-->
<!--          <div class="copy-text-box">-->
<!--            <p>{{ props.payInfo.card }}</p>-->
<!--            <van-image-->
<!--                :src="themeImgs.commonCopyIcon"-->
<!--                @click.prevent="handleCopy(props.payInfo.card)"-->
<!--            />-->
<!--          </div>-->
<!--        </div>-->

<!--        <div v-if="props.currentPayType.id === 3" class="pay-info-item">-->
<!--          <span>Nội dung chuyển khoản</span>-->
<!--          <div class="copy-text-box">-->
<!--            <p class="pay-info-item-remark">-->
<!--              {{ props.payInfo.remark }}-->
<!--            </p>-->
<!--            <van-image-->
<!--                :src="themeImgs.commonCopyIcon"-->
<!--                @click.prevent="handleCopy(props.payInfo.remark)"-->
<!--            />-->
<!--          </div>-->
<!--        </div>-->

<!--        <div class="pay-info-item">-->
<!--          <span>Số tiền (đ):Số tiền (đ)</span>-->
<!--          <div class="copy-text-box">-->
<!--            <p> {{ props.payInfo.actualAmount }}</p>-->
<!--            <van-image-->
<!--                :src="themeImgs.commonCopyIcon"-->
<!--                @click.prevent="handleCopy(props.payInfo.actualAmount)"-->
<!--            />-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="pay-card pay-qrcode">-->
<!--        <div class="pay-qrcode-title">Lưu mã QR</div>-->
<!--        <div-->
<!--            class="pay-qrcode-model"-->
<!--            :style="{backgroundImage: `url('${themeImgs.orderDetailQrCodeBgImg}')`}">-->
<!--          <van-image-->
<!--              class="qrcode-img"-->
<!--              fit="fill"-->
<!--              :src="qrCode"-->
<!--          />-->
<!--        </div>-->

<!--        <div class="pay-qrcode-save" @click="handleDownload">-->
<!--          <van-icon class="save-photo-icon" :name="themeImgs.quickPayWallIcon" />-->
<!--          <span>lưu vào album</span>-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="pay-card pay-remark">-->
<!--        <div class="pay-remark-header">-->
<!--          Lưu ý:-->
<!--        </div>-->
<!--        <div class="pay-remark-content">-->
<!--          · Nếu không thể sử dụng mã QR Code, vui lòng sao chép tài khoản thanh toán.-->
<!--          · Bạn vui lòng kiểm tra kỹ thông tin trước khi thực hiện bất kỳ thao tác nào. Chúng tôi không chịu trách nhiệm về tính xác thực thông tin chuyển khoản.-->
<!--          ·Khi chuyển khoản không làm mới trình duyệt.-->
<!--        </div>-->
<!--      </div>-->
    </div>
    <div class="pay-iframe-box">
      <iframe
          :src="props.payInfo"
          width="100%"
          height="100%"
          frameborder="none"
          allowfullscreen
      />
    </div>
  </van-popup>
</template>

<style scoped lang="less">
.paying-box {
  //padding: 15px;
  max-height: 100%;
  //overflow-y: auto;

  .pay-header {
    //display: flex;
    //align-items: center;
    //justify-content: center;
    height: 50px;
    position: relative;
    .pay-close {
      position: absolute;
      top: 0;
      right: 10px;
      font-size: 18px;
      color: #999;
    }

    .pay-icon {
      width: 24px;
      height: 24px;
    }
    span {
      margin-left: 6px;
      font-family: PingFang SC;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }
  }

  //.pay-card {
  //  box-shadow: 0 1px 4px 0 #5E5E5E40;
  //  background: #FFFFFF;
  //  border-radius: 8px;
  //  margin-top: 15px;
  //  padding: 15px;
  //}
  //.pay-info {
  //  text-align: center;
  //  .pay-info-item {
  //    font-family: PingFang SC;
  //    font-weight: 500;
  //    margin-bottom: 15px;
  //    &:last-child {
  //      margin: 0;
  //    }
  //    span {
  //      font-size: 12px;
  //      line-height: 12px;
  //      color: rgba(0, 0, 0, 0.6);
  //    }
  //
  //    .copy-text-box {
  //      display: flex;
  //      align-items: center;
  //      justify-content: center;
  //      margin-top: 5px;
  //      p {
  //        font-size: 16px;
  //        line-height: 16px;
  //        color: #000000;
  //        margin-right: 10px;
  //
  //        &.pay-info-item-remark {
  //          color: #F50000;
  //        }
  //      }
  //      .van-image {
  //        width: 16px;
  //        height: 16px;
  //      }
  //    }
  //  }
  //}
  //.pay-qrcode {
  //  .pay-qrcode-title {
  //    font-family: PingFang SC;
  //    font-size: 14px;
  //    font-weight: 500;
  //    line-height: 14px;
  //    text-align: center;
  //    color: rgba(0, 0, 0, 0.8);
  //    margin-bottom: 10px;
  //  }
  //  .pay-qrcode-model {
  //    width: 180px;
  //    height: 180px;
  //    background-repeat: no-repeat;
  //    background-size: 100% 100%;
  //    margin: auto;
  //    padding: 15px;
  //    .qrcode-img {
  //      width: calc(180px - 30px);
  //      height: calc(180px - 30px);
  //    }
  //  }
  //}
  //.pay-qrcode-save {
  //  display: flex;
  //  align-items: center;
  //  justify-content: center;
  //  margin-top: 10px;
  //  span {
  //    margin-left: 6px;
  //    font-family: PingFang SC;
  //    font-size: 12px;
  //    font-weight: 500;
  //    line-height: 12px;
  //    color: #2EBD85;
  //  }
  //}
  //.pay-remark {
  //  background: #ECFDF6;
  //  font-family: PingFang SC;
  //  .pay-remark-header {
  //    font-size: 14px;
  //    font-weight: 500;
  //    line-height: 14px;
  //    color: rgba(0, 0, 0, 0.8);
  //    margin-bottom: 4px;
  //  }
  //  .pay-remark-content {
  //    font-size: 12px;
  //    font-weight: 500;
  //    line-height: 18px;
  //    color: #666666;
  //  }
  //}
}

.pay-iframe-box {
  height: calc(90vh - 50px);
  width: 100%;
}
</style>

<style lang="less">
.paying-box-dialog {
  height: 90vh;
  overflow-y: auto;
}

.save-photo-icon .van-icon__image {
  width: 18px;
  height: 18px;
}
</style>