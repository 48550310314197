<template>
  <van-nav-bar
      left-arrow
      title="Quét mã"
      :border="false"
      @click-left="onClickLeft"
  />
  <div class="reader" id="reader"></div>
</template>

<script setup>
import {onBeforeUnmount, onMounted, ref} from 'vue'
import {Html5Qrcode} from "html5-qrcode";
import { useRouter } from 'vue-router'
import { Toast } from 'vant'
import { useStore } from 'vuex'
import { detectOS } from '@/utils/index'


const router = useRouter()
const store = useStore()

let html5QrCode = null
let cameraId = null

  // This method will trigger user permissions
  // Html5Qrcode.getCameras().then(devices => {
  //       /**
  //        * devices would be an array of objects of type:
  //        * { id: "id", label: "label" }
  //        */
  //       console.log('手机摄像头', devices)
  //       if (devices && devices.length) {
  //         // const iosData = [
  //         //   { id: 111111, label: '前置相机' },
  //         //   { id: 222222, label: '后置广角镜头' },
  //         //   { id: 333333, label: '后置超广角镜头' },
  //         //   { id: 444444, label: '后置相机' },
  //         // ]
  //
  //         // const androidData = [
  //         //   { id: 111111, label: 'camera2 1, facing front' },
  //         //   { id: 222222, label: 'camera2 3, facing front' },
  //         //   { id: 333333, label: 'camera2 2, facing back' },
  //         //   { id: 444444, label: 'camera2 0, facing back' },
  //         // ]
  //         const str = detectOS() === 'android' ? 'back' : '后置'
  //         //  获取后置摄像头
  //         const camera = devices.find((device) => device.label.includes(str))
  //         console.log('11111', camera)
  //         //  扫码，只拿后置摄像头
  //         cameraId = camera ? camera.id : null
  //       }
  //       // if (devices && devices.length) {
  //       //   if (devices.length === 1) {
  //       //     cameraId = devices[0].id;
  //       //   } else {
  //       //     cameraId = devices[1].id;
  //       //   }
  //       // }
  //
  //       html5QrCode = new Html5Qrcode("reader", /* verbose= */ true);
  //       startScan()
  //     }
  // ).catch(err => {
  //   // handle err
  //   console.log('err', err)
  // })

let num = 0
onMounted(() => {
  startScan()
})
const startScan = () => {
  console.log('html5QrCode', html5QrCode)
  html5QrCode = new Html5Qrcode("reader", /* verbose= */ true);
  // facingMode: 'user', // 前置摄像头
  // facingMode: { exact: "environment" }, //  后置摄像头
  setTimeout(() => {
    html5QrCode.start(
        { facingMode: 'environment' },
        {
          fps: 1,    // Optional, frame per seconds for qr code scanning
          qrbox: {width: 250, height: 250}  // Optional, if you want bounded box UI
        },
        (decodedText, decodedResult) => {
          console.log('开始扫描', decodedText)
          if (decodedText.includes('order')) {
            store.commit('setIsScanSuccess', true)
            router.replace({
              path: '/home',
              query: { data: JSON.stringify(decodedText) }
            })
            stopScan()
          } else {
            Toast.fail(`Dùng mã QR đúng`)
          }
        },
        (errorMessage) => {
          num++

          if (num === 100) {
            Toast.fail('Quét mã thất bại')
            stopScan()
            router.push('/home')
          }
          console.log('errorMessage', errorMessage)
        })
        .catch((err) => {
          console.log('start 失败', err)
        })
  }, 1000);
}

const stopScan = () => {
  html5QrCode.stop().then((ignore) => {
    // QR Code scanning is stopped.
    console.log('ignore', ignore)
  }).catch((err) => {
    // Stop failed, handle it.
  });
}

const onClickLeft = () => {
  router.push('/home')
}

onBeforeUnmount(() => {
  stopScan()
})
</script>

<style lang='less' scoped>
.scan-message {
  width: 100%;
  height: calc(100% - 46px);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #999;
  font-family: "PingFang SC"
}
.reader {
  height: calc(100% - 46px);

  ::v-deep video {
    height: 100%;
    object-fit: cover;
  }

  ::v-deep #qr-shaded-region {
    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: #FFF;
      animation: upDown 5s ease infinite;
    }
  }
}

@keyframes upDown {
  0% { top: 0; }
  50% { top: 100% }
  100% { top: 0 }
}
</style>