<script setup>
import {computed} from 'vue'
import {useStore} from 'vuex'
import {useRouter} from 'vue-router'
// import {Toast} from 'vant'
import { useI18n } from 'vue-i18n'

// const props = defineProps({
//   memberInfo: {
//     type: Object,
//     default: {}
//   }
// })

const { t, locale } = useI18n()

const store = useStore()

// mapstate--皮肤对应的图片
const themeImgs = computed(() => store.state.themeImgs)

const token = computed(() => store.state.loginInfos.token)

// 打开登录注册弹框
const openLoginReg = () => {
  store.commit('setLoginRegisterInfo', { flag: true })
}

const $router = useRouter()

//  跳转买币
const buyCoin = () => {
  if (!token.value) {
    return openLoginReg()
  }
  $router.push({
    path: '/quickTrade',
    query: { id: 0 }
  })
}

//  跳转卖币
const handleOpenSellCoinUrl = () => {
  if (!token.value) {
    return openLoginReg()
  }
  // if (props.memberInfo.is_name === 1) {
  //   return Toast.fail(t('请先进行实名认证'))
  // }
  // if (props.memberInfo.is_name === 3) {
  //   return Toast.fail(t('实名审核中'))
  // }
  // if (props.memberInfo.is_withdraw === 1) {
  //   return Toast.fail(t('该功能已禁用'))
  // }
  $router.push({
    path: '/quickTrade',
    query: { id: 1 }
  })
}

</script>

<template>
  <div class="enter-box">
    <div class="enter-item-box" @click="buyCoin">
      <i :style="{ backgroundImage: `url('${themeImgs.homeBuyIcon}')` }"></i>
      <span class="title">{{ t('购买') }}</span>
    </div>

    <div class="enter-item-box" @click="handleOpenSellCoinUrl">
      <i :style="{ backgroundImage: `url('${themeImgs.homeSellIcon}')` }"></i>
      <span class="title">{{ t('出售') }}</span>
    </div>
  </div>
</template>

<style scoped lang="less">
.enter-box {
  display: flex;
  justify-content: space-between;
  padding: 0 7px;
  margin-bottom: 16px;

  .enter-item-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 172px;
    height: 40px;
    border-radius: 10px;
    font-family: Roboto;
    font-size: 18px;
    font-weight: 400;
    color: rgba(0, 0, 0, 1);
    box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.02);

    &:first-of-type {
      background: rgba(253, 212, 49, 1);
    }

    &:last-of-type {
      background: rgba(46, 189, 133, 1);
    }

    i {
      width: 22px;
      height: 22px;
      margin-right: 6px;
      background-size: 100% 100%;
    }
  }
}
</style>