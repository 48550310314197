<template>
  <div class="home-wrap">
    <Header @show="showSideBar = true" />
    <div class="home-main-wrap">

      <Banner :list="topBannerData" />

      <Announcement />

     <Trade :is-home="true" />
<!--      <TradingHall/>-->
      <BuySellBtn />

      <Helper :data="bottomBannerData" />

      <div v-if="isShowFromThirdPayBtn" class="go-pay-page" @click="goBackPayUrl">{{ t('回到充值页') }}</div>
      <!-- 公告弹窗 -->
      <van-dialog v-model:show="showNotification" :show-confirm-button="false" class="notification-pop">
        <div class="top-box" @click="handleCloseNotification">
          <van-icon :name="themeImgs.homeCloseIcon" />
        </div>

        <van-swipe :autoplay="3000" ref="notificationSwipeRef" class="notification-swipe" :show-indicators="false">
          <van-swipe-item v-for="(item,index) in notificationData" :key="index"
                          :style="`backgroundImage:url(${locale === 'vi' ? themeImgs.homePopBgImgVn : themeImgs.homePopBgImg})`">
            <div class="title">{{ item.title }}</div>
            <div class="content" v-html="handleContent(item.content)"></div>
          </van-swipe-item>
        </van-swipe>

<!--        <div v-if="showPrevNextBtn" class="btn-box">-->
<!--          <van-icon :name="themeImgs.homePrevIcon" class="prev-btn" @click="handlePrev"/>-->
<!--          <van-icon :name="themeImgs.homeNextIcon" class="next-btn" @click="handleNext"/>-->
<!--        </div>-->

        <div v-if="showPrevNextBtn" class="next-btn" @click="handleNext">{{ $t('下一个') }}</div>

        <div class="no-show-24">
          <van-checkbox name='rememberMe' shape="square" v-model="close24Hours">
            {{ t('24小时内不再出现提示') }}
          </van-checkbox>
        </div>
      </van-dialog>

      <!--          您的订单有新状态-->
<!--      <div v-if="isHaveNewOrder" class="order-status-box" @click="openUrlOrderDetails">-->
<!--        <p>您的订单有新状态</p>-->
<!--        <div class="immediate-box">-->
<!--          <span>立即处理</span>-->
<!--          <van-icon :name="themeImgs.homeDoubleRightArrowIcon"/>-->
<!--        </div>-->
<!--      </div>-->
    </div>
    <Footer/>
    <SideBar v-model:showSideBar="showSideBar" />
  </div>
</template>

<script setup>
import {computed, onMounted, ref, watch} from "vue";
import {useRouter, useRoute} from "vue-router";
import SideBar from "@/components/SideBar.vue";
import {getBanner, getBulletin, getMemberInfo, getOrderRemind, logout} from '@/api/api'
import {useStore} from 'vuex'
import {Toast} from "vant";
import useClipboard from 'vue-clipboard3'
import wsConnection from "@/utils/websocket"
import {useGetAvatar, useUtils} from "@hooks";
import {handleContent} from '@/utils'
import { useI18n } from 'vue-i18n'
// import TradingHall from '../TradingHall.vue'
// import Deposit from '../DepositAndWithdraw/Deposit.vue'
// import Withdraw from '../DepositAndWithdraw/Withdraw.vue'
import Header from './components/Header.vue'
import Footer from '@/components/Footer.vue'
import Helper from './components/Helper.vue'
import Banner from './components/Banner.vue'
import Announcement from './components/Announcement.vue'
import BuySellBtn from './components/BuySellBtn.vue'
import Trade from './components/Trade.vue'

const { t, locale } = useI18n()
const $router = useRouter()
const $route = useRoute()
const store = useStore()
const { setWithExpiry, getWithExpiry } = useUtils()
const token = computed(() => store.state.loginInfos.token)

const {getAvatar} = useGetAvatar()
// 盘口标识
const siteName = ref(siteChannel)
// 获取会员个人信息
const handleGetMemberInfo = () => {
  getMemberInfo().then(res => {
    if (res.result === 200000) {
      if (res.data.status !== 1) {
        memberInfo.value = res.data
        store.dispatch('saveUserInfosActions', res.data)
        // 为第三方跳转来的创建与登录接口返回值对应的对象
        store.dispatch("saveLoginInfosActions", {
          ...store.state.loginInfos, ...{
            username: res.data.username,
            ws_url: res.data.ws_url
          }
        });
      } else {
        handleDoLogout()
      }
    }
  })
}
// 退出登录
const handleDoLogout = () => {
  logout().then(res => {
    if (res.result === 200000) {
      Toast.success(t('账号被冻结'))
      localStorage.removeItem("loginInfos")
      if (wsConnection.$ws) {
        wsConnection.$ws.close()
      }
      // $router.push('/login')
      store.commit('setLoginRegisterInfo', { flag: true })
    }
  })
}
// // 获取会员交易信息
// const handleGetMemberAmount = () => {
//   getMemberAmount().then(res => {
//     if (res.result == 200000) {
//       memberDealInfo.value = res.data.info
//       store.dispatch('saveMemberAmountActions', res.data.info)
//     }
//   })
// }
// 获取banner

// 存储banner数据
const topBannerData = ref([])
const bottomBannerData = ref([])
const handleGetBanner = () => {
  getBanner().then(res => {
    if (res.result === 200000) {
      //  顶部banner
      const topBanner = res.data.list.filter((item) => item.location === '0')
      //  快速了解
      const bottomBanner = res.data.list.filter((item) => item.location === '1')
      topBannerData.value = topBanner
      bottomBannerData.value = bottomBanner
    }
  })
}
// 是否显示弹窗底部按钮
const showPrevNextBtn = ref(false)
// 获取滚动公告,弹窗，type1为弹窗，2为滚动
const handleGetBulletin = () => {
  getBulletin({type: 1}).then(res => {
    if (res.result == 200000) {
      notificationData.value = res.data.list
      const value = getWithExpiry('announcementClose24h');

      if (res.data.list.length && value !== 1) {
        showNotification.value = true
      } else {
        showNotification.value = false
      }

      if (res.data.list.length > 1) {
        showPrevNextBtn.value = true
      } else {
        showPrevNextBtn.value = false
      }
    }
  })
}

onMounted(() => {
  console.log('home页')
  if (token.value) {
    handleGetMemberInfo()
    // handleGetMemberAmount()
    handleGetOrderRemind()
  }
  handleGetBanner()
  handleGetBulletin()

  if (!!sessionStorage.getItem('fromThirdUrlStr')) {
    isShowFromThirdPayBtn.value = true
  }
})

// 存储弹窗公告
const notificationData = ref([])
// 存储会员个人信息
const memberInfo = ref({})
// 存储会员单子信息
// const memberDealInfo = ref({})

// 复制钱包地址
const {toClipboard} = useClipboard()
const copyAddress = async (content) => {
  try {
    await toClipboard(content)
    Toast.success(t('复制成功'))
  } catch (e) {
    console.error(e)
  }
}
// 是否显示有无订单状态
const isHaveNewOrder = ref(false)
// 存储订单状态提醒数据
const orderRemind = ref([])
// 获取订单状态提醒
const handleGetOrderRemind = () => {
  const fullPath = window.location.href
  //    name === bitcoin,比特币消息，不需要调用接口
  if (orderRemindObj.value.name !== 'bitcoin' && !fullPath.includes('checkoutCounter')) {
    getOrderRemind().then(res => {
      if (res.result == 200000) {
        orderRemind.value = res.data.list[0]
        if (res.data.list.length > 0) {
          isHaveNewOrder.value = true
        } else {
          isHaveNewOrder.value = false
        }
      }
    })
  }
}
// 计算属性实现视图更新--新订单提醒
const orderRemindObj = computed(() => store.state.orderRemind)
watch(orderRemindObj, (newValue) => {
  orderRemind.value = newValue
  isHaveNewOrder.value = true
}, {
  deep: true // name是一个对象，需要进行深度监听
})

// 计算属性实现视图更新--后台审核已实名
const isName = computed(() => store.state.isName)
watch(isName, (newValue) => {
  handleGetMemberInfo()
})

// 为true时24小时不再显示公告
const close24Hours = ref(false)

// 是否展示公告弹窗
const showNotification = ref(false)
// 关闭公告弹窗
const handleCloseNotification = () => {
  showNotification.value = false

  // 24小时不再显示公告
  if (close24Hours.value) {
    setWithExpiry('announcementClose24h', 1, 24 * 60 * 60 * 1000)
  } else {
    localStorage.removeItem('announcementClose24h');
  }
}

// 实例化
const notificationSwipeRef = ref()
// 切换到上一轮播
const handlePrev = () => {
  notificationSwipeRef.value.prev();
}
// 切换到下一轮播
const handleNext = () => {
  notificationSwipeRef.value.next();
}
// mapstate--皮肤对应的图片
const themeImgs = computed(() => store.state.themeImgs)
// 是否显示返回到充值页的按钮-第三方
const isShowFromThirdPayBtn = ref(false)
// 返回到充值页-第三方
const goBackPayUrl = () => {
  $router.push(`/fromThirdPay?${sessionStorage.getItem('fromThirdUrlStr')}`)
  sessionStorage.removeItem('fromThirdUrlStr')
}

const showSideBar = ref(false)

// const changLang = () => {
//   if (locale.value === 'vi') {
//     localStorage.setItem('lang', 'zh')
//   }
//
//   if (locale.value === 'zh') {
//     localStorage.setItem('lang', 'vi')
//   }
//
//   location.reload()
// }
</script>

<style lang="less" scoped>
.home-wrap {
  height: 100%;
  //background: var(--mainBgColor);
  overflow: hidden;
  .home-main-wrap {
    overflow-y: auto;
    height: calc(100% - 50px);
    //height: 100%;
    padding-top: 15px;
    padding-bottom: 74px;
  }

  /*    :deep(.line-pop){
        background: #ffffff;
        .top{
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 57px;
          padding: 0px 14px;
          border-bottom: 8px solid #F8F9FA;
        }
        .manage{
          display: flex;
          justify-content: center;
          align-items: center;
          height: 56px;
          border-bottom: 1px solid #F8F9FA;
          cursor: pointer;
        }
        .fill-img{
          width: 20px;
          margin-left: 5px;
          margin-top: 3px;
        }
        .fill-img img {
          width: 100%;
        }
        .cursor{
          cursor: pointer;
        }
      }*/

  :deep(.notification-pop) {
    top: 50%;
    //width: 254px;
    width: 258px !important;
    background: none;
    overflow: visible;

    .top-box {
      position: absolute;
      top: 50px;
      right: -12px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 36px;
      height: 36px;
      z-index: 1000;

      img {
        width: 24px;
        height: 24px;
      }
    }

    .notification-swipe {
      //overflow: unset;

      .van-swipe-item {
        width: 100%;
        height: 445px;
        padding: 158px 15px 0;
        background-size: 100% 100% !important;
        //background-size: cover;

        .title {
          font-weight: 500;
          font-size: 16px;
          color: #535C66;
        }

        .content {
          height: 258px;
          margin-top: 10px;
          line-height: 22px;
          color: rgba(83, 92, 102, 0.9);
          word-break: break-all;
          word-wrap: break-word;
          overflow-y: scroll;
        }

        //.van-swipe__indicators {
        //  bottom: -100px;
        //}

        .notification-swipe {
          height: 100%;

          .title {
            font-weight: 500;
            font-size: 16px;
            color: #535C66;
          }

          .content {
            margin-top: 10px;
            line-height: 22px;
            color: rgba(83, 92, 102, 0.9);
            word-break: break-all;
            word-wrap: break-word;
          }

          //.van-swipe__indicators {
          //  bottom: -100px;
          //}

          //.custom-indicator {
          //  position: absolute;
          //  right: 5px;
          //  bottom: 5px;
          //  padding: 2px 5px;
          //  font-size: 12px;
          //  background: rgba(0, 0, 0, 0.1);
          //}
        }
      }

      //@media screen and (min-width: 750px) {
      //  .van-swipe-item {
      //    height: 200px;
      //
      //    .content {
      //      height: 100px;
      //    }
      //  }
      //}

      //.van-swipe__indicators {
      //  bottom: -20px;
      //}
    }

    .next-btn {
      width: 100%;
      height: 40px;
      margin-top: 18px;
      margin-bottom: 15px;
      background: #2EBD85;
      border-radius: 8px;
      font-family: PingFang SC;
      font-size: 16px;
      font-weight: 600;
      line-height: 40px;
      text-align: center;
      color: #FFF;
    }

    .van-checkbox {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 24px;
      margin-top: 14px;

      .van-checkbox__icon {
        width: 18px;
        height: 18px;

        .van-badge__wrapper {
          width: 100%;
          height: 100%;
          line-height: 20px;
          border-radius: 2px;
          border-color: rgba(255, 255, 255, 0.9);
        }
      }

      .van-checkbox__label {
        margin-left: 6px;
        font-family: PingFang SC;
        font-size: 13px;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.9);
      }
    }
  }

  .go-pay-page {
    position: fixed;
    right: 0;
    bottom: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    //width: 100px;
    height: 44px;
    padding: 0 10px;
    //background: var(--btnBgColor);
    background: #2EBD85;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    color: #fff;
    z-index: 1;
    text-align: center;
  }

  .order-status-box {
    position: fixed;
    right: 0;
    bottom: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 120px;
    height: 60px;
    padding-left: 10px;
    //background: var(--btnBgColor);
    background: #2EBD85;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    color: #fff;
    z-index: 1;

    p {
      font-size: 11px;
      font-weight: 500;
      text-align: center;
    }

    .immediate-box {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 8px;

      span {
        font-size: 10px;
        letter-spacing: 0.04em;
      }

      :deep(.van-icon) {
        .van-icon__image {
          width: 8px;
          height: 8px;
        }
      }
    }
  }

  //.qtpay-back-pay-btn {
  //  height: 44px;
  //  margin-bottom: 40px;
  //  line-height: 44px;
  //  text-align: center;
  //  background: #2ebd85;
  //  border-radius: 6px;
  //  color: #fff;
  //  font-weight: 700;
  //}
}
</style>
