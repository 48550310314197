<script setup>
import {onMounted, defineProps, watch, ref, computed, onBeforeUnmount, onActivated, onDeactivated} from 'vue'
import * as echarts from 'echarts'

const props = defineProps({
  data: {
    type: Object,
    default: {
      zaloOrder: 0,
      momoOrder: 0,
      bankOrder: 0,
    }
  },
  type: {
    type: Number,
    default: 0
  }
})

//  返回时间数据条数
const dataFilter = (timeType) => {
  switch (timeType) {
    case 1:
      //  分
      return 15
    case 2:
      //  分
      return 60
    case 3:
      //  半小时
      return 8
    case 4:
      //  1小时
      return 24
    case 5:
      //  半小时
      return 48
    case 6:
      // 1小时
      return 7
    default:
      return 60
  }
}

//  order的返回
const orderFilter = computed(() => {
  if (props.type === 1) {
    return props.data.momoOrder
  }
  if (props.type === 2) {
    return props.data.bankOrder
  }
  return props.data.zaloOrder
})

// 根据选择的时间，返回不同的order数量
const timeOrderFilter = () => {
  const minutesInDay = 24 * 60
  let count = 0
  switch (activeTab.value) {
    case 1:
      // 15分钟
      count = (orderFilter.value / minutesInDay) * 15
      break
    case 2:
      // 1小时（60分）
      count = (orderFilter.value / minutesInDay) * 60
      break
    case 3:
      // 4小时（240分）
      count = (orderFilter.value / minutesInDay) * 8
      break
    case 4:
      // 1天
      count = orderFilter.value
      break
    case 5:
      // 2天（96个半小时，以秒为单位）
      count = orderFilter.value * 2
      break
    case 6:
      // 1周（336个半小时，以秒为单位）
      count = orderFilter.value * 7
      break
    default:
      // 默认1分钟（60秒）
      count = orderFilter.value / minutesInDay
      break
  }
  return Math.floor(count)
}

// 生成不规则的数据
const generateIrregularData = (total, count) => {
  const average = total / count
  let remaining = total
  const data = []

  for (let i = 0; i < count; i++) {
    let value;
    if (i === count - 1) {
      // 最后一个数值，保证总和等于total
      value = remaining
    } else {
      // 在平均值的±10%范围内随机扰动
      const deviation = average * 0.2;
      value = average + (Math.random() * deviation * 2 - deviation)
      value = Math.max(0, Math.ceil(value))
      remaining -= value
    }
    data.push(value)
  }

  // 调整误差，确保总和等于total
  const currentSum = data.reduce((a, b) => a + b, 0)
  const adjustment = total - currentSum;
  data[data.length - 1] += adjustment

  // 确保所有值为正整数
  for (let i = 0; i < data.length; i++) {
    data[i] = Math.max(1, Math.ceil(data[i]))
  }

  // 重新调整，确保总和等于total
  let finalSum = data.reduce((a, b) => a + b, 0)
  let difference = finalSum - total
  let index = 0

  while (difference !== 0) {
    if (difference > 0) {
      if (data[index] > 1) {
        data[index] -= 1
        difference -= 1
      }
    } else {
      data[index] += 1
      difference += 1
    }
    index = (index + 1) % data.length
  }

  return data
}

// 根据数据最大值的长度动态设置 grid.right
const getGridRight = (length) => {
  return (length * 10) + 10 + 'px'
};

const formatVolume = (volume) => {
  return (volume / 1000).toFixed(2) + 'K';
}

const options = ref({})

let myChat = null
const initChat = () => {
  const chartDom = document.getElementById('main')
  if (!chartDom) {
    return false
  }

  if (!myChat) {
    myChat = echarts.init(chartDom)
  }
  //  横坐标
  const d = dataFilter(activeTab.value)
  let date = [];
  for (let i = 0; i < d; i++) {
    date.push(i + 1)
  }
  // console.log('图表渲染2', date)
  //  根据当前选择的时间，返回的成单量
  const order = timeOrderFilter()
  // console.log('order', order)
  //  k线图要渲染的数据
  const data = generateIrregularData(order, d)
  // console.log('data', data)
  // 计算数据最大值的长度
  const maxDataLength = Math.max(...data).toString().length
  // console.log('maxDataLength', maxDataLength)
  options.value = {
    tooltip: {
      trigger: 'axis',
      formatter: function (params) {
        console.log('params', params)
        return `${params[0].marker} ${params[0].data}`;
      },
    },
    grid: {
      left: '5%',
      right: getGridRight(maxDataLength),
      top: '5%',
      bottom: '10%'
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: date,
      axisLine: {
        show: true,
        lineStyle: {
          color: 'rgba(0, 0, 0, 0.1)'
        }
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        show: false
      }
    },
    yAxis: {
      type: 'value',
      boundaryGap: [0, '100%'],
      position: 'right',
      splitLine: {
        show: false
      },
      axisLabel: {
        formatter: formatVolume
      }
    },
    series: [
      {
        name: '成单量',
        type: 'line',
        symbol: 'none',
        sampling: 'lttb',
        itemStyle: {
          color: '#D3B400'
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: '#f3e7c6' },
            { offset: 1, color: '#ffffff' }
          ])
        },
        data: data
      }
    ],
  }
  options.value && myChat.setOption(options.value)
  console.log('已更新option', options.value)
}

const activeTab = ref(0)

//  时间切换
const handleTab = (currentItem) => {
  activeTab.value = currentItem.name

  initChat()
}

onMounted(() => {
  initChat()
  window.addEventListener('resize', handleResize)
})

onBeforeUnmount(() => {
  if (myChat) {
    myChat.dispose()
  }
  window.removeEventListener('resize', handleResize)
})

const handleResize = () => {
  if (myChat) {
    myChat.resize()
  }
}

//  返回不同的时间 '2 ngày', '2 tuần'
const chatTabs = computed(() => {
  return ['1 phút', '15 phút', '1 giờ', '4 giờ', '1 ngày', '2 ngày', '1 tuần']
})

watch(() => [props.type, props.data], () => {
  initChat()
})

</script>

<template>
  <div class="category-chat-box">
    <van-tabs
        v-model:active="activeTab"
        class="chart-type-box"
        background="none"
        :line-height="0"
        title-active-color="#000"
        title-inactive-color="rgba(0, 0, 0, 0.6)"
        @click-tab="handleTab">
      <van-tab v-for="item in chatTabs" :title="item">
      </van-tab>
    </van-tabs>
    <div class="category-chat-wrap">
      <div id="main" class="category-chat"></div>
    </div>
  </div>
</template>

<style scoped lang="less">
.category-chat-wrap {
  width: 100%;
  height: 160px;
  .category-chat {
    width: 100%;
    height: 100%;
  }
}
</style>