<template>
  <div class="uploadVoucher-wrap">
    <van-nav-bar
        :border="false"
        left-arrow
        :title="t('上传凭证')"
        @click-left="onClickLeft"
    >
      <template #right>
        <a :href="serviceUrl" target="_blank">
          <van-icon :name="themeImgs.commonServiceIcon"/>
        </a>
      </template>
    </van-nav-bar>

    <div class="uploadVoucher-main-wrap">
      <div class="upload-box">
        <van-uploader v-model="voucherFileList"
                      :after-read="afterUploadVoucher"
                      :before-read="beforeUpload"
                      :max-count="1"
                      :preview-options="{closeable:true}"
                      :upload-icon="require('@/assets/images/svg/uploadIcon.svg')"
        />
        <span>{{ t('上传支付凭证') }}</span>
      </div>

      <div class="start-btn-box">
        <van-button block @click="handleexchangeInfoByBuy">{{ t('我已转账') }}</van-button>
      </div>

      <div v-if="hintMsg.exchangeInfoByBuy" class="buy-message">
        {{ hintMsg.exchangeInfoByBuy }}
      </div>
    </div>
    <van-overlay :show="showOverlay" class-name="uploadVoucher-overlay">
      <van-loading color="#fff" size="24px" type="spinner" vertical>{{ t('上传中') }}...</van-loading>
    </van-overlay>
<!--    <van-dialog-->
<!--        v-model:show="exchangeInfoByBuy"-->
<!--        :before-close="handleUploadPaymentVoucher"-->
<!--        title="Nhắc nhở">-->
<!--      <div class="pay-desc">-->
<!--        {{ hintMsg.exchangeInfoByBuy }}-->
<!--      </div>-->
<!--    </van-dialog>-->
  </div>
</template>

<script>
import {computed, onMounted, ref} from 'vue'
import {comfirmBuyerTransfer, getHint, uploadImage, uploadPaymentVoucher} from '@/api/api'
import {useRoute, useRouter} from 'vue-router'
import {Toast} from 'vant'
import {useStore} from 'vuex'
import { useI18n } from 'vue-i18n'

export default {
  setup() {
    const { t } = useI18n()

    const $route = useRoute()
    const $router = useRouter()
    const store = useStore()
    // 点击导航栏左侧返回键/取消按钮
    const onClickLeft = () => {
      history.back()
    }
    const hintMsg = ref({})
    const handleGetHint = () => {
      getHint().then(res => {
        if (res.result == 200000) {
          hintMsg.value = res.data
        }
      })
    }
    onMounted(() => {
      handleGetHint()
    })
    // 定义在线客服地址
    const serviceUrl = ref(store.state.sysConfig.manage)
    // 是否显示蒙层
    const showOverlay = ref(false)
    // 存储上传买家付款凭证后得到的图片地址
    const voucherImgUrl = ref('')
    // 存储上传买家付款凭证后得到的文件流
    const voucherFileList = ref([])
    // 上传买家付款凭证前
    const beforeUpload = () => {
      showOverlay.value = true
      return true
    }
    // 上传买家付款凭证后
    const afterUploadVoucher = (file) => {
      // 此时可以自行将文件上传至服务器
      const formData = new FormData()
      formData.append('file', file.file)
      formData.append('file_name', 'certificate')
      uploadImage(formData).then(res => {
        if (res.result == 200000) {
          voucherImgUrl.value = res.data.path
          showOverlay.value = false
        } else {
          showOverlay.value = false
          Toast.fail(res.msg)
          voucherImgUrl.value = ''
          voucherFileList.value = []
        }
      })
    };
    // 确定上传凭证
    const exchangeInfoByBuy = ref(false)
    const handleexchangeInfoByBuy = () => {
      handleUploadPaymentVoucher('confirm')
      // if (hintMsg.value.exchangeInfoByBuy) {
      //   exchangeInfoByBuy.value = true
      // } else {
      //   handleUploadPaymentVoucher('confirm')
      // }
    }
    const handleUploadPaymentVoucher = (action) => {
      // 点击了确定按钮
      if (action === "confirm") {
        uploadPaymentVoucher({order_sn: $route.query.order_sn, pay_pic: voucherImgUrl.value}).then(res => {
          if (res.result == 200000) {
            handleComfirmBuyerTransfer()
            Toast.success(t('上传成功'))
            $router.push({
              path: 'buyerOrderDetails',
              query: {
                order_sn: $route.query.order_sn
              }
            })
            store.commit('setUntreatedNum', 0)
          } else {
            Toast.fail(res.msg)
          }
        })
        exchangeInfoByBuy.value = false
      }
      // 点击了取消按钮
      else {
        exchangeInfoByBuy.value = false
      }
    }
    // 买家确认转账
    const handleComfirmBuyerTransfer = () => {
      comfirmBuyerTransfer({order_sn: $route.query.order_sn})
    }
    // mapstate--皮肤对应的图片
    const themeImgs = computed(() => store.state.themeImgs)
    return {
      onClickLeft,
      voucherFileList,
      afterUploadVoucher,
      handleUploadPaymentVoucher,
      exchangeInfoByBuy,
      handleexchangeInfoByBuy,
      showOverlay,
      hintMsg,
      beforeUpload,
      serviceUrl,
      themeImgs,
      t
    }
  }
}
</script>

<style lang="less" scoped>
.uploadVoucher-wrap {
  :deep(.van-nav-bar) {
    .van-nav-bar__right {
      .van-icon {
        width: 24px;
        height: 24px;

        .van-icon__image {
          width: 24px;
          height: 24px;
        }
      }
    }
  }

  .uploadVoucher-main-wrap {
    height: calc(100vh - 44px);
    padding: 24px 16px 0;
    //background: var(--mainBgColor);

    .upload-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 143px;
      //background: var(--btnBgColor);
      background: #F4F4F4;
      border-radius: 8px;

      :deep(.van-uploader) {
        .van-uploader__upload {
          //width: 72px;
          //height: 72px;
          //background: rgba(255, 255, 255, 0.3);
          //border-radius: 50%;
          margin: 0;
          background: transparent;

          .van-uploader__upload-icon {
            width: 72px;
            height: 72px;
            color: #fff;

            .van-icon__image {
              width: 72px;
              height: 72px;
            }
          }
        }

        .van-uploader__preview-image {
          width: 72px;
          height: 72px;
        }
      }

      span {
        margin-top: 10px;
        font-family: PingFang SC;
        font-size: 18px;
        font-weight: 500;
        //color: var(--textColor1);
        color: #666666;
      }
    }

    .start-btn-box {
      margin-top: 32px;
      border-radius: 8px;

      .van-button {
        border-radius: 8px;
        background: #2EBD85;
      }
    }
  }

  .uploadVoucher-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  :deep(.van-dialog) {
    &.code-dialog {
      .van-dialog__header {
        //background: var(--orderDialogHeaderBgColor);
      }

      .van-dialog__confirm {
        //background: var(--orderDialogHeaderBgColor);
        //color: var(--orderDialogTextColor);
      }
    }

    .van-dialog__header {
      padding: 16px 0;
      font-weight: 500;
      font-size: 18px;
      //color: var(--orderDialogTextColor);
      color: #535C66;
    }

    .code-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 275px;
      background-size: 100% 100% !important;

      .van-image {
        width: 158px;
        height: 158px;
        border-radius: 8px;
      }

      .name {
        margin-top: 20px;
        font-size: 16px;
        color: rgba(83, 92, 102);
      }
    }

    .cancel-order-dialog-cell {
      background: none;

      textarea::-webkit-input-placeholder {
        color: rgba(255, 255, 255, 0.6);
      }

      textarea:-ms-input-placeholder {
        color: rgba(255, 255, 255, 0.6);
      }

      textarea:-moz-placeholder {
        color: rgba(255, 255, 255, 0.6);
        opacity: 1;
      }

      textarea::-moz-placeholder {
        color: rgba(255, 255, 255, 0.6);
        opacity: 1;
      }

      .van-field__label {
        color: #fff;
      }

      .van-field__control {
        color: #fff;
      }
    }
  }
}

.pay-desc {
  padding: 0 5% 5% 5%;
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: rgba(83, 92, 102, 0.9);
}

.buy-message {
  color: red;
  font-size: 14px;
  font-family: "PingFang SC";
  line-height: 24px;
  margin-top: 20px;
}
</style>
