<template>
  <van-dialog v-model:show="show"
              class="deposit-dialog"
              :show-confirm-button="false"
              :close-on-click-overlay="true"
              @closed="emit('update:showDeposit', false)"
  >
    <div class="title">{{ $t('充值') }}</div>
    <Usdt type="recharge" />
  </van-dialog>
</template>

<script setup>
import {defineProps, ref, watch, defineEmits} from 'vue'
import Usdt from './components/Usdt'

// defineOptions({
//   name: 'Deposit'
// })

const props = defineProps({
  showDeposit: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['update:showDeposit'])

const show = ref(props.showDeposit)

watch(() => props.showDeposit, val => {
  show.value = val
})
</script>

<style lang='less' scoped>
.title {
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
  line-height: 21.78px;
  color: #333;
  text-align: center;
  padding: 15px 0 6px;
}
</style>

<style lang='less'>
.deposit-dialog {
  width: 327px;
}
</style>
