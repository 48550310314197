<script setup>
import {ref} from 'vue'
import {useRouter} from 'vue-router'
import HelpItemQuestionDetail from './HelpItemQuestionDetail.vue'

const items = [
  { id: 1, label: 'Có mất phí giao dịch không?' },
  { id: 2, label: '88PAY Phương thức thanh toán nào được hỗ trợ?' },
  { id: 3, label: 'Cách khiếu nại nếu giao dịch có vấn đề？' },
  { id: 4, label: 'Quy trình mua/bán xu' },
  { id: 5, label: 'Quy trình gửi/rút tiền API' },
  { id: 6, label: 'Quy trình xác thực tên thật' },
]

const $router = useRouter()

const showDetail = ref(false)
const content = ref({})

//  常见问题跳转
const handleNavigator = (item) => {
  if ([4, 5].includes(item.id))  {
    $router.push('/helpCenter')
  } else {
    content.value = item
    showDetail.value = true
  }
}

</script>

<template>
  <div
      class="help-content-2"
      v-for="item in items"
      :key="item.id"
      @click="handleNavigator(item)">
    {{ item.id}}. {{ item.label }}
  </div>
  <HelpItemQuestionDetail
      v-model:is-show="showDetail"
      :current-item="content"
  />
</template>

<style scoped lang="less">
.help-content-2 {
  padding-bottom: 10px;
  font-family: PingFang SC;
  font-size: 13px;
  font-weight: 500;
  color: #000;
  &:last-child {
    padding: 0;
  }
}
</style>