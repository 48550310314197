<template>
  <div class="announcement-wrap">
    <van-nav-bar
        :border="false"
        class="announcement-nav-bar"
        fixed
        left-arrow
        :title="$t('站内信息')"
        @click-left="onClickLeft"
    />
    <div class="announcement-main-wrap">
      <div v-for="(item,index) in announcementData" :key="index" class="announcement-main-item-wrap">
        <div class="date">{{ item.created_at }}</div>
        <div class="content-box">
          <p class="title">{{ item.title }}</p>
          <!-- <span @click="showAnnouncementDialog=true">了解详情</span> -->
          <p v-html="item.content"></p>
        </div>
      </div>

      <van-empty
          v-if="announcementData.length < 1"
          :image="themeImgs.commonEmptyImg"
          :description="$t('暂无数据') + '～'"
      />
    </div>
    <!-- 查看公告内容 -->
    <van-dialog v-model:show="showAnnouncementDialog" class="announcement-content-dialog" :title="$t('公告内容')">
      123
    </van-dialog>
  </div>
</template>

<script setup>
import {computed, onMounted, ref} from 'vue'
import {getNewsList} from '@/api/api'
import { useStore } from 'vuex'

const store = useStore()
// 点击导航栏左侧返回键
const onClickLeft = () => {
  history.back()
}
// 获取数据
const handleGetNewsList = () => {
  getNewsList().then(res => {
    if (res.result == 200000) {
      announcementData.value = res.data.list || []
    }
  })
}
onMounted(() => {
  handleGetNewsList()
})
// 存储站内消息
const announcementData = ref([])
// 是否显示公告内容
const showAnnouncementDialog = ref(false)

// mapstate--皮肤对应的图片
const themeImgs = computed(() => store.state.themeImgs)
</script>

<style lang="less" scoped>
.announcement-wrap {
  .announcement-main-wrap {
    min-height: calc(100vh - 44px);
    margin-top: 75px;
    padding: 0 16px 60px;

    .announcement-main-item-wrap {
      margin-bottom: 20px;

      &:last-of-type {
        margin-bottom: 0;
      }

      .date {
        width: 171px;
        height: 22px;
        margin: 0 auto 10px;
        //background: var(--btnBgColor);
        background: #2EBD85;
        border-radius: 8px;
        line-height: 22px;
        text-align: center;
        color: #fff;
      }

      .content-box {
        width: 100%;
        min-height: 80px;
        padding: 14px 16px;
        //background: var(--boxBgColor);
        background: #E6FFF5;
        border-radius: 8px;
        font-family: PingFang SC;
        font-weight: 500;

        .title {
          margin-bottom: 10px;
          font-size: 16px;
          line-height: 22.4px;
          color: rgba(83, 92, 102, 1);
        }

        span {
          font-size: 14px;
          line-height: 19.6px;
          color: rgba(83, 92, 102, 0.8);
        }
      }
    }
  }
}
</style>
