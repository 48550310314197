<template>
  <div class="sellCoin-wrap">
    <van-nav-bar
        :border="false"
        left-arrow
        :title="route.query.title"
        @click-left="onClickLeft"
    />
    <div class="sellCoin-main-wrap">
      <van-image :src="detailPic" class="avatar"/>
    </div>
  </div>
</template>

<script setup>
import {useRoute} from 'vue-router'

// 点击导航栏左侧返回键
const route = useRoute()

const detailPic = route.query.pic

const onClickLeft = () => {
  history.back()
}
</script>

<style lang="less" scoped>
.sellCoin-wrap {
  .sellCoin-main-wrap {
    min-height: calc(100vh - 46px);
    padding: 24px 16px 16px;
    background: var(--mainBgColor);
  }
}
</style>
