<template>
  <div class="recharge-wrap">
<!--    <van-nav-bar-->
<!--        :border="false"-->
<!--        :title="isRecharge ? 'USDT充值' : 'USDT提现'"-->
<!--        left-arrow-->
<!--        @click-left="onClickLeft"-->
<!--    />-->
    <div class="recharge-box">
      <div class="remain-balance">
        {{ t('当前余额') }}<template v-if="locale !== 'vi'">({{ store.state.publicConfig.title }}{{ t('币') }})</template>：{{ store.state.memberAmountObj.currency_money }}
      </div>

      <van-form>
        <div class="form-item">
          <span class="form-label">{{ t('类型') }}</span>
          <!--   类型增加的使用注释里的代码：@click="showPicker = true; bitType = 'type'"  :right-icon="isRecharge ? themeImgs.mySolidDownIcon : ''"  -->
          <van-field
              v-model="formData.typeText"
              name="picker"
              :placeholder="t('请选择USDT类型')"
              readonly
          />
        </div>
        <!--    链路暂时只有一个，不做选择  @click="handleClick('link')"  :right-icon="themeImgs.mySolidDownIcon"    -->
        <div class="form-item">
          <span class="form-label">{{ t('链路') }}</span>
          <van-field
              v-model="formData.linkText"
              name="picker"
              :placeholder="t('请选择USDT链路')"
              readonly
          />
        </div>
        <div class="form-item">
<!--          <span class="form-label">-->
<!--            当前余额({{ store.state.publicConfig.title }})：-->
<!--            {{ store.state.memberAmountObj.currency_money }}-->
<!--          </span>-->
<!--          <span class="form-label form-label2">当前汇率：{{ currentRate }}</span>-->
          <span class="form-label">{{ t('数量') }}</span>
          <van-field
              v-model="formData.amount"
              :left-icon="themeImgs.commonBitIcon"
              :placeholder="isRecharge ? t('请输入要充值的USDT数量') : t('请输入要提现的USDT数量')"
              :readonly="isReadOnly"
              class="left-bit-icon"
              type="number"
              @input="inputChangeUnit"
          />
        </div>

        <div class="form-item">
          <span class="form-label">{{ t('报价') }}</span>
          <div class="form-right">
              {{ currentVirtual }}{{ formData.typeText }} ≈ {{ currentSysAmount }} {{ locale !== 'vi' ? store.state.publicConfig.title : '' }}
          </div>
        </div>

        <div class="form-item">
          <span class="form-label">
            {{ t('地址') }}
            <van-image
                v-show="isRecharge"
                :src="themeImgs.commonCopyIcon"
                class="copy-address-icon"
                @click="copyAddress(formData.address)"
            />
          </span>

          <van-field
              v-model="formData.address"
              :placeholder="isRecharge ?  t('请选择USDT地址') : t('请输入提现的USDT-TRC20地址')"
              :readonly="isRecharge"
              :right-icon="isRecharge ? themeImgs.mySolidDownIcon : ''"
              name="picker"
              @click="handleClick('wallet')"
              class="usdt-address"
          />
<!--          防止自动填充-->
          <input type="password" hidden autocomplete="new-password"/>
        </div>

        <div v-if="!isRecharge" class="form-item">
          <span class="form-label">{{ t('密码') }}</span>
          <van-field
              v-model="formData.pay_password"
              :name="t('支付密码')"
              :placeholder="t('请输入支付密码')"
              type="password"
          />
          <!--          防止自动填充-->
          <input type="password" hidden autocomplete="new-password"/>
        </div>

        <div class="warning-message">{{ t('注意') }}：
          {{ isRecharge ? t('转账时请核对收款地址是否一致，避免造成无法追回损失!') : t('填写地址时请再次核实是否正确，避免造成无法追回损失!') }}
        </div>

        <div v-if="isRecharge && formData.pay_pic" class="form-item">
          <span class="form-label form-label2">{{ t('支付凭证') }}</span>
          <div class="pay-certificate">
            <img :src="formData.imgUrl" @click="showFullImage"/>
            <div class="clear-icon" @click="formData.pay_pic = ''; formData.imgUrl = '';">
              <van-icon
                  :color="'var(--van-field-input-text-color)'"
                  name="close"
                  size="24px"
              />
            </div>
          </div>
        </div>

        <div class="submit-btn">
          <template v-if="isRecharge">
            <van-uploader
                v-if="!formData.pay_pic"
                :after-read="afterUpload"
                :before-read="beforeUpload"
                :max-count="1"
                :preview-image="false"
                class="upload-pay"
                @click-upload="clickUpload"
            >
              <van-button block>
                {{ t('上传支付凭证') }}
              </van-button>
            </van-uploader>
            <van-button v-else :disabled="locked" block @click="onSubmit">
              {{ t('提交充值') }}
            </van-button>
          </template>

          <template v-else>
            <van-button :disabled="locked" block @click="onSubmit">
              {{ t('提交提现') }}
            </van-button>
          </template>
        </div>
      </van-form>
    </div>

    <!--    比特币链路选择 start   -->
    <van-popup
        v-model:show="showPicker"
        position="bottom"
        round
        teleport="body"
    >
      <van-empty v-if="columns.length === 0" :description="t('暂无数据')"/>
      <van-picker
          v-else
          :columns="columns"
          :columns-field-names="{ text: 'address' }"
          :confirm-button-text="t('完成')"
          @cancel="showPicker = false; bitType = ''"
          @confirm="onConfirm"
      />
    </van-popup>

    <!--    比特币充值上传遮罩   -->
    <van-overlay :show="showOverlay" class-name="addPayment-overlay">
      <van-loading color="#fff" size="24px" type="spinner" vertical>{{ t('上传中') }}...</van-loading>
    </van-overlay>
  </div>
</template>

<script setup>
import {computed, onMounted, reactive, ref, watch, defineProps} from 'vue'
import {useStore} from 'vuex'
import {useRoute, useRouter} from "vue-router";
import {ImagePreview, Toast} from 'vant'
import useClipboard from 'vue-clipboard3'
import {getBitInfo, getBitRate, postBitRecharge, postWithdrawOrderAddV1, uploadImage} from '@/api/api'
import { useI18n } from 'vue-i18n'
const { t, locale } = useI18n()

const props = defineProps({
  type: {
    type: String,
    default: 'recharge'
  }
})
const store = useStore()

// mapstate--皮肤对应的图片
const themeImgs = computed(() => store.state.themeImgs)
//  当前主题色
const globalSiteTheme = computed(() => store.state.globalSiteTheme)
const route = useRoute()
const router = useRouter()

//  比特币充值/提现页面判断
const isRecharge = computed(() => {
  return props.type === 'recharge'
})

//    充值/提现表单数据对象
const formData = reactive({
  link: '3',
  linkText: 'trc-20',
  type: '1',
  typeText: 'USDT',
  amount: '',
  address: '',
  pay_pic: '',
  imgUrl: '',
  pay_password: ''
})


//    限制比特币输入金额小数位数
const inputChangeUnit = (e) => {
  formData.amount = e.target.value.match(/^\d*(\.?\d{0,2})/g)[0] || null
}
//    比特币充币地址
const handleClick = (type) => {
  bitType.value = type

  //  提现输入充币地址，返回false
  if (!isRecharge.value) {
    return false
  }
  showPicker.value = true
}

//    获取汇率配置
const currentRate = ref('0.00')
const status = ref(0)
const minAmount = ref('0.00')
const getBitRateInfo = () => {
  getBitRate().then(res => {
    if (res.result === 200000) {
      status.value = res.data.info.status
      minAmount.value = res.data.info.min_deposit_withdrawal
      if (isRecharge.value) {
        currentRate.value = res.data.info.status === 2 ? res.data.info.rate : res.data.info.topup_rate
      } else {
        currentRate.value = res.data.info.status === 2 ? res.data.info.rate : res.data.info.withdraw_rate
      }
    } else {
      currentRate.value = '0.00'
    }
  }).catch(_err => {
    currentRate.value = '0.00'
  })
}
watch(() => store.state.realTimeRate, (val) => {
  if (val) {
    currentRate.value = status.value === 2 ? store.state.realTimeRate : currentRate.value
  }
})

//    计算报价当前虚拟货币
const currentVirtual = computed(() => {
  if (!formData.amount) {
    return 1
  }
  return formData.amount
})

//    计算报价当前系统货币
const currentSysAmount = computed(() => {
  if (!formData.amount) {
    return 1 * (+currentRate.value)
  }
  // return (formData.amount * (+currentRate.value)).toFixed(2)
  return (formData.amount * (+currentRate.value)).toFixed(0)
})

//    获取比特币钱包地址
const walletList = ref([])
const getBitConfigInfo = () => {
  getBitInfo().then(res => {
    if (res.result === 200000) {
      walletList.value = res.data.list
      if (res.data.list.length > 0) {
        const find = res.data.list.find(item => formData.link === '' + item.link)
        formData.address = find ? find.default : ''
      } else {
        formData.address = ''
      }
    } else {
      walletList.value = []
      if (res.result === 1337) {
        Toast(res.msg)
      }
    }
  }).catch(() => {
    walletList.value = []
  })
}

const filterAddress = computed(() => {
  if (isRecharge.value) {
    const newData = walletList.value.find(item => formData.link === '' + item.link)
    if (newData) {
      const data = newData.address.map((item, index) => {
        return {
          id: index + 1,
          address: item
        }
      })
      return data
    }
    return []
  }
  return []
})

onMounted(() => {
  getBitRateInfo()
  if (isRecharge.value) {
    getBitConfigInfo()
  }
})

const bitType = ref('')

//    比特币链路，类型，充值地址选择数据
const columns = computed(() => {
  //  钱包地址使用的id和address，静态数据也使用这种渲染
  if (bitType.value === 'link') {
    return [
      {id: '1', address: 'etc-20'},
      {id: '2', address: 'erc-20'},
      {id: '3', address: 'trc-20'}
    ]
  }
  if (bitType.value === 'type') {
    return [{id: '1', address: 'USDT'}]
  }
  return filterAddress.value
})

//    链路选择器开关
const showPicker = ref(false)

// 复制钱包地址
const {toClipboard} = useClipboard()

//    充值选择的钱包地址复制
const copyAddress = async (content) => {
  if (content === '') {
    return false
  }
  if (isRecharge.value) {
    try {
      await toClipboard(content)
      Toast.success(t('USDT地址已复制'))
    } catch (e) {
      console.error(e)
    }
  }
}

//    比特币链路，类型，充值地址选择
const onConfirm = (currentValue) => {
  if (bitType.value === 'link') {
    formData.link = currentValue.id
    formData.linkText = currentValue.address
    // if (isRecharge.value) {
    //   const newData = walletList.value.find(item => currentValue.id === '' + item.link)
    //   formData.address = newData ? newData.default : ''
    // }
  }
  if (bitType.value === 'type') {
    formData.type = currentValue.id
    formData.typeText = currentValue.address
  }
  if (bitType.value === 'wallet') {
    formData.address = currentValue.address
  }
  showPicker.value = false
}

//    防止用户频繁点击
const locked = ref(false)

//    充值提交执行
const executeRecharge = () => {
  if (locked.value) {
    return false
  }
  locked.value = true
  const params = {
    link: formData.link,
    type: formData.type,
    address: formData.address,
    amount: formData.amount,
    is_rate: status.value,
    pay_pic: formData.pay_pic,
    wallet_type: store.state.publicConfig.title
  }
  postBitRecharge({...params}).then(res => {
    setTimeout(() => locked.value = false, 10000)
    if (res.result === 200000) {
      Toast.success(res.msg)
      // router.push('/order')
      router.push({
        path: '/order',
        query: {
          bitType: 1
        }
      })
    } else {
      Toast.fail(res.msg)
    }
  }).catch(() => {
    setTimeout(() => locked.value = false, 10000)
    Toast.fail(t('操作失败'))
  })
}

//    提现提交执行
const executeWithdraw = () => {
  if (+currentSysAmount.value > +store.state.memberAmountObj.currency_money) {
    Toast.fail(t('当前账户余额不足'))
    return false
  }

  if (locked.value) {
    return false
  }
  locked.value = true
  const params = {
    link: formData.link,
    type: formData.type,
    address: formData.address,
    amount: formData.amount,
    is_rate: status.value,
    wallet_type: store.state.publicConfig.title,
    pay_password: formData.pay_password
  }
  postWithdrawOrderAddV1({...params}).then(res => {
    setTimeout(() => locked.value = false, 10000)
    if (res.result === 200000) {
      Toast.success(res.msg)
      router.push({
        path: '/order',
        query: {
          bitType: 2
        }
      })
    } else {
      Toast.fail(res.msg)
    }
  }).catch(() => {
    setTimeout(() => locked.value = false, 10000)
    Toast.fail(t('操作失败'))
  })
}

//    表单保存前的验证
const validata = () => {
  //  输入的usdt数量
  if (formData.amount === '') {
    Toast.fail(t('请输入USDT数量'))
    return false
  }
  //  充值/提现数量必须大于最低配置的数量
  if ((+minAmount.value) > (+formData.amount)) {
    const msg = isRecharge.value ? t('充值的USDT数量小于最低配置限制') : t('提现的USDT数量小于最低配置限度')
    Toast.fail(msg)
    return false
  }
  //  usdt地址
  if (formData.address === '') {
    Toast.fail(isRecharge.value ? t('请选择USDT地址') : t('请输入提现的USDT-TRC20地址'))
    return false
  }

  //  地址验证
  if (!/^[a-zA-Z0-9]+$/.test(formData.address)) {
    Toast.fail(t('USDT地址只能包含英文和数字'))
    return false
  }
  return true
}

//    表单上传前验证
const clickUpload = (event) => {
  if (!validata()) {
    event.preventDefault()
  }
}

//    是否只读
const isReadOnly = ref(0)
//    上传凭证遮罩
const showOverlay = ref(false)
// 上传支付凭证前
const beforeUpload = () => {
  showOverlay.value = true
  formData.pay_pic = ''
  formData.imgUrl = ''
  return true
}
// 上传支付凭证后
const afterUpload = (file) => {
  // 此时可以自行将文件上传至服务器
  file.status = 'uploading'
  const newData = new FormData()
  newData.append('file', file.file)
  newData.append('file_name', 'centos')
  uploadImage(newData).then(res => {
    if (res.result == 200000) {
      isReadOnly.value += 1
      formData.pay_pic = res.data.path
      formData.imgUrl = res.data.url
      file.status = 'success'
      showOverlay.value = false
    } else {
      isReadOnly.value -= 1
      file.status = 'failed';
      file.message = t('上传失败');
      showOverlay.value = false
      formData.pay_pic = ''
      formData.imgUrl = ''
      Toast.fail(res.msg)
    }
  })
}

//    表单保存
const onSubmit = () => {
  if (validata()) {
    if (isRecharge.value) {
      if (formData.pay_pic) {
        executeRecharge()
      }
    } else {
      if (!formData.pay_password) {
        Toast.fail(t('支付密码不能为空'))
        return false
      }
      executeWithdraw()
    }
  }
}

//    查看支付凭证
const showFullImage = () => {
  if (formData.imgUrl === '') {
    return false
  }
  ImagePreview({
    images: [formData.imgUrl]
  })
}
</script>

<style lang="less" scoped>
.recharge-wrap {
  position: relative;
  padding-bottom: 23px;

  .recharge-box {
    padding: 0 17px;

    .remain-balance {
      margin-bottom: 14px;
      font-family: PingFang SC;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      text-align: center;
      color: #222;
    }

    .form-item {
      position: relative;
      display: flex;
      align-items: center;
      margin-bottom: 18px;

      .form-label {
        position: relative;
        display: inline-block;
        width: 42px;
        font-family: PingFang SC;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: #333;
        text-align: left;

        &.form-label2 {
          width: auto;
        }
      }

      ::v-deep {
        .van-badge__wrapper {
          display: flex;
          align-items: center;
        }
      }

      .form-right {
        width: 100%;
        height: 36px;
        padding-left: 10px;
        background: #DFFBF0;
        border-radius: 8px;
        line-height: 36px;
        color: rgba(102, 102, 102, 1);
      }

      img {
        width: 100%;
      }

      .view-img {
        color: var(--activeItemTextColor);
      }
    }

    .warning-message {
      margin-bottom: 16px;
      font-family: PingFang SC;
      font-size: 12px;
      font-weight: 300;
      line-height: 16px;
      color: #F50000;
    }
  }

  .submit-btn {
    overflow: hidden;

    .van-button {
      min-width: 258px;
      height: 40px;
      margin: auto;
      border-radius: 6px;
      background: #2EBD85;
      font-family: Inter;
      font-size: 14px;
      font-weight: 500;
      line-height: 40px;
      text-align: center;
      color: #FFF;
    }
  }

  ::v-deep {
    .form-item {
      .van-cell{
        display: flex;
        align-items: center;
        height: 36px;
        padding: 0 0 0 10px;
        background: #DFFBF0;
        border-radius: 8px;

        &.usdt-address {
          input {
            font-size: 11px;
          }
        }

        input {
          color: rgba(102, 102, 102, 1);

          &::placeholder {
            color: rgba(102, 102, 102, .6);
          }
        }
      }

      .left-bit-icon {
        .van-icon__image {
          width: 24px;
          height: 24px;
        }
      }

      .copy-address-icon {
        width: 18px;
        height: 18px;
      }

      .pay-certificate {
        position: relative;
        width: 100px;
        height: 100px;

        img {
          height: 100%;
        }

        .clear-icon {
          position: absolute;
          top: -10px;
          right: -10px;
        }
      }
    }

    .upload-pay {
      width: 100%;

      .van-uploader__wrapper {
        display: block;
      }
    }
  }
}
</style>
