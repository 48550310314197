<script setup>
import { onBeforeMount, computed, ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import Loading from './components/Loading.vue'
import Order from './components/Order.vue'
import { postQuickLogin } from '@/api/api'
import wsConnection from "@/utils/websocket";
import PayMessage from "@/viViews/CheckoutCounter/components/PayMessage.vue";

const store = useStore()

const token = computed(() => store.state.loginInfos.token)

// 封装获取url参数
const getUrlParams = (url) => {
  let urlStr = url.split('?')[1]
  const urlSearchParams = new URLSearchParams(urlStr)
  return Object.fromEntries(urlSearchParams.entries())
}

const payDataParams = ref({
  channel: '',
  three_username: '',
  actname: '',
  mcode: '',
  time: '',
  ip: '',
  order_sn: '',
  sign: '',
  amount: '',
  notifyUrl: ''
})

/**
 * params参数说明
 * @channel: string  渠道/通道
 * @three_username: string  三方账号
 * @actname: string  真实姓名
 * @time: string  请求时间
 * @ip: string  请求ip
 * @mcode: string  商户号
 * @amount: string  金额
 * @order_sn: string  订单号
 * @notifyUrl: string  回调地址
 * @sign: string  签名
 * http://localhost:8081/#/checkoutCounter?channel=HK&three_username=sdfd&actname=&time=1720794744&ip=127.0.0.1&mcode=100004&amount=100&order_sn=213432134&notifyUrl=123&sign=694397FD2CF0EDEDCC28D71B91FAA3B2
 */
const initFetch = () => {
  payDataParams.value.channel = getUrlParams(location.href)['channel']
  payDataParams.value.three_username = getUrlParams(location.href)['three_username']
  payDataParams.value.actname = getUrlParams(location.href)['actname']
  payDataParams.value.ip = getUrlParams(location.href)['ip']
  payDataParams.value.mcode = getUrlParams(location.href)['mcode']
  payDataParams.value.time = getUrlParams(location.href)['time']
  payDataParams.value.sign = getUrlParams(location.href)['sign']
  payDataParams.value.order_sn = getUrlParams(location.href)['order_sn']
  payDataParams.value.amount = getUrlParams(location.href)['amount']
  payDataParams.value.notifyUrl = getUrlParams(location.href)['notifyUrl']

  postQuickLogin(payDataParams.value).then(res => {
    if (res.result === 200000) {
      sessionStorage.setItem('checkoutCounterData', JSON.stringify(payDataParams.value))
      store.dispatch('saveLoginInfosActions', res.data)
      showPayMessage.value = true
      //  移除5这个值
      // store.commit('setQuickSellNotify', '5')
      // showMessageDialog.value = true
      // location.reload()
      wsConnection.initWebSocket()
    }
  })
}

//  清除一些信息
const clearSessionData = () => {
  sessionStorage.removeItem('voucherImgPath')
  sessionStorage.removeItem('checkoutCounterData')
  sessionStorage.removeItem('buyOrderCountDown')
  sessionStorage.removeItem('voucherFileList')
  sessionStorage.removeItem('showCheckoutCounterMessage')
  sessionStorage.removeItem('quickSellPayInfo')
  store.commit('setQuickSellNotify', '0')
  localStorage.removeItem("loginInfos")
  store.dispatch("saveLoginInfosActions", {})
  if(wsConnection.$ws) {
    wsConnection.$ws.close()
  }
  // 针对综合的退出
  if(window.parent.frames['agIframe']) {
    window.parent.postMessage('退出登录', store.state.loginInfos.originUrl)
  }
}

// watch(() => token.value, () => {
//   if (!token.value) {
//     initFetch()
//   }
// }, { immediate: true })
onMounted(() => {
  if (!token.value) {
    initFetch()
  }
})

//  关闭页面调用退出登录
// const handleDoLogout = () => {
//   logout().then(() => {
//     clearSessionData()
//   })
// }

//  返回订单信息
const getOrder = () => {
  const order = sessionStorage.getItem('checkoutCounterData')
  if (order) {
    return JSON.parse(order)
  }
  return {}
}
onBeforeMount(() => {
  const currentOrderSn = getUrlParams(location.href)['order_sn']
  if (getOrder().order_sn !== currentOrderSn) {
    // handleDoLogout()
    clearSessionData()
  }
})

const component = computed(() => {
  if (token.value) {
    return Order
  }
  return Loading
})

const showPayMessage = ref(false)
const onClose = () => {
  showPayMessage.value = false
  sessionStorage.setItem('showCheckoutCounterMessage', '0')
}
</script>

<template>
  <div class="checkout-counter-box">
    <component :is="component" :showPayMessage="showPayMessage" />
    <PayMessage :showPayMessage="showPayMessage" @onClose="onClose" />
  </div>
</template>

<style scoped lang="less">
.checkout-counter-box {
  height: 100vh;
  //overflow: hidden;
}
</style>
