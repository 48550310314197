<script setup>
import {computed, onActivated, onMounted, ref} from 'vue'
import {useStore} from 'vuex'
import {useRoute, useRouter} from 'vue-router'
import {Toast} from 'vant'
import QRCode from 'qrcode'
import {getQuickOrderDetails} from '@/api/api'
import PayDialog from '@/viViews/QuickTrade/components/PayDialog'

const store = useStore()

const $router = useRouter()

const $route = useRoute()

// mapstate--皮肤对应的图片
const themeImgs = computed(() => store.state.themeImgs)

const onClickLeft = () => {
  if (history.state.back && history.state.back.indexOf($route.path) > -1 || !history.state.back) {
    $router.push('/order')
  } else {
    history.back()
  }
}

// 越南钱包-快捷订单列表订单状态过滤器
const quickOrderStatusFilter = (status) => {
  switch (status) {
    case 1:
      return 'Đang thanh toán'
    case 2:
      return 'Thanh toán thành công'
    default:
      return 'Thất bại'
  }
}

const orderDetails = ref({})
//  详情调用
const initFetch = () => {
  getQuickOrderDetails({order_sn: $route.query.order_sn}).then(res => {
    if (res.result === 200000) {
      orderDetails.value = res.data
      // if (res.data.pay_channel === '11') {
      //  renderQrcode(res.data.qrcodeField)
      // } else {
      //   qrcodeUrl.value = res.data.qrcodeField
      // }
    } else {
      Toast.fail(res.msg)
    }
  })
}

onActivated(() => {
  initFetch()
})


const qrcodeUrl = ref('')
//  二维码渲染
const renderQrcode = (text) => {
  if (text) {
    QRCode.toDataURL(text, { errorCorrectionLevel: 'L' }).then(url => {
      qrcodeUrl.value = url
    }).catch(err => {
      console.error(err)
      Toast.fail('Failed to generate QR code')
    })
  }
}

//  支付方式
const payChannelFitter = (type) => {
  let str = ''
  switch (type) {
    case '13':
      str = 'zalo'
      break
    case '12':
      str = 'Momo'
      break
    case '11':
      str = 'Bank'
      break
  }
  return str
}

//  打开支付页面
const isPay = ref(false)
const handlePay = () => {
  if (orderDetails.value.payUrl) {
    isPay.value = true
  }
}
</script>

<template>
  <div class="quick-order-details-box">
    <van-nav-bar
        :border="false"
        left-arrow
        title="Chi tiết mua nhanh"
        @click-left="onClickLeft"
    />
    <div class="direction"></div>

    <div class="quick-order-info">
      <div class="info-horizontal">
        <span>Số đơn hàng</span>
        <p>{{ orderDetails.order_sn }}</p>
      </div>

<!--      <div class="info-horizontal">-->
<!--        <span>Ghi chú đơn hàng</span>-->
<!--        <p class="info-remark">{{ orderDetails.remark }}</p>-->
<!--      </div>-->
      <div class="info-horizontal">
        <span>Trạng thái đơn hàng</span>
        <p :class="`order_status_${orderDetails.status}`">
          {{ quickOrderStatusFilter(orderDetails.status) }}
        </p>
      </div>
      <div class="info-horizontal">
        <span>Tài khoản thanh toán</span>
        <p>{{ orderDetails.user_name }}</p>
      </div>
<!--      <div class="info-horizontal">-->
<!--        <span>Tên người nhận tiền</span>-->
<!--        <p>{{ orderDetails.realName }}</p>-->
<!--      </div>-->
      <div class="info-horizontal">
        <span>Số tiền mua</span>
        <p>{{ orderDetails.actual_amount }}</p>
      </div>
      <div class="info-horizontal">
        <span>Phương thức nhận tiền</span>
        <p>{{ payChannelFitter(orderDetails.pay_channel) }}</p>
      </div>
<!--      <div v-if="orderDetails.pay_channel === '13'" class="info-horizontal">-->
<!--        <span>Tên ngân hàng</span>-->
<!--        <p>{{ orderDetails.bank }}</p>-->
<!--      </div>-->
      <div v-if="orderDetails.status === 1" class="pay-btn" @click="handlePay">
        thanh toán
      </div>
<!--      <div class="info-horizontal">-->
<!--        <span>Mã nhận tiền</span>-->
<!--        <div class="info-qrcode" :style="{backgroundImage: `url('${themeImgs.orderDetailQrCodeBgImg}')`}">-->
<!--          <van-image-->
<!--              class="qrcode-img"-->
<!--              fit="fill"-->
<!--              :src="qrcodeUrl"-->
<!--          />-->
<!--        </div>-->
<!--      </div>-->
    </div>
  </div>

  <PayDialog
      v-model:show="isPay"
      :pay-info="orderDetails.payUrl"
  />
</template>

<style scoped lang="less">
.quick-order-details-box {
  .direction {
    width: 100%;
    height: 8px;
    background: #F3F3F3;
  }

  .quick-order-info {
    background: rgba(244, 244, 244, 0.6);
    padding: 10px;
    margin: 15px;
    border-radius: 8px;

    .info-horizontal {
      display: flex;
      justify-content: space-between;
      font-family: PingFang SC;
      font-size: 16px;
      line-height: 22px;
      color: #666666;
      margin-bottom: 10px;
      span {
        font-weight: 500;
      }
      p {
        margin-bottom: 0;
        font-weight: 400;

        &.order_status_1 {
          color: orange;
        }
        &.order_status_2 {
          color: green;
        }
        &.order_status_3 {
          color: red;
        }
      }
      .info-remark {
        color: #F50000;
      }
      .info-qrcode {
        width: 128px;
        height: 128px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding: 15px;
        .qrcode-img {
          width: calc(128px - 30px);
          height: calc(128px - 30px);
        }
      }
    }

    .pay-btn {
      background: #2ebd85;
      border-radius: 8px;
      width: 100px;
      height: 30px;
      text-align: center;
      line-height: 30px;
      margin: auto;
      color: #fff;
      font-family: PingFang SC;
      font-size: 16px;
    }
  }
}
</style>