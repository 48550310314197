<script setup>
import {computed, defineProps} from 'vue'
import {useStore} from 'vuex'
import {useRouter} from 'vue-router'

const props = defineProps({
  data: {
    type: Object,
    default: {
      zaloOrder: 0,
      zaloRate: '0%',
      momoOrder: 0,
      momoRate: '0%',
      bankOrder: 0,
      bankRate: '0%',
    }
  }
})

const store = useStore()

// mapstate--皮肤对应的图片
const themeImgs = computed(() => store.state.themeImgs)

//  format
const formatVolume = (volume) => {
  return (volume / 1000).toFixed(2) + 'K';
}

//  每种支付方式的成交量
const payTypeItems = computed(() => {
  return [
    {
      id: 3,
      count: formatVolume(props.data.bankOrder),
      icon: themeImgs.value.commonPaymentIconVn3,
      name: 'Bank',
      percentage: props.data.bankRate,
      key: 2
    },
    {
      id: 2,
      count: formatVolume(props.data.momoOrder),
      icon: themeImgs.value.commonPaymentIconVn2,
      name: 'Momo',
      percentage: props.data.momoRate,
      key: 1
    },
    {
      id: 1,
      count: formatVolume(props.data.zaloOrder),
      icon: themeImgs.value.commonPaymentIconVn1,
      name: 'Zalo',
      percentage: props.data.zaloRate,
      key: 0
    }
  ]
})

const $router = useRouter()

const handleBuy = (index) => {
  $router.push({
    path: '/realTimeTrade',
    query: {
      key: index
    }
  })
}
</script>

<template>
  <div class="pay-count-box">
    <div class="pay-count-item" v-for="(item, index) in payTypeItems" :key="item.id">
     <div class="item-type">
       <van-icon :name="item.icon" />
       <span>{{ item.name }}</span>
     </div>
      <div class="item-count">
        <van-icon :name="themeImgs.commonHot" />
        <span>{{ item.count }}</span>
      </div>
      <div class="buy-btn-box" @click="handleBuy(index)">
        {{ item.percentage }}
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
.pay-count-box {
  margin: 12px 7px 12px 7px;
  .pay-count-item {
    background: rgba(218, 218, 218, 0.21);
    border-radius: 8px;
    margin-bottom: 5px;
    padding: 10px 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:last-child {
      margin: 0;
    }

    .item-type {
      display: flex;
      width: 130px;
      span {
        margin-left: 10px;
      }
    }
    .item-count {
      display: flex;
      align-items: center;
      width: 200px;
      span {
        margin-left: 10px;
        font-family: DINPro;
        font-size: 16px;
        font-weight: 700;
        line-height: 16px;
      }
    }

    .buy-btn-box {
      width: 74px;
      height: 30px;
      margin-left: 20px;
      border-radius: 6px;
      font-family: Inter;
      font-size: 14px;
      font-weight: 500;
      color: #FFF;
      line-height: 30px;
      text-align: center;
      background: rgba(46, 189, 133, 1);
    }
  }
}
</style>