<script setup>
import {computed, ref, defineEmits, defineProps, watch} from 'vue'
import {useStore} from 'vuex'

const props = defineProps({
  data: {
    type: Array,
    default: []
  }
})

const emit = defineEmits(['onTab'])

const store = useStore()

// mapstate--皮肤对应的图片
const themeImgs = computed(() => store.state.themeImgs)

const activeTab = ref(0)
const handleTab = (currentItem) => {
  activeTab.value = currentItem.name
  emit('onTab', props.data[currentItem.name])
}

</script>

<template>
  <div class="buy-fast-box">
    <p>Phương thức thanh toán</p>
    <van-tabs
        v-model:active="activeTab"
        class="pay-type-tabs"
        background="#DFFBF0"
        :line-height="0"
        title-active-color="#2EBD85"
        title-inactive-color="#000000"
        @click-tab="handleTab">
      <van-tab v-for="(item, index) in props.data">
        <template #title>
          <div
              class="pay-type-tab"
              :class="{'pay-type-tab-active': activeTab === index}">
            <van-icon :name="item.icon" :size="24" />
            <span>{{ item.name }}</span>
          </div>
        </template>
      </van-tab>
    </van-tabs>
  </div>
</template>

<style scoped lang="less">
.buy-fast-box {
  margin-bottom: 16px;
  p {
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: rgba(0, 0, 0, 0.8);
    margin: 10px 0;
  }
}
</style>

<style lang="less">
.buy-fast-box {
  .pay-type-tabs{

    & > .van-tabs__wrap {
      height: auto;
      border-radius: 8px;
      .van-tab__text--ellipsis {
        overflow: auto;
        -webkit-line-clamp: initial;
      }
      .van-tabs__nav {
        padding: 5px 0;
      }
    }
    .pay-type-tab {
      display: flex;
      align-items: center;
      border-radius: 30px;
      padding: 3px 8px;
      height: 40px;
      &.pay-type-tab-active {
        background: #fff;
        border: 1px solid #2EBD85;
      }
      span {
        margin-left: 5px;
      }
    }
  }
}
</style>
