<script setup>
import {onMounted, ref, computed} from 'vue'
import {useStore} from 'vuex'
import {useI18n} from 'vue-i18n'
import {getBulletin} from '@/api/api'

const { t, locale } = useI18n()

// 存储滚动公告
const announcementData = ref('')

const initFetch = () => {
  getBulletin({type: 2}).then(res => {
    if (res.result === 200000) {
      if (res.data.list.length) {
        res.data.list.forEach((item, index) => {
          announcementData.value += `${item.content}  `
        })
      }
    }
  })
}

onMounted(() => {
  initFetch()
})

const store = useStore()

// mapstate--皮肤对应的图片
const themeImgs = computed(() => store.state.themeImgs)

</script>

<template>
  <van-notice-bar
      :left-icon="themeImgs.homeNoticeIcon"
      :text="announcementData || t('暂无公告')"
      background="none"
      class="home-notice-bar"
      scrollable
      speed="50"
  />
</template>

<style scoped lang="less">

.home-notice-bar {
  height: 16px;
  margin-bottom: 16px;

  ::v-deep {
    .van-icon {
      width: 16px;
      height: 16px;
      margin-right: 4px;

      .van-icon__image {
        width: 100% !important;
        height: 100% !important;
      }
    }

    .van-notice-bar__content {
      font-family: PingFang SC;
      font-size: 13px;
      font-weight: 400;
      line-height: 14px;
      color: rgba(51, 51, 51, 1);
    }
  }
}
</style>