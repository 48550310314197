<template>
  <div class="fromThird-wrap">
    <van-nav-bar
        :border="false"
        :title="t('初次使用设置')"
    />
    <div class="main-wrap">
      <div class="label">{{ t('设置账号') }}</div>

      <van-field v-model="payDataParams.three_username"
                 :border="false"
                 autocomplete="off"
                 disabled
                 :placeholder="t('首字母开头-6-20位')"
                 readonly
      />
      <div class="label">{{ t('真实姓名') }}</div>

      <van-field v-model="payDataParams.actname"
                 :border="false"
                 autocomplete="off"
                 disabled
                 :placeholder="t('输入真实姓名')"
                 readonly
      />

      <template v-if="siteName !== '88pay'">
        <div class="label">{{ t('设置登录密码') }}</div>

        <van-field v-model="payDataParams.password"
                   :border="false"
                   autocomplete="off"
                   clearable
                   :placeholder="t('设置登录密码')"
                   type="password"
        />
        <!--        防止自动填充-->
        <input type="password" hidden autocomplete="new-password" />
      </template>

      <div class="label">{{ t('设置支付密码') }}</div>

      <van-field v-model="payDataParams.pay_password"
                 :border="false"
                 autocomplete="off"
                 clearable maxlength="6"
                 :placeholder="t('6位数字支付密码')"
                 type="password"
      />
      <!--        防止自动填充-->
      <input type="password" hidden autocomplete="new-password" />

      <div class="btn" @click="handleReg">{{ t('确认') }}</div>
    </div>
  </div>
</template>

<script setup>
import {onBeforeMount, reactive, ref} from 'vue'
import {fromThirdReg} from "@/api/api"
import {Toast} from 'vant'
import {useRouter} from "vue-router"
import {useStore} from "vuex"
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const $router = useRouter();
const store = useStore();
const siteName = ref(siteChannel)

// 当前支付数据接口请求参数
const payDataParams = reactive({
  channel: '',
  three_username: '',
  actname: '',
  mcode: '',
  time: '',
  ip: '',
  order_sn: '',
  member_amount: '',
  order_time: '',
  password: '',
  pay_password: '',
  sign: '',
  amount: '',
  notifyUrl: ''
})
// 封装获取url参数
const getUrlParams = (url) => {
  let urlStr = url.split('?')[1]
  const urlSearchParams = new URLSearchParams(urlStr)
  const result = Object.fromEntries(urlSearchParams.entries())
  return result
}
// 获取支付数据
const getPayData = () => {
  payDataParams.channel = getUrlParams(location.href)['channel']
  payDataParams.three_username = getUrlParams(location.href)['three_username']
  payDataParams.actname = getUrlParams(location.href)['actname']
  payDataParams.ip = getUrlParams(location.href)['ip']
  payDataParams.mcode = getUrlParams(location.href)['mcode']
  payDataParams.time = getUrlParams(location.href)['time']
  payDataParams.sign = getUrlParams(location.href)['sign']
  payDataParams.order_sn = getUrlParams(location.href)['order_sn']
  payDataParams.member_amount = getUrlParams(location.href)['member_amount']
  payDataParams.amount = getUrlParams(location.href)['amount']
  payDataParams.notifyUrl = getUrlParams(location.href)['notifyUrl']
  payDataParams.order_time = getUrlParams(location.href)['order_time']
}
onBeforeMount(() => {
  getPayData()
})

const generateRandomString = () => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const minLength = 6;
  const maxLength = 10;
  const length = Math.floor(Math.random() * (maxLength - minLength + 1)) + minLength;
  let result = '';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters[randomIndex];
  }

  return result;
}

const handleReg = () => {
  if (siteName.value === '88pay') {
    payDataParams.password = generateRandomString()
  }

  if (!payDataParams.password) {
    return Toast.fail(t('请设置登录密码'))
  }
  if (!payDataParams.pay_password) {
    return Toast.fail(t('请设置支付密码'))
  }
  fromThirdReg(payDataParams).then(res => {
    if (res.result == 200000) {
      Toast.success(t('设置成功'));
      store.dispatch("saveLoginInfosActions", res.data)
      $router.push("/home")
    } else {
      Toast.fail(res.msg)
    }
  })
}
</script>

<style lang="less" scoped>
.fromThird-wrap {
  height: 100vh;
  background: #fff;

  .main-wrap {
    margin-top: 23px;
    padding: 0 16px 16px;

    .label {
      margin-bottom: 10px;
      font-family: PingFang SC;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: #333;
    }

    :deep(.van-cell) {
      height: 40px;
      margin-bottom: 15px;
      background: #E3E3E3;
      border-radius: 8px;

      input {
        font-family: PingFang SC;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: #333;

        &::placeholder {
          color: #A4A4A4;
        }
      }
    }

    .btn {
      height: 44px;
      margin-top: 76px;
      line-height: 44px;
      background: #2EBD85;
      border-radius: 8px;
      text-align: center;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #333;
    }
  }
}
</style>
