<template>
  <div class="maintenance-wrap">
<!--    <h3>{{ $t('系统维护') }}</h3>-->
<!--    <p>{{ $t('维护说明') }}：{{ maintenanceObj.msg }}</p>-->
<!--    <p>{{ $t('维护开始时间') }}：{{ maintenanceObj.start }}</p>-->
<!--    <p>{{ $t('维护结束时间') }}：{{ maintenanceObj.end }}</p>-->
<!--    <div class="cs" @click="setShowServicePopup(true)">{{ $t('联系客服') }}</div>-->


    <van-image :src="themeImgs.maintenanceBg" />
    <div class="time">{{ $t('维护时间') }}：{{ maintenanceObj.start }}{{ $t('至') }}{{ maintenanceObj.end }}</div>
    <div class="content" v-html="handleContent"></div>
    <div class="service-forget" @click="setShowServicePopup(true)">
      <van-image :src="themeImgs.loginRegCsIcon" />{{ $t('联系客服') }}
    </div>
  </div>
</template>

<script>
import {computed, onMounted, ref} from "vue";
import {getMaintenance} from '@/api/api'
import {useRouter} from "vue-router";
import {useStore} from "vuex";

export default {
  setup() {
    const $router = useRouter();
    const store = useStore();
    // 存储维护信息
    const maintenanceObj = ref({})
    // 获取维护信息
    const handleGetMaintenance = () => {
      getMaintenance().then(res => {
        if (res.result == 200000) {
          $router.push("/home");
        }
        if (res.result == 30000) {
          maintenanceObj.value = res.data.info
        }
      })
    }

    onMounted(() => {
      handleGetMaintenance()
    })

    // mapstate--皮肤对应的图片
    const themeImgs = computed(() => store.state.themeImgs)

    const setShowServicePopup = (flag) => {
      store.commit('setShowServicePopup', flag)
    }

    const handleContent = computed(() => {
      return maintenanceObj.value.msg && maintenanceObj.value.msg.replace(/\n/g, '<br/>')
    })

    return {
      maintenanceObj,
      themeImgs,
      setShowServicePopup,
      handleContent
    }
  }
}
</script>

<style lang="less" scoped>
.maintenance-wrap {
  position: relative;
  min-height: 100vh;
  padding: 81px 0 60px;

  > .van-image {
    display: block;
    width: 278px;
    height: 278px;
    margin: 0 auto 33px;
  }

  .time {
    padding: 0 46px;
    margin-bottom: 16px;
    font-family: PingFang SC;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
    color: rgba(0, 0, 0, 0.8);
    word-break: break-word;
  }

  .content {
    padding: 0 46px;
    font-family: PingFang SC;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: rgba(0, 0, 0, 0.7);
    //word-break: break-word;
  }

  .service-forget {
    position: absolute;
    left: 50%;
    bottom: 18px;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: 400;
    color: #2EBD85;
    white-space: nowrap;

    .van-image {
      flex-shrink: 0;
      width: 24px;
      height: 24px;
      margin-right: 6px;
    }
  }
}
</style>
