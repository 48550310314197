<script setup>
import {ref, computed, onActivated, onMounted, watch} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import Footer from '@/components/Footer.vue'
import Buy from './components/Buy.vue'
import Sell from './components/Sell.vue'

//  顶部外层tabs
const tabs = [
  { id: 1, label: 'Mua' },
  { id: 2, label: 'Bán' },
]

const router = useRouter()
const $route = useRoute()

//  外层当前选中tab,这是tab的索引值
const activeTab = ref(0)

watch(() => $route.query.id, () => {
  activeTab.value = $route.query.id ? parseInt($route.query.id) : 0
})

onMounted(() => {
  activeTab.value = $route.query.id ? parseInt($route.query.id) : 0
})

//  tab的切换
const handleTab = (currentItem) => {
  router.push({
    path: 'quickTrade',
    query: {
      id: currentItem.name
    }
  })
}

const component = computed(() => {
  if ($route.query.id === '1') {
    return Sell
  }
  return Buy
})
</script>

<template>
  <div class="quick-trade">
    <van-tabs
        v-model:active="activeTab"
        color="#2EBD85"
        title-active-color="#000"
        title-inactive-color="#000"
        :shrink="true"
        class="quick-trade-tabs"
        @click-tab="handleTab">
      <van-tab
          v-for="item in tabs"
          :title="item.label"
          title-class="quick-trade-tab">
        <template #default>
          <component :is="component" />
        </template>
      </van-tab>
    </van-tabs>
  </div>
  <Footer />
</template>
<style lang="less">
.quick-trade {

  .quick-trade-tabs {
    .van-tabs__nav {
      justify-content: center;
    }
    .quick-trade-tab {
      width: 100px;
      //flex: initial;
      &.van-tab--active {
        span {
          font-weight: 600;
        }
      }

      span {
        font-family: PingFang SC;
        font-size: 18px;
        font-weight: 400;
        line-height: 20px;
      }
    }
  }
}

</style>