<template>
  <div class="record-video">
    <van-nav-bar
        title=""
        left-arrow
        :border="false"
        @click-left="onClickLeft"
    />

    <div class="title">Quét mặt xác thực</div>
    <div class="sub-title">Đảm bảo bạn thao tác</div>

    <div class="videoWrapper">
      <video v-show="isRecording === 1" class="video" ref="preview" id="preview" width="100%" height="auto" playsinline webkit-playsinline autoplay muted></video>
      <video style="display: none;" class="video" ref="recording" id="recording" width="100%" height="auto" controls muted></video>
      <canvas ref="canvas" style="display:none;"></canvas>
      <img v-show="isRecording === 2" ref="thumbnail" alt="">
    </div>

    <div class="tips">{{ handleText }}</div>

    <van-button :disabled="isRecording !== 0" class="btn" @click="start">{{ btnText }}</van-button>
  </div>
</template>
<script setup>
import { computed, ref, watch } from "vue";
import { useRouter } from 'vue-router'
import { Toast } from 'vant'
import { uploadImage, doCertification} from '@/api/api'
import {detectOS} from "@/utils";

const router = useRouter()

const preview = ref(null)
const recording = ref(null)
const isRecording = ref(0) // 0未录制， 1录制中， 2录制结束

let dataChunks = [];
let recorder;

const timeCount = ref(0)

let timer = null

const handleText = computed(() => {
  let text = 'Chuẩn bị ghi hình khuôn mặt'

  if (timeCount.value > 0 && timeCount.value <= 3) {
    text = 'Nhìn sang phải'
  }

  if (timeCount.value > 3 && timeCount.value <= 6) {
    text = 'Nhìn sang trái'
  }

  if (timeCount.value > 6 && timeCount.value <= 9) {
    text = 'Chớp mắt'
  }

  if (timeCount.value > 9 && timeCount.value <= 12) {
    text = 'Ngẩng đầu'
  }

  if (timeCount.value > 12) {
    text = 'Hoàn thành ghi hình'
  }

    return text
})

const btnText = computed(() => {
  let text = 'Bắt đầu ghi'

  if (isRecording.value === 1) {
    text = 'Đang ghi'
  }

  if (isRecording.value === 2) {
    text = 'Hoàn thành ghi'
  }

  return text
})

let videoPath = ''

const submit = () => {
  let certificationParams = window.localStorage.getItem('certificationParams') && JSON.parse(window.localStorage.getItem('certificationParams')) || {}
  certificationParams.video = videoPath

  doCertification(certificationParams).then(res => {
    if (res.result === 200000) {
      router.push('/home')
      Toast.success('Tải lên thành công，Vui lòng chờ duyệt')
    } else {
      Toast.success(res.msg)
      resetStatus()
    }
  }).catch(err => {
    console.log('error', err)
    Toast.fail('Tải lên bị lỗi,Vui lòng xác thực lại')
    resetStatus()
  })
}

let videoUploadFile = null

const uploadVideo = () => {
  // 此时可以自行将文件上传至服务器
  const formData = new FormData()
  formData.append('file', videoUploadFile)
  formData.append('file_name', 'video')

  uploadImage(formData).then(res => {
    if(res.result === 200000) {
      videoPath = res.data.path
      submit()
    } else {
      Toast.fail(res.msg)
      resetStatus()
    }
  }).catch(err => {
    videoPath = ''
    console.log('error', err)
    Toast.fail('上传异常,请重新认证')
    resetStatus()
  })
};

watch(() => timeCount.value, val => {
  if (val > 12) {
    stop()
    clearInterval(timer)
    Toast('Ghi hình thành công，Đang tải lên')
  }
})

let mimeType = 'video/webm'

if (detectOS() === 'ios') {
  mimeType = 'video/mp4'
}
// 开始录制
function startRecording(stream, lengthInMS) {
  recorder = new MediaRecorder(stream, {
    //因为视频和音频分开录制这里直接注释了
    // audioBitsPerSecond: 128000, // 音频码率
    bitsPerSecond: 1843200, // 视频码率
    mimeType: mimeType, // 编码格式， 编码设为web兼容性较好，部分手机不支持mp4格式
  })
  recorder.ondataavailable = (event) => {
    let data = event.data;
    dataChunks.push(data);
  };
  recorder.start(1000);
  isRecording.value = 1
  timer = setInterval(() => {
    timeCount.value ++
  }, 1000)
  console.log(recorder.state + " Bắt đầu ghi .....");
}

const stop = async () => {
  try {
    // 关闭录制
    if (preview.value.srcObject && preview.value.srcObject.getTracks) {
      preview.value.srcObject.getTracks().forEach((track) => track.stop());
    }
    recorder.stop();

    // 预览视频
    let recordedBlob = new Blob(dataChunks, { type: "video/mp4" });
    recording.value.src = URL.createObjectURL(recordedBlob);
    // 获取视频第一帧
    handleVideoFile()
    isRecording.value = 2
    console.log(recorder.state + " Hoàn thành ghi .....");

    // 将 Blob 转换为 File 对象
    videoUploadFile = new File([recordedBlob], 'video.mp4', { type: 'video/mp4', lastModified: Date.now() });
    uploadVideo()
  } catch (e) {
    console.log('errorerrorerrorerror' + e);
  }
}

//访问用户媒体设备的兼容方法
const getUserMedia = (constraints, success, error) => {

  if (navigator.mediaDevices.getUserMedia) {
    //最新的标准API
    navigator.mediaDevices.getUserMedia(constraints).then(success).catch(error);
    var supportedConstraints = navigator.mediaDevices.getSupportedConstraints();
    console.log('supportedConstraints', supportedConstraints)
  } else if (navigator.webkitGetUserMedia) {
    //webkit核心浏览器
    navigator.webkitGetUserMedia(constraints, success, error)
  } else if (navigator.mozGetUserMedia) {
    //firfox浏览器
    navigator.mozGetUserMedia(constraints, success, error);
  } else if (navigator.getUserMedia) {
    //旧版API
    navigator.getUserMedia(constraints, success, error);
  }
}

const success = (stream) => {
  console.log('srcObject', preview.value)
  const CompatibleURL = window.URL || window.webkitURL;
  //将视频流设置为video元素的源
  try {
    preview.value.src = CompatibleURL.createObjectURL(stream);
  } catch (e) {
    preview.value.srcObject = stream;
  }
  // preview.value.captureStream =
  //     preview.value.captureStream || preview.value.mozCaptureStream;
  // startRecording(preview.value.captureStream());
  startRecording(stream);
}

const error = (error) => {
  Toast.fail('Lỗi truy cập thiết bị，Đổi trình duyệt để xác thực');
  console.log(`Lỗi truy cập thiết bị${error.name}, ${error.message}`);
}

const start = () => {
  console.log('navigator.mediaDevices', navigator)
  if (navigator.mediaDevices.getUserMedia || navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia) {
    //调用用户媒体设备, 访问摄像头
    getUserMedia({
      video: {
        width: { ideal: 1280, max: 1280 },
        height: { ideal: 720, max: 720 },
        facingMode: 'user', // 前置摄像头
        // facingMode: { exact: "environment" }, //  后置摄像头
        frameRate: { ideal: 30, max: 30 },
        torch: true
      }
    }, success, error);
  } else {
    Toast.fail('不支持访问用户媒体');
  }
}

const canvas = ref(null)
const thumbnail = ref(null)

/**
 * 将视频加载到 <video> 元素中，然后在视频的 loadeddata 事件中，将视频的当前时间设置为0（视频的开始时间），并使用 <canvas> 元素绘制视频的第一帧。最后，从 <canvas> 元素中提取图像数据。
 * */
function handleVideoFile() {
  recording.value.addEventListener('loadeddata', () => {
    recording.value.currentTime = 0;
  });

  recording.value.addEventListener('seeked', () => {
    captureFrame();
  });
}

// 或者视频第一帧
function captureFrame() {
  const context = canvas.value.getContext('2d');
  canvas.value.width = recording.value.videoWidth;
  canvas.value.height = recording.value.videoHeight;
  context.drawImage(recording.value, 0, 0, canvas.value.width, canvas.value.height);
  const imageDataURL = canvas.value.toDataURL('image/png');
  thumbnail.value.src = imageDataURL;
}

const onClickLeft = () => {
  router.push('/IdentityVerification')
}

const resetStatus = () => {
  setTimeout(() => {
    window.location.reload()
  }, 1000)
}
</script>
<style lang="less" scoped>
.record-video {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  font-family: PingFang SC;

  .van-nav-bar {
    width: 100%;
  }

  .title {
    margin-top: 20px;
    margin-bottom: 12px;
    font-size: 32px;
    font-weight: 500;
    line-height: 32px;
  }

  .sub-title {
    margin-bottom: 32px;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
  }

  .videoWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 240px;
    height: 240px;
    margin-bottom: 63px;
    background: #C4C4C4;
    border-radius: 50%;
    overflow: hidden;

    .video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }

    img {
      width: 100%;
      object-fit: cover;
    }
  }

  .tips {
    font-size: 24px;
    font-weight: 500;
    line-height: 18px;
    color: var(--textColor);
  }

  .btn {
    width: 343px;
    height: 44px;
    margin-top: 47px;
    font-size: 16px;
    font-weight: 500;
    line-height: 44px;
    text-align: center;
    color: #FFF;
    border-radius: 6px;
    background: #2EBD85;
  }
}
</style>
