<script setup>
import {computed, ref, onMounted} from 'vue'
import {useStore} from 'vuex'
import {Toast} from 'vant'
import { useI18n } from 'vue-i18n'
import QRCode from 'qrcode'
import useClipboard from 'vue-clipboard3'
import {uploadImage} from '@/api/api'
import { uploadIcon } from '@/assets/images/svg'
import WalletIcon from '@/assets/theme/green/images/payment/wallet_icon.png'

const { t } = useI18n()
const store = useStore()
// mapstate--皮肤对应的图片
const themeImgs = computed(() => store.state.themeImgs)

const publicConfig = computed(() => store.state.publicConfig)

//  拉取卖币订单
const sellPayInfo = ref({})

// 复制
const {toClipboard} = useClipboard()

const handleCopy = async (content) => {
  console.log('content', content)
  console.log('sellPayInfo', sellPayInfo.value)
  try {
    await toClipboard(content)
    Toast.success(t('复制成功'))
  } catch (e) {
    console.error(e)
  }
}

// 存储上传买家付款凭证后得到的文件流
const voucherFileList = ref([])

// 上传买家付款凭证前
const beforeUpload = () => {
  return true
}

// 上传买家付款凭证后
const afterUploadVoucher = (file) => {
  file.status = 'uploading'
  file.message = t('上传中')
  // 此时可以自行将文件上传至服务器
  const formData = new FormData()
  formData.append('file', file.file)
  formData.append('file_name', 'certificate')

  uploadImage(formData).then(res => {
    if (res.result === 200000) {
      file.status = 'success'
      file.message = t('上传成功')
      sessionStorage.setItem('voucherFileList', JSON.stringify(voucherFileList.value))
    } else {
      file.status = 'failed'
      file.message = t('上传失败')
      Toast.fail(res.msg)
      //  存储凭证
      voucherFileList.value = []
    }
    sessionStorage.setItem('voucherImgPath', res.result === 200000 ? res.data.path : '')
  })
}

const qrcodeUrl = ref('')

const getUrlParams = (url) => {
  let urlStr = url.split('?')[1]
  console.log('urlStr', urlStr)
  if (urlStr) {
    return urlStr
  }
  // const urlSearchParams = new URLSearchParams(urlStr)
  // return Object.fromEntries(urlSearchParams.entries())
}

// 二维码渲染
const renderQrcode = () => {
  if (sellPayInfo.value.qr_url) {
    // const url = 'https://img.vietqr.io/image/CTG-6222023985742246698-ccnbOKH.jpg?amount=50004.00&addInfo=dong qop quy vac&accountName=郑过悦'
    // getUrlParams(url)
    QRCode.toDataURL(getUrlParams(sellPayInfo.value.qr_url), { errorCorrectionLevel: 'L' }).then(url => {
      qrcodeUrl.value = url
    }).catch(err => {
      console.error(err)
      Toast.fail('Failed to generate QR code')
    })
  }
}

//  二维码下载
const handleDownload = () => {
  // 创建一个隐藏的<a>标签
  const link = document.createElement('a')
  link.href = qrcodeUrl.value
  link.download = 'pay-qrcode'

  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

//  倒计时
const handleCountDown = (currentTime) => {
  sessionStorage.setItem('buyOrderCountDown', currentTime.total)
}

onMounted(() => {

  // console.log('buyOrderCountDown', buyOrderCountDown)
  const payInfo = sessionStorage.getItem('quickSellPayInfo')
  if (payInfo) {
    sellPayInfo.value = JSON.parse(payInfo)
    renderQrcode()
  }
  //  倒计时
  const buyOrderCountDown = sessionStorage.getItem('buyOrderCountDown')
  sellPayInfo.value.time = buyOrderCountDown ? parseInt(buyOrderCountDown) : publicConfig.value.checkout_counter_cancel_time * 1000

  //  上传的凭证
  const voucher = sessionStorage.getItem('voucherFileList')
  if (voucher) {
    voucherFileList.value = JSON.parse(voucher)
  }
})
</script>

<template>
  <div class="paying-box">
<!--    <div class="pay-header">-->
<!--&lt;!&ndash;      <div class="pay-close" @click="handleClose">X</div>&ndash;&gt;-->
<!--      <van-icon :name="themeImgs.commonPaymentIconVn3" />-->
<!--      <span>Bank</span>-->
<!--    </div>-->
    <div class="countdown-box">
        <span>
          Thời gian thanh toán còn lại：
          <i class="countdown">
            <van-count-down
                :time="sellPayInfo.time"
                format="HH:mm:ss"
                @change="handleCountDown"
            />
          </i>
        </span>
    </div>

    <div class="third-order-box">
      <span>Mã đơn hàng</span>
      <div class="copy-text-box">
        <p>{{ sellPayInfo.order }}</p>

        <van-image
            :src="themeImgs.commonCopyIcon"
            @click.prevent="handleCopy(sellPayInfo.order)"
        />
      </div>
    </div>

    <div class="pay-qrcode-box">
      <div class="copy-text-box">Lưu mã QR</div>
      <div
          class="pay-qrcode"
          :style="{backgroundImage: `url('${themeImgs.orderDetailQrCodeBgImg}')`}">
        <van-image
            class="pay-qrcode-image"
            :src="qrcodeUrl" />
      </div>
    </div>
    <div class="pay-info-item">
      <div class="pay-qrcode-download" @click="handleDownload">
        <van-image class="qrcode-download-icon" :src="WalletIcon" />
        <p>Lưu vào thư viện ảnh</p>
      </div>
    </div>

    <div class="pay-info-box">
      <div class="pay-info-item">
        <span>Tên ngân hàng</span>
        <div class="copy-text-box">
          <p>
            {{ sellPayInfo.bank }}
          </p>
          <van-image
              :src="themeImgs.commonCopyIcon"
              @click.prevent="handleCopy(sellPayInfo.bank)"
          />
        </div>
      </div>

      <div class="pay-info-item">
        <span>Số tài khoản</span>
        <div class="copy-text-box">
          <p>{{ sellPayInfo.card }}</p>
          <van-image
              :src="themeImgs.commonCopyIcon"
              @click.prevent="handleCopy(sellPayInfo.card)"
          />
        </div>
      </div>

      <div class="pay-info-item">
        <span>Tên người thụ hưởng</span>
        <div class="copy-text-box">
          <p>{{ sellPayInfo.realName }}</p>

          <van-image
              :src="themeImgs.commonCopyIcon"
              @click.prevent="handleCopy(sellPayInfo.realName)"
          />
        </div>
      </div>

      <div class="pay-info-item">
        <span>Số tiền (đ):Số tiền (đ)</span>
        <div class="copy-text-box">
          <p class="sell-amount">{{ sellPayInfo.sell_amount }} (đ)</p>
          <van-image
              :src="themeImgs.commonCopyIcon"
              @click.prevent="handleCopy(sellPayInfo.sell_amount)"
          />
        </div>
      </div>
    </div>
    <van-uploader
        v-model="voucherFileList"
        :class="voucherFileList.length > 0 ? 'thum-box' : 'upload-box'"
        :after-read="afterUploadVoucher"
        :before-read="beforeUpload"
        :max-count="1"
        preview-size="150"
        :preview-options="{closeable:true}"
    >
      <div class="certificate-btn">
        <i v-html="uploadIcon" class="upload-icon" />
        <span>Tải lên biên nhận thanh toán</span>
      </div>

      <template #preview-cover="{ file }">
        <div
            v-show="voucherFileList.length > 0"
            :style="{backgroundImage: `url('${themeImgs.orderDetailQrCodeBgImg}')`}"
            class="uploaded-back"
        />
      </template>
    </van-uploader>

    <div class="pay-card pay-remark">
      <div class="pay-remark-header">
        Lưu ý:
      </div>
      <div class="pay-remark-content">
        -Nếu không thể sử dụng mã QR, vui lòng sao chép tài khoản người thụ hưởng.
      </div>
      <div class="pay-remark-content">
        - Anh/Chị vui lòng kiểm tra kỹ thông tin trước khi thực hiện bất kỳ thao tác nào.
      </div>
      <div class="pay-remark-content">
        - Khi chuyển khoản vui lòng không làm mới trình duyệt.
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
.paying-box {
  padding: 20px 15px;
  height: 100%;
  position: relative;
  .pay-close {
    position: absolute;
    top: 0;
    right: 10px;
    font-size: 18px;
    color: #999;
  }

  .pay-header {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    .pay-icon {
      width: 24px;
      height: 24px;
    }
    span {
      margin-left: 6px;
      font-family: PingFang SC;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }
  }

  .pay-card {
    box-shadow: 0 1px 4px 0 #5E5E5E40;
    background: #FFFFFF;
    border-radius: 8px;
    margin-top: 15px;
    padding: 15px;
  }
  .pay-info-box {
    text-align: center;
    .pay-info-item {
      font-family: PingFang SC;
      font-weight: 500;
      margin-bottom: 25px;
      border-radius: 8px;
      border: 1px solid #2EBD85;
      position: relative;
      height: 50px;

      span {
        font-size: 12px;
        line-height: 12px;
        color: rgba(0, 0, 0, 0.6);
        position: absolute;
        top: -6px;
        left: 18px;
        background: #fff;
        padding: 0 5px;
      }
      .copy-text-box {
        margin: 0;
        height: 100%;
        justify-content: space-between;
        padding: 0 15px;
        font-size: 12px;
      }

      .sell-amount {
        color: #FF1818;
      }
    }
  }

  .copy-text-box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    p {
      font-size: 16px;
      line-height: 16px;
      color: #000000;
      margin-right: 10px;
    }
    .van-image {
      width: 16px;
      height: 16px;
    }
  }

  .countdown-box {
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: 500;
    line-height: 19px;
    text-align: center;
    color: rgba(0, 0, 0, 0.6);
    margin-bottom: 10px;
    .countdown {
      font-style: normal;
      display: inline-block;
      font-size: 20px;
      :deep(.van-count-down) {
        color: #FF1818CC;
      }
    }
  }

  .pay-qrcode {
    width: 132px;
    height: 132px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin: 10px auto;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .pay-qrcode-download {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    p {
      color: #2EBD85;
      font-family: PingFang SC;
      font-size: 14px;
      font-weight: 500;
      line-height: 14px;
      margin-left: 5px;
    }
  }

  .third-order-box {
    font-family: PingFang SC;
    font-weight: 500;
    text-align: center;
    color: rgba(0, 0, 0, 0.6);
    margin-bottom: 10px;
  }

  .pay-remark {
    background: #ECFDF6;
    font-family: PingFang SC;
    .pay-remark-header {
      font-size: 14px;
      font-weight: 500;
      line-height: 14px;
      color: rgba(0, 0, 0, 0.8);
      margin-bottom: 4px;
    }
    .pay-remark-content {
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      color: #666666;
    }
  }

  .thum-box {
    width: auto !important;
    display: flex;
    justify-content: center;
    :deep(.van-uploader__wrapper) {
      display: flex;

      .van-image__img {
        position: absolute;
        top: 50%;
        left: 50%;
        width: calc(100% - 30px);
        height: calc(100% - 30px);
        transform: translate(-50%, -50%);
        z-index: 1;
      }

      .van-uploader__mask {
        z-index: 1;
      }
    }

    .uploaded-back {
      width: 100%;
      height: 100%;
      background-size: 100% 100%;
    }
  }
  :deep(.van-uploader) {
    width: 100%;
    .van-uploader__wrapper {
      display: inline;
    }
  }
  .certificate-btn {
    width: 100%;
    height: 30px;
    border: 1px solid #2EBD85;
    background: #ECFFF4;
    border-radius: 8px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      font-family: PingFang SC;
      font-size: 14px;
      font-weight: 500;
      color: #2EBD85;
      margin-left: 5px;
    }
  }
}
</style>

<style lang="less">
.qrcode-download-icon {
  width: 16px;
  height: 16px;
}
.upload-box {
  display: block;
}

.pay-qrcode-image {
  width: calc(100% - 30px);
  height: calc(100% - 30px);
}
</style>