export const useUtils = () => {
  // 设置带有过期时间的 localStorage 项
  function setWithExpiry(key, value, ttl) {
    const now = new Date();
    // ttl 是过期时间（单位：毫秒）
    const item = {
      value: value,
      expiry: now.getTime() + ttl,
    };
    localStorage.setItem(key, JSON.stringify(item));
  }

// 获取 localStorage 项，检查其是否过期
  function getWithExpiry(key) {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();
    if (now.getTime() > item.expiry) {
      localStorage.removeItem(key);
      return null;
    }
    return item.value;
  }

/*// 使用示例
// 设置一个条目，并且让它 1 小时后过期
  setWithExpiry('exampleItem', 'value', 60 * 60 * 1000);

// 读取该条目，如果它过期了，将返回 null
  const value = getWithExpiry('exampleItem');*/

  // 将货币每三位数用逗号分隔
  const formatCurrency = (number) => {
    // return new Intl.NumberFormat('en-US', {}).format(Number(number))
    const _number = typeof number === 'number' ? number.toString() : number
    const res = _number.replace(/\d+/, function (n) {
      return n.replace(/(\d)(?=(\d{3})+$)/g, function ($1) {
        return $1 + ','
      })
    })

    return res
  }

  return {
    setWithExpiry,
    getWithExpiry,
    formatCurrency
  }
}
