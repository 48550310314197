<script setup>
import {ref, computed, defineProps} from 'vue'
import {useStore} from 'vuex'
import HelpItemFast from './HelpItemFast.vue'
import HelpItemQuestion from './HelpItemQuestion.vue'

const props = defineProps({
  data: {
    type: Array,
    default: []
  }
})

const store = useStore()

// mapstate--皮肤对应的图片
const themeImgs = computed(() => store.state.themeImgs)

//  tabs
const items = [
  {
    id: 1,
    label: 'Học hỏi',
    labelIcon: themeImgs.value.homeHelpLeftLabelText,
    labelBack: themeImgs.value.homeHelpLeftCurrentTab,
  },
  {
    id: 2,
    label: 'Câu hỏi',
    labelIcon: themeImgs.value.homeHelpRightLabelText,
    labelBack: themeImgs.value.homeHelpRightCurrentTab,
  },
]

const activeTab = ref(1);

//  背景图片的加载
const boxBackImg = () => {
  if(activeTab.value === 2) {
    return themeImgs.value.homeHelpRightImg
  }
  return themeImgs.value.homeHelpLeftImg
}

//  tab的切换
const handleTab = (id) => {
  activeTab.value = id
}
</script>

<template>
  <div
      class="help-box"
      :class="{'help-box-h': activeTab === 2}"
      :style="{ backgroundImage: `url('${boxBackImg()}')` }">
    <div class="help-tabs">
      <div
          v-for="item in items"
          :key="item.id"
          v-show="activeTab === item.id"
          class="item-current"
          :style="{ backgroundImage: `url('${item.labelBack}')`}"
          :class="{[`item-current-${item.id}`]: true,}">
        <img
            class="label-img"
            :class="`label-img-${item.id}`"
            :src="item.labelIcon" alt="" />
        <span>
          {{ item.label }}
        </span>
      </div>
      <div
          v-for="item in items"
          :key="item.id"
          class="help-tab"
          :class="{[`help-tab-${item.id}`]: true,}"
          @click="handleTab(item.id)">
        <span>{{ item.label }}</span>
      </div>
    </div>

    <div class="help-content">
      <HelpItemFast v-if="activeTab === 1" :data="props.data" />
      <HelpItemQuestion v-if="activeTab === 2" />
    </div>
  </div>
</template>

<style scoped lang="less">
.help-box {
  width: calc(100% - 14px);
  height: 169px;
  margin: 30px 7px 10px 7px;
  background-repeat: no-repeat;
  background-size: 100% 100%;

  &.help-box-h {
    height: 212px;
  }
  .help-tabs {
    position: relative;
    height: 40px;
    .item-current {
      width: 197px;
      height: 49px;
      //position: relative;
      position: absolute;
      background-repeat: no-repeat;
      background-size: 100%;
      text-align: center;
      margin-top: -17px;
      z-index: 1;
      &.item-current-1 {
        left: 0;
      }
      &.item-current-2 {
        right: 0;
      }
      .label-img {
        position: absolute;
        transform: translate(-50%, -50%);
        top: 60%;
        &.label-img-1 {
          left: 44%;
        }
        &.label-img-2 {
          right: 21%;
        }
      }
      span {
        font-family: PingFang SC;
        font-size: 16px;
        font-weight: 600;
        line-height: 40px;
        position: absolute;
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;
      }
    }

    .help-tab {
      width: 50%;
      height: 49px;
      text-align: center;
      position: absolute;
      z-index: 0;
      transform: translateY(-50%);
      top: 0;
      &.help-tab-1 {
        left: 0;
      }
      &.help-tab-2 {
        right: 0;
      }
      span {
        font-family: PingFang SC;
        font-size: 16px;
        font-weight: 600;
        line-height: 80px;
        color: rgba(0, 0, 0, 0.5);
      }
    }
  }
  .help-content {
    margin: 0 10px;
    //height: 159px;
    overflow: hidden;
  }
}
</style>