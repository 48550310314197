<template>
  <div class="editPersonalInfo-wrap">
    <van-nav-bar
        :border="false"
        left-arrow
        :title="t('个人资料')"
        @click-left="onClickLeft"
    />
    <div class="editPersonalInfo-main-wrap">
      <!--   设置头像事件  @click="showAvatarDialog = true"   -->
      <van-cell center is-link :title="t('设置头像')" @click="showAvatarPopup = true">
        <template #value>
          <van-image :src="getAvatar(presonalInfoObj.avatarNew)" class="avatar-img" round/>
        </template>
      </van-cell>
      <van-cell :value="presonalInfoObj.nick_name" center is-link :title="t('修改昵称')" @click="showNickNamePop=true"/>
    </div>
    <!-- 修改头像 -->
    <!--        <van-dialog class="editPersonalInfo-avatar-dialog" v-model:show="showAvatarDialog" title="修改头像" show-cancel-button @confirm="handleUpdateAvatar">-->
    <!--            <van-uploader class="editPersonalInfo-uploader" :max-count="1" v-model="uploadFile" :after-read="afterUpload" />-->
    <!--        </van-dialog>-->
    <!-- 修改昵称 -->
    <van-popup
        v-model:show="showNickNamePop"
        class="showPop-wrap"
        position="bottom"
        round
    >
      <div class="pop-head">
        <div class="pop-cancle-btn" @click="showNickNamePop=false">{{ t('取消') }}</div>
        <div class="pop-title">{{ t('修改昵称') }}</div>
        <div class="pop-comfirm-btn" @click="handleUpdateNickName">{{ t('完成') }}</div>
      </div>
      <div class="pop-content">
        <van-field v-model="newNickName" autocomplete="off" clearable :placeholder="t('请输入新的昵称')"/>
      </div>
    </van-popup>

    <van-popup
        v-model:show="showAvatarPopup"
        class="showPop-wrap"
        position="bottom"
        round
    >
      <div class="pop-head">
        <div class="pop-cancle-btn" @click="cancelAvatarPopup">{{ t('取消') }}</div>
        <div class="pop-title">{{ t('设置头像') }}</div>
        <div class="pop-comfirm-btn" @click="handleUpdateAvatar">{{ t('完成') }}</div>
      </div>
      <div class="pop-content">
        <div v-for="(_, index) in avatarList"
             :key="index"
             :class="{ active: avatarId === (index + 1)}"
             class="avatarItem"
             @click="selectAvatar(index + 1)"
        >
          <van-image :src="avatarList[index]" round/>
          <div :style="{ backgroundImage: `url(${themeImgs.myAvatarSelected})` }"
               class="activeBorder"
          />
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import {computed, onMounted, ref, watch} from 'vue'
import {useStore} from 'vuex'
import {getMemberInfo, upAvatar, upNickName} from '@/api/api'
import {Toast} from 'vant'
import {useGetAvatar} from "@hooks";
import { useI18n } from 'vue-i18n'

export default {
  setup() {
    const { t } = useI18n()
    const store = useStore()
    const {getAvatar} = useGetAvatar()

    // 点击导航栏左侧返回键/取消按钮
    const onClickLeft = () => {
      history.back()
    }
    const presonalInfoObj = ref({})
    // 修改头像相关
    // let showAvatarDialog = ref(false)
    let showAvatarPopup = ref(false)

    // 存储上传头像后得到的文件流
    // const uploadFile = ref([])
    // 存储上传头像后得到的头像地址
    // const avatarUrl = ref('')
    // 上传头像后
    // const afterUpload = (file) => {
    //     // 此时可以自行将文件上传至服务器
    //     const formData = new FormData()
    //     formData.append('file', file.file)
    //     formData.append('file_name', 'avatar')
    //     uploadImage(formData).then(res => {
    //         if(res.result == 200000) {
    //             avatarUrl.value = res.data.path
    //         } else {
    //             Toast.fail(res.msg)
    //         }
    //     })
    // };

    // 批量倒入头像
    const files = require.context('@/assets/images/avatars/', false, /\.jpeg$/)
    const avatarList = ref([])
    const avatarKeys = ref([])

    avatarKeys.value = files.keys()

    function sort(value) {
      return Number(value.split('/')[1].split('.')[0])
    }

    avatarKeys.value.sort((a, b) => {
      return sort(a) - sort(b)
    })

    avatarKeys.value.forEach(key => {
      // console.log(key, files(key))
      avatarList.value.push(files(key))
    })

    const avatarId = ref(1)

    const selectAvatar = (id) => {
      avatarId.value = id
    }

    watch(() => presonalInfoObj.value.avatarNew, (val) => {
      selectAvatar(isNaN(Number(val)) ? 1 : Number(val))
    }, {
      immediate: true
    })

    const cancelAvatarPopup = () => {
      showAvatarPopup.value = false
      setTimeout(() => {
        const avatarNew = presonalInfoObj.value.avatarNew
        selectAvatar(isNaN(Number(avatarNew)) ? 1 : Number(avatarNew))
      }, 200)
    }
    // 修改昵称
    const showNickNamePop = ref(false)
    const newNickName = ref('')
    // 获取个人资料
    const handleGetUserInfo = () => {
      getMemberInfo().then(res => {
        if (res.result == 200000) {
          presonalInfoObj.value = res.data
          store.dispatch('saveUserInfosActions', res.data)
        }
      })
    }
    onMounted(() => {
      handleGetUserInfo()
    })
    // 修改头像
    const handleUpdateAvatar = () => {
      upAvatar({avatar: avatarId.value}).then(res => {
        if (res.result === 200000) {
          Toast.success(t('修改成功'))
          // uploadFile.value = []
          handleGetUserInfo()
          showAvatarPopup.value = false
        } else {
          Toast.fail(res.msg)
        }
      })
    }
    // 修改昵称
    const handleUpdateNickName = () => {
      if (!newNickName.value) {
        return Toast.fail(t('请输入新的昵称'))
      }
      upNickName({nick_name: newNickName.value}).then(res => {
        if (res.result === 200000) {
          showNickNamePop.value = false
          newNickName.value = ''
          Toast.success(t('修改成功'))
          handleGetUserInfo()
        } else {
          Toast.fail(res.msg)
        }
      })
    }

    // mapstate--皮肤对应的图片
    const themeImgs = computed(() => store.state.themeImgs)

    return {
      onClickLeft,
      presonalInfoObj,
      showAvatarPopup,
      // showAvatarDialog,
      // afterUpload,
      // uploadFile,
      handleUpdateAvatar,
      handleUpdateNickName,
      showNickNamePop,
      newNickName,
      avatarList,
      selectAvatar,
      getAvatar,
      avatarId,
      cancelAvatarPopup,
      themeImgs,
      t
    }
  }
}
</script>

<style lang="less" scoped>
.editPersonalInfo-wrap {
  .editPersonalInfo-main-wrap {
    height: calc(100vh - 44px);
    //background: var(--mainBgColor);

    .avatar-img {
      width: 46px;
      height: 46px;
    }

    .van-cell {
      min-height: 50px;
      background: var(--myCellBgColor);
    }
  }

  :deep(.editPersonalInfo-avatar-dialog) {
    .van-dialog__header {
      color: #fff;
    }

    .van-dialog__content {
      padding: 20px 0;
      text-align: center;
    }
  }

  :deep(.showPop-wrap) {
    .pop-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 56px;
      padding: 0 16px;
      color: var(--textCommonColor);

      .pop-title {
        font-weight: 500;
        font-size: 17px;
      }

      .pop-comfirm-btn {
        font-weight: 600;
      }
    }

    .pop-content {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      min-height: 96px;
      max-height: 324px;
      padding: 8px 32px 0;
      overflow-y: auto;

      .van-cell {
        border-radius: 6px;
        background: var(--inputBgColor);
      }

      .avatarItem {
        position: relative;
        width: 60px;
        height: 60px;
        margin-bottom: 12px;
        margin-right: 23px;

        &:nth-of-type(4n) {
          margin-right: 0;
        }

        .activeBorder {
          position: absolute;
          top: -2px;
          left: -2px;
          //transform: translate(-50%, -50%);
          display: none;
          width: 100%;
          height: 100%;
          background-size: 100% 100%;
        }

        &.active {
          .activeBorder {
            display: block;
          }

          .van-image {
            width: 57px;
            height: 57px;
          }
        }
      }
    }
  }
}
</style>
