<template>
  <div class="home-wrap">
    <div class="home-main-wrap">
      <div class="home-header-wrap">
        <van-image :src="themeImgs.homeMenu" class="menu" @click="showSideBar = true"/>
        <van-image :src="require('@siteResources/logo/home_logo.png')" class="logo" />
        <div class="right-box">
          <van-icon :name="themeImgs.commonServiceIcon" @click="setShowServicePopup(true)"/>

          <van-icon :name="themeImgs.homeMsgIcon" class="msg" @click="handleOpenMsgUrl"/>
        </div>
      </div>

      <van-swipe :autoplay="3000" class="my-swipe" indicator-color="white">
        <van-swipe-item v-for="(item,index) in bannerData" :key="index">
          <van-image v-if="!item.url" :src="item.pic" class="avatar"/>
          <a v-else-if="item.url_type === 2" rel="noopener noreferrer" target="_blank" @click="goTobanner(item)">
            <van-image :src="item.pic" class="avatar"/>
          </a>
          <a v-else :href="item.url" rel="noopener noreferrer" target="_blank">
            <van-image :src="item.pic" class="avatar"/>
          </a>
        </van-swipe-item>
      </van-swipe>

      <van-notice-bar
          :left-icon="themeImgs.homeNoticeIcon"
          :text="announcementData || t('暂无公告')"
          background="none"
          class="home-notice-bar"
          scrollable
          speed="50"
      />

      <div class="enter-box">
        <div class="enter-item-box" @click="buyCoin">
          <i :style="{ backgroundImage: `url('${themeImgs.homeSellIcon}')` }"></i>
          <span class="title">{{ t('购买') }}</span>
        </div>

        <div class="enter-item-box" @click="handleOpenSellCoinUrl">
          <i :style="{ backgroundImage: `url('${themeImgs.homeBuyIcon}')` }"></i>
          <span class="title">{{ t('出售') }}</span>
        </div>
      </div>

      <TradingHall/>

      <div v-if="isShowFromThirdPayBtn" class="go-pay-page" @click="goBackPayUrl">{{ t('回到充值页') }}</div>

      <!-- 公告弹窗 -->
      <van-dialog v-model:show="showNotification" :show-confirm-button="false" class="notification-pop">
        <div class="top-box" @click="handleCloseNotification">
          <van-icon :name="themeImgs.homeCloseIcon" />
        </div>

        <van-swipe :autoplay="3000" ref="notificationSwipeRef" class="notification-swipe" :show-indicators="false">
          <van-swipe-item v-for="(item,index) in notificationData" :key="index"
                          :style="`backgroundImage:url(${locale === 'vi' ? themeImgs.homePopBgImgVn : themeImgs.homePopBgImg})`">
            <div class="title">{{ item.title }}</div>
            <div class="content" v-html="handleContent(item.content)"></div>
          </van-swipe-item>
        </van-swipe>

<!--        <div v-if="showPrevNextBtn" class="btn-box">-->
<!--          <van-icon :name="themeImgs.homePrevIcon" class="prev-btn" @click="handlePrev"/>-->
<!--          <van-icon :name="themeImgs.homeNextIcon" class="next-btn" @click="handleNext"/>-->
<!--        </div>-->

        <div v-if="showPrevNextBtn" class="next-btn" @click="handleNext">{{ $t('下一个') }}</div>

        <div class="no-show-24">
          <van-checkbox name='rememberMe' shape="square" v-model="close24Hours">
            {{ t('24小时内不再出现提示') }}
          </van-checkbox>
        </div>
      </van-dialog>

      <!--          您的订单有新状态-->
<!--      <div v-if="isHaveNewOrder" class="order-status-box" @click="openUrlOrderDetails">-->
<!--        <p>您的订单有新状态</p>-->
<!--        <div class="immediate-box">-->
<!--          <span>立即处理</span>-->
<!--          <van-icon :name="themeImgs.homeDoubleRightArrowIcon"/>-->
<!--        </div>-->
<!--      </div>-->
    </div>
    <!--    <Footer/>-->
    <SideBar v-model:showSideBar="showSideBar" />

    <Deposit v-model:showDeposit="showDeposit" v-if="showDeposit" />
    <Withdraw v-model:showWithdraw="showWithdraw" v-if="showWithdraw" />
  </div>
</template>

<script setup>
import {computed, onMounted, ref, watch} from "vue";
import {useRouter} from "vue-router";
import SideBar from "@/components/SideBar";
import {getBanner, getBulletin, getMemberInfo, getOrderRemind, logout} from '@/api/api'
import {useStore} from 'vuex'
import {Toast} from "vant";
import useClipboard from 'vue-clipboard3'
import wsConnection from "@/utils/websocket"
import {useGetAvatar, useUtils} from "@hooks";
import {handleContent} from '@/utils'
import TradingHall from './TradingHall'
import Deposit from './DepositAndWithdraw/Deposit'
import Withdraw from './DepositAndWithdraw/Withdraw'
import { useI18n } from 'vue-i18n'

const { t, locale } = useI18n()
const $router = useRouter()
const store = useStore()
const { setWithExpiry, getWithExpiry } = useUtils()
const token = computed(() => store.state.loginInfos.token)

const {getAvatar} = useGetAvatar()
// 盘口标识
const siteName = ref(siteChannel)
// 获取会员个人信息
const handleGetMemberInfo = () => {
  getMemberInfo().then(res => {
    if (res.result == 200000) {
      if (res.data.status != 1) {
        memberInfo.value = res.data
        store.dispatch('saveUserInfosActions', res.data)
        // 为第三方跳转来的创建与登录接口返回值对应的对象
        store.dispatch("saveLoginInfosActions", {
          ...store.state.loginInfos, ...{
            username: res.data.username,
            ws_url: res.data.ws_url
          }
        });
      } else {
        handleDoLogout()
      }
    }
  })
}
// 退出登录
const handleDoLogout = () => {
  logout().then(res => {
    if (res.result == 200000) {
      Toast.success(t('账号被冻结'))
      localStorage.removeItem("loginInfos")
      if (wsConnection.$ws) {
        wsConnection.$ws.close()
      }
      // $router.push('/login')
      store.commit('setLoginRegisterInfo', { flag: true })
    }
  })
}
// // 获取会员交易信息
// const handleGetMemberAmount = () => {
//   getMemberAmount().then(res => {
//     if (res.result == 200000) {
//       memberDealInfo.value = res.data.info
//       store.dispatch('saveMemberAmountActions', res.data.info)
//     }
//   })
// }
// 获取banner
const handleGetBanner = () => {
  getBanner().then(res => {
    if (res.result == 200000) {
      bannerData.value = res.data.list
    }
  })
}
// 是否显示弹窗底部按钮
const showPrevNextBtn = ref(false)
// 获取滚动公告,弹窗，type1为弹窗，2为滚动
const handleGetBulletin = () => {
  getBulletin({type: 1}).then(res => {
    if (res.result == 200000) {
      notificationData.value = res.data.list
      const value = getWithExpiry('announcementClose24h');

      if (res.data.list.length && value !== 1) {
        showNotification.value = true
      } else {
        showNotification.value = false
      }

      if (res.data.list.length > 1) {
        showPrevNextBtn.value = true
      } else {
        showPrevNextBtn.value = false
      }
    }
  })
  getBulletin({type: 2}).then(res => {
    if (res.result == 200000) {
      if (res.data.list.length) {
        res.data.list.forEach((item, index) => {
          announcementData.value += `${item.content}  `
        })
      }
    }
  })
}
onMounted(() => {
  if (token.value) {
    handleGetMemberInfo()
    // handleGetMemberAmount()
    handleGetOrderRemind()
  }
  handleGetBanner()
  handleGetBulletin()

  if (!!sessionStorage.getItem('fromThirdUrlStr')) {
    isShowFromThirdPayBtn.value = true
  }
})
// 存储banner数据
const bannerData = ref([])
// 存储滚动公告
const announcementData = ref('')
// 存储弹窗公告
const notificationData = ref([])
// 存储会员个人信息
const memberInfo = ref({})
// 存储会员单子信息
// const memberDealInfo = ref({})
// 点击跳转到卖币页面
const handleOpenSellCoinUrl = () => {
  if (!token.value) {
    return openLoginReg()
  }

  if (memberInfo.value.is_name == 1) {
    return Toast.fail(t('请先进行实名认证'))
  }

  if (memberInfo.value.is_name == 3) {
    return Toast.fail(t('实名审核中'))
  }

  if (memberInfo.value.is_withdraw == 1) {
    return Toast.fail(t('该功能已禁用'))
  }

  showWithdraw.value = true
}

const buyCoin = () => {
  if (locale.value === 'vi') {
    Toast.fail('Chức năng chưa được mở, vui lòng chọn đơn hàng trong sảnh để mua vào.')
    return
  }
  if (!token.value) {
    return  openLoginReg()
  }

  showDeposit.value = true
}
// 点击跳转到站内消息
const handleOpenMsgUrl = () => {
  $router.push('/announcement')
}
// 复制钱包地址
const {toClipboard} = useClipboard()
const copyAddress = async (content) => {
  try {
    await toClipboard(content)
    Toast.success(t('复制成功'))
  } catch (e) {
    console.error(e)
  }
}
// 是否显示有无订单状态
const isHaveNewOrder = ref(false)
// 存储订单状态提醒数据
const orderRemind = ref([])
// 获取订单状态提醒
const handleGetOrderRemind = () => {
  //    name === bitcoin,比特币消息，不需要调用接口
  if (orderRemindObj.value.name !== 'bitcoin') {
    getOrderRemind().then(res => {
      if (res.result == 200000) {
        orderRemind.value = res.data.list[0]
        if (res.data.list.length > 0) {
          isHaveNewOrder.value = true
        } else {
          isHaveNewOrder.value = false
        }
      }
    })
  }
}
// 计算属性实现视图更新--新订单提醒
const orderRemindObj = computed(() => store.state.orderRemind)
watch(orderRemindObj, (newValue) => {
  orderRemind.value = newValue
  isHaveNewOrder.value = true
}, {
  deep: true // name是一个对象，需要进行深度监听
})

// 计算属性实现视图更新--金额数量变化
// const moneyChangeObj = computed(() => store.state.moneyChangeInfos)
// watch(moneyChangeObj, (newValue) => {
//   memberDealInfo.value = newValue
// }, {
//   deep: true // name是一个对象，需要进行深度监听
// })

// 计算属性实现视图更新--后台审核已实名
const isName = computed(() => store.state.isName)
watch(isName, (newValue) => {
  handleGetMemberInfo()
})
// 点击跳转订单详情页
const openUrlOrderDetails = () => {
  if (orderRemind.value.name !== 'bitcoin') {
    if (orderRemind.value.type == 1) {
      $router.push({
        path: 'buyerOrderDetails',
        query: {
          order_sn: orderRemind.value.order_sn
        }
      })
    } else {
      $router.push({
        path: 'sellerOrderDetails',
        query: {
          order_sn: orderRemind.value.order_sn
        }
      })
    }
  } else {
    $router.push({
      path: '/order',
      query: {
        bitType: orderRemind.value.type
      }
    })
  }
}

// 为true时24小时不再显示公告
const close24Hours = ref(false)

// 是否展示公告弹窗
const showNotification = ref(false)
// 关闭公告弹窗
const handleCloseNotification = () => {
  showNotification.value = false

  // 24小时不再显示公告
  if (close24Hours.value) {
    setWithExpiry('announcementClose24h', 1, 24 * 60 * 60 * 1000)
  } else {
    localStorage.removeItem('announcementClose24h');
  }
}

// 实例化
const notificationSwipeRef = ref()
// 切换到上一轮播
const handlePrev = () => {
  notificationSwipeRef.value.prev();
}
// 切换到下一轮播
const handleNext = () => {
  notificationSwipeRef.value.next();
}
// mapstate--皮肤对应的图片
const themeImgs = computed(() => store.state.themeImgs)
// 是否显示返回到充值页的按钮-第三方
const isShowFromThirdPayBtn = ref(false)
// 返回到充值页-第三方
const goBackPayUrl = () => {
  $router.push(`/fromThirdPay?${sessionStorage.getItem('fromThirdUrlStr')}`)
  sessionStorage.removeItem('fromThirdUrlStr')
}

const setShowServicePopup = (flag) => {
  store.commit('setShowServicePopup', flag)
}

const goTobanner = (item) => {
  $router.push({
    path: "bannerDetail",
    query: {
      pic: item.url,
      title: item.title
    },
  });
}

const showSideBar = ref(false)
const showDeposit = ref(false)
const showWithdraw = ref(false)

const openLoginReg = () => {
  store.commit('setLoginRegisterInfo', { flag: true }) // 打开登录注册弹框
}

// const changLang = () => {
//   if (locale.value === 'vi') {
//     localStorage.setItem('lang', 'zh')
//   }
//
//   if (locale.value === 'zh') {
//     localStorage.setItem('lang', 'vi')
//   }
//
//   location.reload()
// }
</script>

<style lang="less" scoped>
.home-wrap {
  height: 100%;
  //background: var(--mainBgColor);
  overflow: hidden;

  .home-main-wrap {
    //height: calc(100% - 50px);
    height: 100%;
    padding-top: 15px;

    .home-header-wrap {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 29px;
      padding: 0 10px;
      margin-bottom: 15px;

      .menu {
        width: 22px;
        height: 22px;
      }

      .logo {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 104px;
      }

      .right-box {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .van-icon {
          width: 22px;
          height: 22px;
        }

        .msg {
          margin-left: 10px;
        }
      }
    }

    :deep(.my-swipe) {
      width: 100%;
      height: 130px;
      margin-bottom: 11px;
      border-radius: 12px;

      .van-image {
        width: 100%;

        .van-image__img {
          width: 100%;
          height: 130px;
        }
      }
    }

    .home-notice-bar {
      height: 16px;
      margin-bottom: 16px;

      ::v-deep {
        .van-icon {
          width: 16px;
          height: 16px;
          margin-right: 4px;

          .van-icon__image {
            width: 100% !important;
            height: 100% !important;
          }
        }

        .van-notice-bar__content {
          font-family: PingFang SC;
          font-size: 13px;
          font-weight: 400;
          line-height: 14px;
          color: rgba(51, 51, 51, 1);
        }
      }
    }

    .enter-box {
      display: flex;
      justify-content: space-between;
      padding: 0 7px;
      margin-bottom: 16px;

      .enter-item-box {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 172px;
        height: 40px;
        border-radius: 10px;
        font-family: Roboto;
        font-size: 18px;
        font-weight: 400;
        color: rgba(0, 0, 0, 1);
        box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.02);

        &:first-of-type {
          background: rgba(253, 212, 49, 1);
        }

        &:last-of-type {
          background: rgba(46, 189, 133, 1);
        }

        i {
          width: 22px;
          height: 22px;
          margin-right: 6px;
          background-size: 100% 100%;
        }
      }
    }
  }

  /*    :deep(.line-pop){
        background: #ffffff;
        .top{
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 57px;
          padding: 0px 14px;
          border-bottom: 8px solid #F8F9FA;
        }
        .manage{
          display: flex;
          justify-content: center;
          align-items: center;
          height: 56px;
          border-bottom: 1px solid #F8F9FA;
          cursor: pointer;
        }
        .fill-img{
          width: 20px;
          margin-left: 5px;
          margin-top: 3px;
        }
        .fill-img img {
          width: 100%;
        }
        .cursor{
          cursor: pointer;
        }
      }*/

  :deep(.notification-pop) {
    top: 50%;
    //width: 254px;
    width: 258px !important;
    background: none;
    overflow: visible;

    .top-box {
      position: absolute;
      top: 50px;
      right: -12px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 36px;
      height: 36px;
      z-index: 1000;

      img {
        width: 24px;
        height: 24px;
      }
    }

    .notification-swipe {
      //overflow: unset;

      .van-swipe-item {
        width: 100%;
        height: 445px;
        padding: 158px 15px 0;
        background-size: 100% 100% !important;
        //background-size: cover;

        .title {
          font-weight: 500;
          font-size: 16px;
          color: #535C66;
        }

        .content {
          height: 258px;
          margin-top: 10px;
          line-height: 22px;
          color: rgba(83, 92, 102, 0.9);
          word-break: break-all;
          word-wrap: break-word;
          overflow-y: scroll;
        }

        //.van-swipe__indicators {
        //  bottom: -100px;
        //}

        .notification-swipe {
          height: 100%;

          .title {
            font-weight: 500;
            font-size: 16px;
            color: #535C66;
          }

          .content {
            margin-top: 10px;
            line-height: 22px;
            color: rgba(83, 92, 102, 0.9);
            word-break: break-all;
            word-wrap: break-word;
          }

          //.van-swipe__indicators {
          //  bottom: -100px;
          //}

          //.custom-indicator {
          //  position: absolute;
          //  right: 5px;
          //  bottom: 5px;
          //  padding: 2px 5px;
          //  font-size: 12px;
          //  background: rgba(0, 0, 0, 0.1);
          //}
        }
      }

      //@media screen and (min-width: 750px) {
      //  .van-swipe-item {
      //    height: 200px;
      //
      //    .content {
      //      height: 100px;
      //    }
      //  }
      //}

      //.van-swipe__indicators {
      //  bottom: -20px;
      //}
    }

    .next-btn {
      width: 100%;
      height: 40px;
      margin-top: 18px;
      margin-bottom: 15px;
      background: #2EBD85;
      border-radius: 8px;
      font-family: PingFang SC;
      font-size: 16px;
      font-weight: 600;
      line-height: 40px;
      text-align: center;
      color: #FFF;
    }

    .van-checkbox {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 24px;
      margin-top: 14px;

      .van-checkbox__icon {
        width: 18px;
        height: 18px;

        .van-badge__wrapper {
          width: 100%;
          height: 100%;
          line-height: 20px;
          border-radius: 2px;
          border-color: rgba(255, 255, 255, 0.9);
        }
      }

      .van-checkbox__label {
        margin-left: 6px;
        font-family: PingFang SC;
        font-size: 13px;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.9);
      }
    }
  }

  .go-pay-page {
    position: fixed;
    right: 0;
    bottom: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    //width: 100px;
    height: 44px;
    padding: 0 10px;
    //background: var(--btnBgColor);
    background: #2EBD85;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    color: #fff;
    z-index: 1;
    text-align: center;
  }

  .order-status-box {
    position: fixed;
    right: 0;
    bottom: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 120px;
    height: 60px;
    padding-left: 10px;
    //background: var(--btnBgColor);
    background: #2EBD85;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    color: #fff;
    z-index: 1;

    p {
      font-size: 11px;
      font-weight: 500;
      text-align: center;
    }

    .immediate-box {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 8px;

      span {
        font-size: 10px;
        letter-spacing: 0.04em;
      }

      :deep(.van-icon) {
        .van-icon__image {
          width: 8px;
          height: 8px;
        }
      }
    }
  }

  //.qtpay-back-pay-btn {
  //  height: 44px;
  //  margin-bottom: 40px;
  //  line-height: 44px;
  //  text-align: center;
  //  background: #2ebd85;
  //  border-radius: 6px;
  //  color: #fff;
  //  font-weight: 700;
  //}
}
</style>
