<template>
  <div class="home-header-wrap">
    <van-image :src="themeImgs.homeMenu" class="menu" @click="emit('show')"/>
    <van-image :src="require('@siteResources/logo/home_logo.png')" class="logo" />
    <div class="right-box">
      <van-icon
          v-if="showScan"
          :name="themeImgs.commonScan"
          @click="handleOpenScan"
      />

      <van-icon :name="themeImgs.commonServiceIcon" class="msg" @click="setShowServicePopup(true)"/>

      <van-icon :name="themeImgs.homeMsgIcon" class="msg" @click="handleOpenMsgUrl"/>
    </div>
  </div>
</template>

<script setup>
import {computed, defineEmits, onMounted, ref} from "vue";
import {useStore} from 'vuex'
import {useRouter} from 'vue-router'
import {Html5Qrcode} from "html5-qrcode";
import {Toast} from "vant";

const emit = defineEmits(['show']);

const store = useStore()

const themeImgs = computed(() => store.state.themeImgs)

const token = computed(() => store.state.loginInfos.token)

const $router = useRouter()

onMounted(() => {
  judgeFromZH()
})

// 是否显示扫码---综合集成和第三方嵌套的，不显示， type 1是正常用户， 2是集成用户，2不显示
const showScan = ref(true)

const judgeFromZH = () => {
  if (window.parent.frames['agIframe'] && localStorage.getItem('isFromThird') && store.state.loginInfos.type === 2) {
    showScan.value = false
  } else {
    showScan.value = true
  }
}

//  扫码
const handleOpenScan = () => {
  if (!token.value) {
    store.commit('setLoginRegisterInfo', { flag: true, type: 1 })
    return false
  }
  store.commit('setIsScanShow', true)
}

//  客服
const setShowServicePopup = (flag) => {
  store.commit('setShowServicePopup', flag)
}

//  跳转你站内信
const handleOpenMsgUrl = () => {
  $router.push('/announcement')
}
</script>

<style scoped lang="less">
.home-header-wrap {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 59px;
  padding: 0 10px;
  //margin-bottom: 15px;
  //position: sticky;

  .menu {
    width: 22px;
    height: 22px;
  }

  .logo {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 104px;
  }

  .right-box {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .van-icon {
      width: 22px;
      height: 22px;
    }

    .msg {
      margin-left: 10px;
    }
  }
}
</style>