<script setup>
import {ref, defineProps, defineEmits, onMounted, watch} from 'vue'
import {useStore} from 'vuex'
import {warningIcon} from '@/assets/images/svg/index'

const props = defineProps({
  showPayMessage: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['onClose'])

const store = useStore()

const isShow = ref(false)

watch(() => props.showPayMessage, () => {
  sessionStorage.setItem('showCheckoutCounterMessage', props.showPayMessage ? '1' : '0')
  if (props.showPayMessage) {
  const _showPayMessage = sessionStorage.getItem('showCheckoutCounterMessage') || ''
    isShow.value = _showPayMessage === '1'
  }
})
const handleClose = () => {
  emit('onClose')
  isShow.value = false
}
</script>

<template>
  <van-dialog
      v-model:show="isShow"
      class="pay-message-dialog"
      :show-confirm-button="false"
      @close="handleClose"
  >
    <div class="pay-message-box">
      <div class="pay-message-header">
        <i class="warn-icon" v-html="warningIcon"></i>
        <span>Nhắc nhở quan trọng</span>
      </div>
      <div class="pay-message-content">
        <p>
          Sau khi xác nhận nạp tiền, vui lòng tải lên biên nhận thanh toán và bấm vào xác nhận thanh toán. Nếu bạn không
          thể biên nhận thanh toán, vui lòng liên hệ bộ phận chăm sóc khách hàng trực tuyến.
        </p>
        <div class="warn-text">
          Nếu bạn không tải lên biên nhận thanh toán, lịch sử nạp tiền sẽ không được ghi nhận. Vui lòng liên hệ dịch vụ
          chăm sóc khách hàng trực tuyến 24/7.
        </div>
        <div class="action-btn" @click="handleClose">
          Tôi đã hiểu
        </div>
      </div>
    </div>
  </van-dialog>
</template>

<style scoped lang="less">
.pay-message-box {
  padding: 15px 10px;

  .pay-message-header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;

    span {
      font-family: PingFang SC;
      font-size: 18px;
      font-weight: 500;
      line-height: 26px;
      text-align: center;
      color: #333333;
      margin-left: 5px;
    }
  }

  .pay-message-content {
    background: #fff;
    padding: 15px 10px;
    border-radius: 12px;
    display: flex;
    flex-flow: column;
    align-items: center;

    p {
      font-family: PingFang SC;
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      text-align: center;
      color: rgba(0, 0, 0, 0.8);
    }

    .warn-text {
      width: calc(100vw - 30px);
      background: #FDD431;
      font-family: PingFang SC;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      text-align: center;
      color: #D81313;
      overflow: initial;
      //transform: translateX(-13%);
      padding: 10px 42px;
      border-radius: 64px;
      margin: 10px 0 20px 0;
    }

    .action-btn {
      width: 203px;
      height: 44px;
      margin: auto;
      font-family: PingFang SC;
      font-size: 16px;
      font-weight: 500;
      line-height: 44px;
      text-align: center;
      color: #fff;
      background: #2EBD85;
      border-radius: 8px;
    }
  }
}
</style>

<style lang="less">
.pay-message-dialog {
  width: calc(100% - 80px);
  //height: 478px;
  background: #FDD431;
  overflow: initial;

  .pay-message-header {
    .warn-icon {
      width: 30px;
      height: 30px;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
