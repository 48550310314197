<template>
  <div class="transfer-wrap">
    <van-nav-bar
        :border="false"
        left-arrow
        title="转账"
        @click-left="onClickLeft"
    />
    <div class="transfer-main-wrap">
      <h3>转账信息</h3>
      <van-cell-group class="transfer-form-wrap" inset>
        <van-field v-model="transferParams.currency" :border="false" :formatter="formatterNumber" autocomplete="off" center
                   clearable label="货币数量" placeholder="请输入货币数量"/>
        <van-field v-model="transferParams.address" :border="false" :formatter="formatter" autocomplete="off" center clearable
                   label="转账地址" placeholder="请输入钱包地址"/>
        <van-field v-model="transferParams.pay_password" :border="false" :formatter="formatter" autocomplete="off" center clearable
                   label="支付密码" placeholder="请输入支付密码" type="password"/>
        <van-field :border="false" center label="钱包类型">
          <template #input>
            <div class="select-type-box" @click="showTypeActions=true">
              <span>{{ curTypeName }}</span>
              <van-image :src="themeImgs.commonColorArrowDownIcon"/>
            </div>
          </template>
        </van-field>
      </van-cell-group>
      <div class="comfirm-btn-box">
        <van-button :disabled="btnClock" block @click="handleComfirmTransfer">确认转账</van-button>
      </div>
    </div>
    <!-- 钱包类型 -->
    <van-action-sheet
        v-model:show="showTypeActions"
        :actions="typeActions"
        cancel-text="取消"
        close-on-click-action
        description="选择钱包类型"
        @cancel="showTypeActions=false"
        @select="handleSelectActions"
    />
  </div>
</template>

<script>
import {computed, reactive, ref} from 'vue'
import {doTransfer} from '@/api/api'
import {Toast} from 'vant'
import {useStore} from "vuex";

export default {
  setup() {
    const store = useStore();
    // 点击导航栏左侧返回键/取消按钮
    const onClickLeft = () => {
      history.back()
    }
    // 转账参数
    const transferParams = reactive({
      currency: '',
      address: '',
      pay_password: '',
      type: '',
    })
    // 过滤输入的中文
    const formatter = (value) => value.replace(/[\u4e00-\u9fa5]/g, "");
    // 只能输入整数跟2位小数
    const formatterNumber = (value) => value.match(/^\d*(\.?\d{0,2})/g)[0]
    // 当前钱包类型type
    const curTypeName = ref('请选择钱包类型')
    // 是否显示钱包类型动作面板
    const showTypeActions = ref(false)
    // 钱包类型actions
    const typeActions = [
      {name: '商户', type: 3},
      {name: '用户', type: 1},
      {name: '代理', type: 2}
    ]
    // 选择交易类型
    const handleSelectActions = action => {
      curTypeName.value = action.name
      transferParams.type = action.type
    }
    // 确认转账
    const handleComfirmTransfer = () => {
      if (btnClock.value) return
      if (!transferParams.currency) {
        return Toast.fail('请输入货币数量')
      } else if (!transferParams.address) {
        return Toast.fail('请输入钱包地址')
      } else if (!transferParams.pay_password) {
        return Toast.fail('请输入支付密码')
      } else if (curTypeName.value == '请选择钱包类型') {
        return Toast.fail('请选择钱包类型')
      }
      btnClock.value = true
      doTransfer(transferParams).then(res => {
        btnClock.value = false
        if (res.result == 200000) {
          Toast.success('转账成功')
          transferParams.currency = ''
          transferParams.address = ''
          transferParams.pay_password = ''
          transferParams.type = ''
          curTypeName.value = '请选择钱包类型'
        } else {
          Toast.fail(res.msg)
        }
      }).catch(error => {
        btnClock.value = false
        Toast.fail('操作失败，请检查网络重试或者联系客服')
      })
    }
    // 按钮锁
    const btnClock = ref(false)
    // mapstate--皮肤对应的图片
    const themeImgs = computed(() => store.state.themeImgs)
    return {
      onClickLeft,
      transferParams,
      formatter,
      formatterNumber,
      curTypeName,
      showTypeActions,
      typeActions,
      handleSelectActions,
      handleComfirmTransfer,
      btnClock,
      themeImgs
    }
  }
}
</script>

<style lang="less" scoped>
.transfer-wrap {
  .transfer-main-wrap {
    height: calc(100vh - 46px);
    //background: var(--mainBgColor);

    h3 {
      padding: 24px 0 8px 16px;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: var(--textCommonColor);
    }

    .transfer-form-wrap {
      border-radius: 8px;

      :deep(.van-cell) {
        .select-type-box {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 184px;
          height: 38px;
          background: rgba(255, 255, 255, 0.1);
          border: 1px solid var(--textColor);
          border-radius: 30px;

          .van-image {
            width: 24px;
            height: 24px;
          }
        }
      }
    }

    .comfirm-btn-box {
      margin-top: 20px;
      padding: 0 16px;
      border-radius: 8px;

      .van-button {
        border-radius: 8px;
      }
    }
  }
}
</style>
