<script setup>
import {computed, ref, defineProps, defineEmits} from 'vue'
import { useI18n } from 'vue-i18n'

const props = defineProps({
  currentTab: {
    type: Number,
    default: 0
  },
  buyCount: {
    type: String,
    default: ''
  },
  sellCount: {
    type: String,
    default: ''
  },
  isSelected: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['handleTab', 'onOpen'])

const { t, locale } = useI18n()

const tabsData = computed(() => {
  const buyCount = parseInt(props.buyCount) || ''
  const sellCount = parseInt(props.sellCount) || ''
  return {
    orderCount: '' + buyCount + sellCount,
    tabs: [
      { key: 5, label: 'Mua xu nhanh', count: '' },
      { key: 0, label: 'CTOC mua xu', count: props.buyCount },
      { key: 1, label: t('卖币订单'), count: props.sellCount },
      { key: 2, label: t('挂单'), count: '' },
      { key: 3, label: t('API订单'), count: '' },
      { key: 6, label: 'Đơn bán nhanh', count: '' },
    ]
  }
})

 const currentLabel = computed(() => {
   const find = tabsData.value.tabs.find((item) => props.currentTab == item.key)
   if (find) {
     return find.label
   }
   return tabsData.value.tabs[0].label
 })
// 点击第一层tab
// const currentLabel = ref(tabsData.value.tabs[0].label)

//  选择
const changeFirstTab = (item) => {
  //  选择后，关闭
  // isSelected.value = false
  currentLabel.value = item.label
  emit('handleTab', item)
}
</script>

<template>
  <div class="order-type-tabs">
    <div class="current-order-type-tab" @click.stop="emit('onOpen')">
      <van-badge :content="tabsData.orderCount">
        <span>{{ currentLabel }}</span>
      </van-badge>
      <van-icon
          :class="['tab-arrow', { 'tab-arrow-selected': isSelected }]"
          name="arrow-down"
          color="#fff"
          size="20"
      />
    </div>
    <div v-show="props.isSelected" class="order-type-content">
      <div
          :class="['order-type-content-item', {'tab-item-active': props.currentTab == item.key}]"
          v-for="(item, index) in tabsData.tabs" :key="index">
        <div
            class="tab-item"
            @click="changeFirstTab(item)">
          <van-badge :content="item.count">
            <span>{{ item.label }}</span>
          </van-badge>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
.order-type-tabs {
  padding: 0 15px;
  position: relative;
  .current-order-type-tab {
    //display: flex;
    //align-items: center;
    //justify-content: space-between;
    height: 32px;
    border-radius: 8px;
    background: linear-gradient(90deg, #00C800 0%, #FDD631 100%);
    padding: 0 15px;
    position: relative;
    line-height: 32px;
    span {
      font-family: PingFang SC;
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      color: #fff;
    }
    .tab-arrow {
      position: absolute;
      right: 15px;
      top: 5px;
      transform: rotate(0deg);
      transition: transform 0.3s;
      &.tab-arrow-selected {
        transform: rotate(-90deg);
      }
    }

    .order-count {
      position: absolute;
      right: 40px;
      top: 7px;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      font-style: normal;
      background: #ee0a24;
      border: 1px solid #fff;
      font-size: 12px;
      color: #fff;
      line-height: 18px;
    }
  }
  .order-type-content {
    width: calc(100% - 30px);
    position: absolute;
    top: 36px;
    left: 15px;
    margin-top: 5px;
    background: #fff;
    box-shadow: 0 1px 4px 0 #5E5E5E40;
    border-radius: 8px;
    z-index: 2;
    .order-type-content-item {
      text-align: center;
      padding: 10px 0;
      border-bottom: 1px solid #F3F4F7;
      position: relative;
      &.tab-item-active {
        background: #E9FFF7;
      }
      &:last-child {
        border-bottom: none;
      }
      span {
        font-family: PingFang SC;
        font-size: 16px;
        font-weight: 400;
        line-height: 16px;
        color: #666666;
      }
      .order-item-count {
        position: absolute;
        right: 15px;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        font-style: normal;
        background: #ee0a24;
        border: 1px solid #fff;
        font-size: 12px;
        color: #fff;
        line-height: 18px;
      }
    }
  }
}
</style>