<template>
  <div class="orderDetails-wrap">
    <van-nav-bar
        :border="false"
        left-arrow
        :title="t('订单详情')"
        @click-left="onClickLeft"
    >
      <template #right>
        <a :href="serviceUrl" target="_blank">
          <van-icon :name="themeImgs.commonServiceIcon"/>
        </a>
      </template>
    </van-nav-bar>

    <div class="orderDetails-main-wrap">
      <div v-show="hintMsg.rechargeInfoByBuy && orderDetails.status == 2" class="tips">
        <div class="tips-div">!</div>
        <span>{{ hintMsg.rechargeInfoByBuy }}</span>
      </div>
      <div class="progress-wrap">
        <div :class="{'active': orderDetails.status >= 1}" class="step-item-box">
          <div :style="`background:url(${orderDetails.status >= 1 ? themeImgs.orderDetailsProcessActiveIcon : themeImgs.orderDetailsProcessIcon})`"
               class="step-icon order-icon"></div>
          <span>{{ t('发起订单') }}</span>
        </div>
        <div :class="{'active': orderDetails.status >= 2}" class="step-item-box">
          <div :style="`background:url(${orderDetails.status >= 2 ? themeImgs.orderDetailsProcessActiveIcon : themeImgs.orderDetailsProcessIcon})`"
               class="step-icon order-icon"></div>
          <span>{{ t('卖家确认') }}</span>
        </div>
        <div :class="{'active': orderDetails.status >= 3}" class="step-item-box">
          <div :style="`background:url(${orderDetails.status >= 3 ? themeImgs.orderDetailsProcessActiveIcon : themeImgs.orderDetailsProcessIcon})`"
               class="step-icon order-icon"></div>
          <span>{{ t('凭证已上传') }}</span>
        </div>
        <div :class="{'active': orderDetails.status == 5 || orderDetails.status > 5}" class="step-item-box">
          <div :style="`background:url(${(orderDetails.status == 5 || orderDetails.status > 5) ? themeImgs.orderDetailsProcessActiveIcon : themeImgs.orderDetailsProcessIcon})`"
               class="step-icon order-icon"></div>
          <span v-if="orderDetails.status != 10">{{ t('卖家已打币') }}</span>
          <span v-if="orderDetails.status == 10">{{ t('卖家已暂停') }}</span>
        </div>
      </div>

      <div class="order-detail-wrap">
        <div class="item-box">
          <span>{{ t('订单编号') }}：{{ orderDetails.order_sn }}</span>
          <van-image :src="themeImgs.commonCopyIcon" @click="handleCopy(orderDetails.order_sn)"/>
        </div>
        <!-- 买家发起订单 -->
        <div v-if="orderDetails.status == 1" class="main-detail-box">
          <div class="detail-box">
            <div class="detail-item-box">
              <span class="label">{{ t('买家名字') }}</span>
              <div class="right-box">
                <span>{{ orderDetails.buy_name }}</span>
                <van-image :src="themeImgs.commonCopyIcon" @click="handleCopy(orderDetails.buy_name)"/>
              </div>
            </div>
            <div v-if="orderDetails.buy_pay_type != 3" class="detail-item-box">
              <span class="label">{{ t('买家昵称') }}</span>
              <div class="right-box">
                <span>{{ orderDetails.buy_nick_name }}</span>
              </div>
            </div>
            <div class="detail-item-box">
              <span class="label">{{ t('金额') }}</span>
              <div class="right-box">
                <span>{{ orderDetails.currency }} {{ locale !== 'vi' ? store.state.publicConfig.title : '' }}</span>
              </div>
            </div>
            <div class="detail-item-box">
              <span class="label">{{ t('订单时间') }}</span>
              <div class="right-box">
                <span>{{ orderDetails.created_at }}</span>
              </div>
            </div>
            <div class="detail-item-box">
              <span class="label">{{ t('付款方式') }}</span>
              <div class="right-box">
                <span>{{ orderDetails.buy_pay_type == 1 ? t('支付宝') : orderDetails.buy_pay_type == 2 ? t('微信') : t('银行卡') }}</span>
              </div>
            </div>
            <div v-if="orderDetails.buy_pay_type == 3" class="detail-item-box">
              <span class="label">{{ t('开户行') }}</span>
              <div class="right-box">
                <span>{{ orderDetails.buy_bank_name }}</span>
              </div>
            </div>
            <div v-if="orderDetails.buy_pay_type == 3" class="detail-item-box">
              <span class="label">{{ t('银行卡号') }}</span>
              <div class="right-box">
                <span>{{ orderDetails.buy_card }}</span>
                <van-image :src="themeImgs.commonCopyIcon" @click="handleCopy(orderDetails.buy_card)"/>
              </div>
            </div>
          </div>
        </div>

        <!-- 卖家确认后买家订单详情 -->
        <div v-if="orderDetails.status == 2" class="main-detail-box">
          <div class="detail-box">
            <div class="detail-item-box">
              <span class="label">{{ t('买家名字') }}</span>
              <div class="right-box">
                <span>{{ orderDetails.buy_name }}</span>
                <van-image :src="themeImgs.commonCopyIcon" @click="handleCopy(orderDetails.buy_name)"/>
              </div>
            </div>
            <div v-if="orderDetails.buy_pay_type != 3" class="detail-item-box">
              <span class="label">{{ t('买家昵称') }}</span>
              <div class="right-box">
                <span>{{ orderDetails.buy_nick_name }}</span>
              </div>
            </div>
            <div class="detail-item-box">
              <span class="label">{{ t('金额') }}</span>
              <div class="right-box">
                <span>{{ orderDetails.currency }} {{ locale !== 'vi' ? store.state.publicConfig.title : '' }}</span>
              </div>
            </div>
            <div class="detail-item-box">
              <span class="label">{{ t('付款方式') }}</span>
              <div class="right-box">
                <span>{{ orderDetails.buy_pay_type == 1 ? t('支付宝') : orderDetails.buy_pay_type == 2 ? t('微信') : t('银行卡') }}</span>
              </div>
            </div>
            <div v-if="orderDetails.buy_pay_type == 3" class="detail-item-box">
              <span class="label">{{ t('开户行') }}</span>
              <div class="right-box">
                <span>{{ orderDetails.buy_bank_name }}</span>
              </div>
            </div>
            <div v-if="orderDetails.buy_pay_type == 3" class="detail-item-box">
              <span class="label">{{ t('银行卡号') }}</span>
              <div class="right-box">
                <span>{{ orderDetails.buy_card }}</span>
                <van-image :src="themeImgs.commonCopyIcon" @click="handleCopy(orderDetails.buy_card)"/>
              </div>
            </div>
          </div>
          <p class="timer">
            {{ t('支付剩余时间') }}：
            <van-count-down :time="orderDetails.timer*1000" @finish="handleGetOrderDetails"/>
          </p>
          <div class="detail-box">
            <div class="detail-item-box">
              <span class="label">{{ t('卖家名字') }}</span>
              <div class="right-box">
                <span>{{ orderDetails.sell_name }}</span>
                <van-image :src="themeImgs.commonCopyIcon" @click="handleCopy(orderDetails.sell_name)"/>
              </div>
            </div>
            <div v-if="orderDetails.sell_pay_type != 3" class="detail-item-box">
              <span class="label">{{ t('卖家昵称') }}</span>
              <div class="right-box">
                <span>{{ orderDetails.sell_nick_name }}</span>
              </div>
            </div>
            <div class="detail-item-box">
              <span class="label">{{ t('金额') }}</span>
              <div class="right-box">
                <span>{{ orderDetails.currency }} {{ locale !== 'vi' ? store.state.publicConfig.title : '' }}</span>
              </div>
            </div>
            <div class="detail-item-box">
              <span class="label">{{ t('收款方式') }}</span>
              <div class="right-box">
                <span>{{ orderDetails.sell_pay_type == 1 ? t('支付宝') : orderDetails.sell_pay_type == 2 ? t('微信') : t('银行卡') }}</span>
              </div>
            </div>
            <div v-if="orderDetails.sell_pay_type == 3" class="detail-item-box">
              <span class="label">{{ t('开户行') }}</span>
              <div class="right-box">
                <span>{{ orderDetails.sell_bank_name }}</span>
              </div>
            </div>
            <div v-if="orderDetails.sell_pay_type == 3" class="detail-item-box">
              <span class="label">{{ t('银行卡号') }}</span>
              <div class="right-box">
                <span>{{ orderDetails.sell_card }}</span>
                <van-image :src="themeImgs.commonCopyIcon" @click="handleCopy(orderDetails.sell_card)"/>
              </div>
            </div>
            <div v-if="orderDetails.sell_pay_type != 3" class="voucher" @click="handleOpenCollectionCodeDialog">
              {{ t('点击扫码付款') }}
            </div>
          </div>
          <div class="btn-box">
            <div class="cancel-btn btn" @click="showRemarkDialog=true">{{ t('取消订单') }}</div>
            <router-link :to="`/uploadVoucher?order_sn=${orderDetails.order_sn}`" class="upload-btn btn">{{ t('上传凭证') }}
            </router-link>
          </div>
          <p class="tip">（{{ t('买家上传支付凭证') }}）</p>
        </div>

        <!-- 买家已转账并且上传凭证 -->
        <div v-if="orderDetails.status == 3 || orderDetails.status == 4" class="main-detail-box">
          <div class="detail-box">
            <div class="detail-item-box">
              <span class="label">{{ t('买家名字') }}</span>
              <div class="right-box">
                <span>{{ orderDetails.buy_name }}</span>
                <van-image :src="themeImgs.commonCopyIcon" @click="handleCopy(orderDetails.buy_name)"/>
              </div>
            </div>
            <div v-if="orderDetails.buy_pay_type != 3" class="detail-item-box">
              <span class="label">{{ t('买家昵称') }}</span>
              <div class="right-box">
                <span>{{ orderDetails.buy_nick_name }}</span>
              </div>
            </div>
            <div class="detail-item-box">
              <span class="label">{{ t('金额') }}</span>
              <div class="right-box">
                <span>{{ orderDetails.currency }} {{ locale !== 'vi' ? store.state.publicConfig.title : '' }}</span>
              </div>
            </div>
            <div class="detail-item-box">
              <span class="label">{{ t('付款方式') }}</span>
              <div class="right-box">
                <span>{{ orderDetails.buy_pay_type == 1 ? t('支付宝') : orderDetails.buy_pay_type == 2 ? t('微信') : t('银行卡') }}</span>
              </div>
            </div>
            <div v-if="orderDetails.buy_pay_type == 3" class="detail-item-box">
              <span class="label">{{ t('开户行') }}</span>
              <div class="right-box">
                <span>{{ orderDetails.buy_bank_name }}</span>
              </div>
            </div>
            <div v-if="orderDetails.buy_pay_type == 3" class="detail-item-box">
              <span class="label">{{ t('银行卡号') }}</span>
              <div class="right-box">
                <span>{{ orderDetails.buy_card }}</span>
                <van-image :src="themeImgs.commonCopyIcon" @click="handleCopy(orderDetails.buy_card)"/>
              </div>
            </div>
          </div>
          <p class="timer">{{ t('已转账，等待卖方确认') }}</p>
          <div class="detail-box">
            <div class="detail-item-box">
              <span class="label">{{ t('卖家名字') }}</span>
              <div class="right-box">
                <span>{{ orderDetails.sell_name }}</span>
                <van-image :src="themeImgs.commonCopyIcon" @click="handleCopy(orderDetails.sell_name)"/>
              </div>
            </div>
            <div v-if="orderDetails.sell_pay_type != 3" class="detail-item-box">
              <span class="label">{{ t('卖家昵称') }}</span>
              <div class="right-box">
                <span>{{ orderDetails.sell_nick_name }}</span>
              </div>
            </div>
            <div class="detail-item-box">
              <span class="label">{{ t('金额') }}</span>
              <div class="right-box">
                <span>{{ orderDetails.currency }} {{ locale !== 'vi' ? store.state.publicConfig.title : '' }}</span>
              </div>
            </div>
            <div class="detail-item-box">
              <span class="label">{{ t('收款方式') }}</span>
              <div class="right-box">
                <span>{{ orderDetails.sell_pay_type == 1 ? t('支付宝') : orderDetails.sell_pay_type == 2 ? t('微信') : t('银行卡') }}</span>
              </div>
            </div>
            <div v-if="orderDetails.sell_pay_type == 3" class="detail-item-box">
              <span class="label">{{ t('开户行') }}</span>
              <div class="right-box">
                <span>{{ orderDetails.sell_bank_name }}</span>
              </div>
            </div>
            <div v-if="orderDetails.sell_pay_type == 3" class="detail-item-box">
              <span class="label">{{ t('银行卡号') }}</span>
              <div class="right-box">
                <span>{{ orderDetails.sell_card }}</span>
                <van-image :src="themeImgs.commonCopyIcon" @click="handleCopy(orderDetails.sell_card)"/>
              </div>
            </div>
          </div>

          <div class="view-voucher-btn-outer">
            <div class="view-voucher-btn" @click="checkShowPaymentVoucher">{{ t('查看凭证') }}</div>
          </div>
        </div>

        <!-- 卖家已打币 -->
        <div v-if="orderDetails.status == 5 || orderDetails.status == 9 || orderDetails.status==13"
             class="main-detail-box">
          <div class="detail-box">
            <div class="detail-item-box">
              <span class="label">{{ t('买家名字') }}</span>
              <div class="right-box">
                <span>{{ orderDetails.buy_name }}</span>
                <van-image :src="themeImgs.commonCopyIcon" @click="handleCopy(orderDetails.buy_name)"/>
              </div>
            </div>
            <div v-if="orderDetails.buy_pay_type != 3" class="detail-item-box">
              <span class="label">{{ t('买家昵称') }}</span>
              <div class="right-box">
                <span>{{ orderDetails.buy_nick_name }}</span>
              </div>
            </div>
            <div class="detail-item-box">
              <span class="label">{{ t('金额') }}</span>
              <div class="right-box">
                <span>{{ orderDetails.currency }} {{ locale !== 'vi' ? store.state.publicConfig.title : '' }}</span>
              </div>
            </div>
            <div class="detail-item-box">
              <span class="label">{{ t('付款方式') }}</span>
              <div class="right-box">
                <span>{{ orderDetails.buy_pay_type == 1 ? t('支付宝') : orderDetails.buy_pay_type == 2 ? t('微信') : t('银行卡') }}</span>
              </div>
            </div>
            <div v-if="orderDetails.buy_pay_type == 3" class="detail-item-box">
              <span class="label">{{ t('开户行') }}</span>
              <div class="right-box">
                <span>{{ orderDetails.buy_bank_name }}</span>
              </div>
            </div>
            <div v-if="orderDetails.buy_pay_type == 3" class="detail-item-box">
              <span class="label">{{ t('银行卡号') }}</span>
              <div class="right-box">
                <span>{{ orderDetails.buy_card }}</span>
                <van-image :src="themeImgs.commonCopyIcon" @click="handleCopy(orderDetails.buy_card)"/>
              </div>
            </div>
          </div>
          <div class="detail-box">
            <div class="detail-item-box">
              <span class="label">{{ t('卖家名字') }}</span>
              <div class="right-box">
                <span>{{ orderDetails.sell_name }}</span>
                <van-image :src="themeImgs.commonCopyIcon" @click="handleCopy(orderDetails.sell_name)"/>
              </div>
            </div>
            <div v-if="orderDetails.sell_pay_type != 3" class="detail-item-box">
              <span class="label">{{ t('卖家昵称') }}</span>
              <div class="right-box">
                <span>{{ orderDetails.sell_nick_name }}</span>
              </div>
            </div>
            <div class="detail-item-box">
              <span class="label">{{ t('金额') }}</span>
              <div class="right-box">
                <span>{{ orderDetails.currency }} {{ locale !== 'vi' ? store.state.publicConfig.title : '' }}</span>
              </div>
            </div>
            <div class="detail-item-box">
              <span class="label">{{ t('收款方式') }}</span>
              <div class="right-box">
                <span>{{ orderDetails.sell_pay_type == 1 ? t('支付宝') : orderDetails.sell_pay_type == 2 ? t('微信') : t('银行卡') }}</span>
              </div>
            </div>
            <div v-if="orderDetails.sell_pay_type == 3" class="detail-item-box">
              <span class="label">{{ t('开户行') }}</span>
              <div class="right-box">
                <span>{{ orderDetails.sell_bank_name }}</span>
              </div>
            </div>
            <div v-if="orderDetails.sell_pay_type == 3" class="detail-item-box">
              <span class="label">{{ t('银行卡号') }}</span>
              <div class="right-box">
                <span>{{ orderDetails.sell_card }}</span>
                <van-image :src="themeImgs.commonCopyIcon" @click="handleCopy(orderDetails.sell_card)"/>
              </div>
            </div>
          </div>
        </div>

        <!-- 订单已取消 -->
        <div v-if="(orderDetails.status>5 && orderDetails.status<9) || orderDetails.status==12" class="main-detail-box">
          <div class="detail-box">
            <div class="detail-item-box">
              <span class="label">{{ t('创建时间') }}</span>
              <div class="right-box">
                <span>{{ orderDetails.created_at }}</span>
              </div>
            </div>
            <div class="detail-item-box">
              <span class="label">{{ t('取消时间') }}</span>
              <div class="right-box">
                <span>{{ orderDetails.updated_at }}</span>
              </div>
            </div>
            <div class="voucher">{{ t('订单已取消') }}</div>
          </div>
          <div v-if="orderDetails.content" class="detail-box">
            <div class="detail-item-box">
              <span class="label">{{ t('撤单备注') }}</span>
              <div class="right-box">
                <span>{{ orderDetails.content }}</span>
              </div>
            </div>
          </div>
        </div>

        <!-- 系统冻结 -->
        <div v-if="orderDetails.status === 14" class="main-detail-box">
          <div class="detail-box">
            <div class="detail-item-box">
              <span class="label">{{ t('创建时间') }}</span>
              <div class="right-box">
                <span>{{ orderDetails.created_at }}</span>
              </div>
            </div>
            <div class="detail-item-box">
              <span class="label">{{ t('冻结时间') }}</span>
              <div class="right-box">
                <span>{{ orderDetails.updated_at }}</span>
              </div>
            </div>
            <div class="voucher">{{ t('订单已冻结') }}</div>
            <div v-if="orderDetails.pay_pic === ''" class="upload-btn-center">
              <div class="btn-box">
                <router-link :to="`/uploadVoucher?order_sn=${orderDetails.order_sn}`" class="upload-btn btn">
                  {{ t('上传凭证') }}
                </router-link>
              </div>
            </div>

            <div v-if="orderDetails.pay_pic !== ''" class="view-voucher-btn-outer">
              <div class="view-voucher-btn" @click="checkShowPaymentVoucher">{{ t('查看凭证') }}</div>
            </div>
          </div>
          <div v-if="!orderDetails.pay_pic" class="buy-message">
            {{ hintMsg.certificateInfoByBuy }}
          </div>
        </div>
        <!-- 卖家暂停打币 -->
        <div v-if="orderDetails.status == 10" class="main-detail-box">
          <div class="detail-box">
            <div class="detail-item-box">
              <span class="label">{{ t('创建时间') }}</span>
              <div class="right-box">
                <span>{{ orderDetails.created_at }}</span>
              </div>
            </div>
            <div class="detail-item-box">
              <span class="label">{{ t('冻结时间') }}</span>
              <div class="right-box">
                <span>{{ orderDetails.updated_at }}</span>
              </div>
            </div>
            <div class="voucher">{{ t('卖家暂停打币') }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 卖家收款二维码弹框 -->
    <van-dialog v-model:show="showCollectionCodeDialog"
                class="code-dialog"
                closeOnClickOverlay
                :title="t('卖家收款二维码')"
                :show-confirm-button="false"
    >
      <div :style="`background:url(${themeImgs.commonCodeBgImg})`" class="code-box">
        <van-image :src="collectionCodeData.sell_bank_name"/>
        <span class="name">{{ collectionCodeData.sell_name }}</span>
      </div>
<!--      <div class="code-box">-->
<!--        <van-image :src="collectionCodeData.sell_bank_name"/>-->
<!--        <span class="name">{{ collectionCodeData.sell_name }}</span>-->
<!--      </div>-->
    </van-dialog>

    <!-- 买家查看凭证弹框 -->
    <van-dialog v-model:show="checkcertificateInfoByBuy" :before-close="onBeforeCertificateInfoByBuyClose" title="Biên nhận thanh toán">
      <div class="pay-desc">
        {{ hintMsg.certificateInfoByBuy }}
      </div>
    </van-dialog>

    <!-- 买家付款凭证弹框 -->
    <van-dialog v-model:show="showPaymentVoucherDialog"
                class="code-dialog"
                closeOnClickOverlay
                :title="t('买家付款凭证')"
                :show-confirm-button="false"
    >
      <div :style="`background:url(${themeImgs.commonCodeBgImg})`" class="code-box">
        <van-image :src="orderDetails.pay_pic" @click="openImagePreview(orderDetails.pay_pic)"/>
        <span class="name">{{ orderDetails.buy_name }}</span>
      </div>
<!--      <div class="code-box">-->
<!--        <van-image :src="orderDetails.pay_pic" @click="openImagePreview(orderDetails.pay_pic)"/>-->
<!--        <span class="name">{{ orderDetails.buy_name }}</span>-->
<!--      </div>-->
    </van-dialog>
    <!-- 撤单备注弹框 -->
    <van-dialog v-model:show="showRemarkDialog" show-cancel-button :title="t('是否撤单')" @confirm="handleCancelBuyerOrder">
      <van-field
          v-model="remarkContent"
          autosize
          class="cancel-order-dialog-cell"
          :label="t('撤单原因')"
          maxlength="50"
          :placeholder="t('撤单原因可为空')"
          rows="1"
          show-word-limit
          type="textarea"
      />
    </van-dialog>
  </div>
</template>

<script>
import {computed, onMounted, ref, watchEffect} from 'vue'
import {cancelBuyerOrder, getBuyerOrderDetails, getHint, getSellerCollectionCode} from '@/api/api'
import {ImagePreview, Step, Steps, Toast} from 'vant'
import {onBeforeRouteLeave, useRoute, useRouter} from 'vue-router'
import useClipboard from 'vue-clipboard3'
import {useStore} from 'vuex'
import { useI18n } from 'vue-i18n'

export default {
  components: {
    [Step.name]: Step,
    [Steps.name]: Steps
  },
  setup() {
    const { t, locale } = useI18n()
    const $route = useRoute()
    const $router = useRouter()
    const store = useStore()
    const {toClipboard} = useClipboard()
    const hintMsg = ref({})
    // 点击导航栏左侧返回键/取消按钮
    const onClickLeft = () => {
      if (history.state.back.indexOf($route.path) > -1 || history.state.back.indexOf('uploadVoucher') > -1 || !history.state.back) {
        $router.push('/order')
      } else {
        history.back()
      }
    }
    const handleGetHint = () => {
      getHint().then(res => {
        if (res.result == 200000) {
          hintMsg.value = res.data
        }
      })
    }
    onMounted(() => {
      handleGetHint()
    })
    // 定义在线客服地址
    const serviceUrl = ref(store.state.sysConfig.manage)
    // 获取订单详情
    const handleGetOrderDetails = () => {
      getBuyerOrderDetails({order_sn: $route.query.order_sn}).then(res => {
        if (res.result == 200000) {
          orderDetails.value = res.data.list
          // status：1发起订单 2卖家确认 3买家已转账 4买家上传凭证 5卖家打币 6卖家取消 7买家取消 8系统取消 9系统自动打币 10卖家暂停打币
        } else {
          Toast.fail(res.msg)
        }
      })
    }
    // 当路由参数变化时，页面数据无法更新的时候
    watchEffect(() => {
      if ($route.path == '/buyerOrderDetails') {
        handleGetOrderDetails()
      }
    })
    // 存储订单信息
    const orderDetails = ref({})
    // 复制
    const handleCopy = async (content) => {
      try {
        await toClipboard(content)
        Toast.success(t('复制成功'))
      } catch (e) {
        console.error(e)
      }
    }
    // 当前进度索引值
    const stepActiveIndex = ref(1)
    // 是否显示撤单备注弹框
    const showRemarkDialog = ref(false)
    // 存储撤单备注内容
    const remarkContent = ref('')
    const reqFlag = ref(false)

    // 买家取消订单
    const handleCancelBuyerOrder = () => {
      if (reqFlag.value) return
      reqFlag.value = true
      cancelBuyerOrder({order_sn: orderDetails.value.order_sn, content: remarkContent.value}).then(res => {
        reqFlag.value = false
        if (res.result == 200000) {
          Toast.success(t('撤单成功'))
          handleGetOrderDetails()
        } else {
          Toast.fail(res.msg)
        }
      }).catch(() => {
        reqFlag.value = false
      })
    }
    // 是否显示卖家收款二维码弹框
    const showCollectionCodeDialog = ref(false)
    // 存储卖家收款二维码
    const collectionCodeData = ref({})
    // 打开卖家收款二维码
    const handleOpenCollectionCodeDialog = () => {
      getSellerCollectionCode({order_sn: orderDetails.value.order_sn}).then(res => {
        if (res.result == 200000) {
          showCollectionCodeDialog.value = true
          collectionCodeData.value = res.data.list
        }
      })
    }
    // 是否显示付款凭证弹框
    const showPaymentVoucherDialog = ref(false)
    onBeforeRouteLeave((to, from, next) => {
      if (to.path == "/buyCoin") {
        next('/home')
      } else {
        next()
      }
    })
    // mapstate--皮肤对应的图片
    const themeImgs = computed(() => store.state.themeImgs)
    // 图片预览
    const openImagePreview = (url) => {
      ImagePreview({
        images: [url],
        closeable: true,
      })
    }
    const checkcertificateInfoByBuy = ref(false)
    const checkShowPaymentVoucher = () => {
      if (hintMsg.value.certificateInfoByBuy) {
        checkcertificateInfoByBuy.value = true
      } else {
        onBeforeCertificateInfoByBuyClose('confirm')
      }
    }
    const onBeforeCertificateInfoByBuyClose = (action) => {
      // 点击了确定按钮
      if (action === "confirm") {
        checkcertificateInfoByBuy.value = false
        showPaymentVoucherDialog.value = true
      }
      // 点击了取消按钮
      else {
        checkcertificateInfoByBuy.value = false
      }
    }
    return {
      onBeforeCertificateInfoByBuyClose,
      checkcertificateInfoByBuy,
      onClickLeft,
      orderDetails,
      stepActiveIndex,
      handleCopy,
      showPaymentVoucherDialog,
      handleCancelBuyerOrder,
      showCollectionCodeDialog,
      handleOpenCollectionCodeDialog,
      collectionCodeData,
      handleGetOrderDetails,
      serviceUrl,
      showRemarkDialog,
      remarkContent,
      themeImgs,
      store,
      hintMsg,
      checkShowPaymentVoucher,
      openImagePreview,
      t,
      locale
    }
  }
}
</script>

<style lang="less" scoped>
.orderDetails-wrap {
  height: 100%;
  overflow: hidden;

  :deep(.van-nav-bar) {
    .van-nav-bar__right {
      .van-icon {
        width: 24px;
        height: 24px;

        .van-icon__image {
          width: 24px;
          height: 24px;
        }
      }
    }
  }

  .orderDetails-main-wrap {
    height: calc(100% - 44px);
    padding: 10px 16px 0;
    //background: var(--mainBgColor);
    overflow: scroll;

    .progress-wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 60px;
      padding: 0 41px;
      //background: var(--boxBgColor);
      background: #E3E3E3;
      border-radius: 17px;

      :deep(.van-steps) {
        background: inherit;
        border-radius: 8px;

        .van-step__circle-container {
          background: inherit;

          .van-image {
            width: 24px;
            height: 24px;
          }
        }
      }

      .step-item-box {
        display: flex;
        flex-direction: column;
        align-items: center;

        .step-icon {
          position: relative;
          width: 24px;
          height: 24px;
          margin-bottom: 6px;
          background-size: 100% 100% !important;
        }

        span {
          font-size: 10px;
          //color: var(--textCommonColor);
          color: #979797;
          font-weight: 400;
        }

        &:not(:last-child) {
          .step-icon {
            &:after {
              position: absolute;
              content: '';
              width: 50px;
              height: 0;
              border: 1px solid #D2D2D2;
              top: 12px;
              left: 24px;
            }
          }
        }

        &.active {
          .step-icon {
            &:after {
              //border: 1px solid var(--textColor);
              border: 1px solid #FFF;
            }
          }

          span {
            //color: var(--textColor);
            color: #666666;
            font-weight: 600;
          }
        }
      }
    }

    .tips {
      padding: .2rem;
      background: var(--boxBgColor);
      margin-bottom: .2rem;
      display: flex;

      .tips-div {
        color: var(--textColor);
        display: inline-block;
        border: 1px solid;
        height: 0.4rem;
        border-color: var(--btnBgColor);
        padding: .005rem .2rem;
        font-size: var(--van-font-size-sm);
      }

      span {
        display: inline-block;
        margin-left: .2rem;
        //color: var(--textColor);
        font-size: var(--van-font-size-sm);
        color: red;
      }
    }

    .tips-div {
      border: 1px solid;
      border-color: var(--btnBgColor);
      position: relative;
      border-radius: 2px;
    }

    .tips-div:after,
    .tips-div:before {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      border: 3px solid transparent;
      border-top-color: var(--btnBgColor);
      left: 50%;
      margin-left: -2px;
      bottom: -5px;
    }

    .tips-div:after {
      border-top-color: var(--boxBgColor);
      bottom: -4px;
    }

    .order-detail-wrap {
      margin-top: 18px;

      .item-box {
        display: flex;
        align-items: center;
        margin-bottom: 6px;
        font-family: PingFang SC;
        font-size: 12px;
        font-weight: 500;
        line-height: 16.8px;

        span {
          margin-right: 6px;
        }

        .van-image {
          width: 16px;
          height: 16px;
          //margin-left: 6px;
        }
      }

      .main-detail-box {
        .detail-box {
          margin-bottom: 10px;
          padding: 14px 10px;
          //background: var(--boxBgColor);
          background: #F4F4F4;
          border-radius: 8px;
          font-family: PingFang SC;
          color: #666;

          &:last-child {
            margin-bottom: 0;
          }

          .detail-item-box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 6px;
            //color: var(--textCommonColor);

            &:last-child {
              margin-bottom: 0;
            }

            .label {
              flex: 0 0 40%;
              font-size: 16px;
              font-weight: 500;
            }

            .right-box {
              display: flex;
              align-items: center;

              span {
                font-size: 16px;
                font-weight: 400;
              }

              .van-image {
                width: 16px;
                height: 16px;
                margin-left: 6px;
              }
            }
          }

          .voucher {
            font-weight: 500;
            font-size: 18px;
            //color: var(--textColor);<div data-v-43b98b7c="" class="code-box" style="background: url(&quot;/img/code_bg.3354596c.png&quot;);"><div data-v-43b98b7c="" class="van-image"><img src="https://ants3.mayibaset.net/ag/dev/public/user/collection/2023042796e94469f4421200fe593b144e7a7cd2.png" class="van-image__img"><!----><!----></div><span data-v-43b98b7c="" class="name">阿萨德</span></div>
            //color: #666666;
            text-align: center;
          }
        }

        .timer {
          display: flex;
          align-items: center;
          margin-bottom: 6px;
          font-size: 12px;
          font-weight: 500;
          color: var(--textColor);

          .van-count-down {
            line-height: unset;
            color: var(--textColor);
            font-size: 12px;
            font-weight: 500;
          }
        }

        .btn-box {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 15px;

          .btn {
            width: 104px;
            height: 30px;
            line-height: 30px;
            border-radius: 8px;
            text-align: center;
            font-weight: 500;
            font-family: PingFang SC;
            font-size: 16px;
          }

          .cancel-btn {
            //background: var(--cancelBtnBgColor);
            //color: var(--textColor);
            margin-right: 20px;
            background: rgba(151, 151, 151, .6);
            color: #fff;
          }

          .upload-btn {
            //background: var(--btnBgColor);
            color: #fff;
            background: #2EBD85;
          }
        }

        //.view-voucher-btn {
        //  width: 100%;
        //  height: 44px;
        //  margin-top: 20px;
        //  line-height: 44px;
        //  //background: var(--btnBgColor);
        //  background: #2EBD85;
        //  text-align: center;
        //  border-radius: 8px;
        //  color: #fff;
        //  font-weight: 500;
        //  font-size: 16px;
        //}

        .view-voucher-btn-outer {
          position: relative;
          height: 24px;
          margin-top: 10px;

          .view-voucher-btn {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            //display: inline-block;
            font-family: PingFang SC;
            font-size: 16px;
            font-weight: 500;
            line-height: 22.4px;
            text-align: center;
            color: #535C66;

            &::after {
              content: '';
              position: absolute;
              left: 0;
              bottom: -1px;
              width: 100%;
              height: 1px;
              background: #535C66;
            }
          }
        }

        .tip {
          margin-top: 10px;
          margin-bottom: 30px;
          //color: var(--textCommonColor);
          font-family: PingFang SC;
          font-size: 14px;
          font-weight: 400;
          color: #B2B2B2;
          text-align: center;
        }
      }

    }
  }

  :deep(.van-dialog) {
    &.code-dialog {
      .van-dialog__header {
        //background: var(--orderDialogHeaderBgColor);
        //background: linear-gradient(180deg, #DBDBDB 0%, #C0C0C0 100%);
      }

      .van-dialog__confirm {
        //background: var(--orderDialogHeaderBgColor);
        //color: var(--orderDialogTextColor);
      }
    }

    .van-dialog__header {
      padding: 16px 0;
      font-weight: 500;
      font-size: 18px;
      //color: var(--orderDialogTextColor);
      color: #535C66;
    }

    .van-button__text {
      font-family: PingFang SC;
      font-size: 17px;
      font-weight: 400;
      color: #535C66;
    }

    .code-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 275px;
      background-size: 100% 100% !important;

      .van-image {
        width: 158px;
        height: 158px;
        border-radius: 8px;
      }

      .name {
        margin-top: 20px;
        font-size: 16px;
        color: rgba(83, 92, 102);
      }
    }

    .cancel-order-dialog-cell {
      flex-direction: column;
      background: none;

      textarea::-webkit-input-placeholder {
        color: rgba(83, 92, 102, 0.6);
      }

      textarea:-ms-input-placeholder {
        color: rgba(83, 92, 102, 0.6);
      }

      textarea:-moz-placeholder {
        color: rgba(83, 92, 102, 0.6);
        opacity: 1;
      }

      textarea::-moz-placeholder {
        color: rgba(83, 92, 102, 0.6);
        opacity: 1;
      }

      .van-field__label {
        margin-bottom: 7px;

        label {
          color: #535C66;
          font-family: PingFang SC;
          font-size: 16px;
          font-weight: 500;
          white-space: nowrap;
        }
      }

      .van-field__control {
        color: #535C66;
        font-family: PingFang SC;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
}

.upload-btn-center {
  display: flex;
  justify-content: center;
  margin-top: -10px;
}

.pay-desc {
  padding: 0 5% 5% 5%;
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: rgba(83, 92, 102, 0.9);
}

.buy-message {
  color: red;
  font-size: 14px;
  font-family: "PingFang SC";
  line-height: 24px;
  margin-top: 20px;
}
</style>
