<template>
  <van-dialog v-model:show="show"
              class="withdraw-dialog"
              :show-confirm-button="false"
              :close-on-click-overlay="true"
              @closed="close"
  >
    <div class="title">{{ $t('出售') }}</div>

    <van-tabs animated swipeable v-if="locale !== 'vi'">
      <van-tab :title="$t('RMB')">
        <SellCoin @close="close" />
      </van-tab>

      <van-tab title="USDT">
        <Usdt type="withdraw" />
      </van-tab>
    </van-tabs>

    <SellCoin @close="close" v-else />

  </van-dialog>
</template>

<script setup>
import { defineProps, ref, watch, defineEmits } from 'vue'
import SellCoin from './components/SellCoin'
import Usdt from './components/Usdt'
import {useI18n} from "vue-i18n";

// defineOptions({
//   name: 'Withdraw'
// })
const { locale } = useI18n()

const props = defineProps({
  showWithdraw: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['update:showWithdraw'])

const close = () => {
  emit('update:showWithdraw', false)
}

const show = ref(props.showWithdraw)

watch(() => props.showWithdraw, val => {
  show.value = val
})

</script>

<style lang='less' scoped>
.title {
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
  line-height: 21.78px;
  color: #333;
  text-align: center;
  padding: 15px 0 14px;
}

::v-deep {
  .van-tabs__wrap {
    width: 293px;
    height: 34px;
    padding: 0 2px;
    margin: 0 auto 14px;
    background: linear-gradient(90deg, #01C801 0%, #FCD731 100%);
    border-radius: 6px;

    .van-tabs__nav {
      display: flex;
      align-items: center;
      background: transparent;
    }

    .van-tab__text {
      color: #FFF;
      font-family: PingFang SC;
      font-size: 14px;
      font-weight: 500;
    }

    .van-tab--active {
      width: 134px;
      height: 30px;
      border-radius: 5px;
      background: #09794D;
    }

    .van-tabs__line {
      display: none;
    }

  }
}
</style>

<style lang='less'>
.withdraw-dialog {
  width: 327px;
}
</style>
