<template>
  <div class="order-wrap" @click="handleWrapClick">
    <div class="order-header-box">
      <van-nav-bar
          :border="false"
          left-arrow
          :title="t('订单')"
          @click-left="onClickLeft"
      />
      <OrderType
          :is-selected="isFirstSelected"
          :current-tab="firstTabIndex"
          :buy-count="UntreatedBuyNum"
          :sell-count="UntreatedSellNum"
          @handleTab="changeFirstTab"
          @onOpen="onOpen"
      />

      <!--      <div class="first-tab-box">-->
      <!--        <van-badge :content="UntreatedBuyNum">-->
      <!--          <div :class="{'active':firstTabIndex==0}" class="tab-item" @click="changeFirstTab(0)">{{ t('买单') }}</div>-->
      <!--        </van-badge>-->
      <!--        <van-badge :content="UntreatedSellNum">-->
      <!--          <div :class="{'active':firstTabIndex==1}" class="tab-item" @click="changeFirstTab(1)">{{ t('卖单') }}</div>-->
      <!--        </van-badge>-->
      <!--        <van-badge>-->
      <!--          <div :class="{'active':firstTabIndex==2}" class="tab-item" @click="changeFirstTab(2)">{{ t('挂单') }}</div>-->
      <!--        </van-badge>-->
      <!--        <van-badge>-->
      <!--          <div :class="{'active':firstTabIndex==3}" class="tab-item" @click="changeFirstTab(3)">{{ t('订单') }}</div>-->
      <!--        </van-badge>-->
      <!--        &lt;!&ndash;    比特币 tab start   &ndash;&gt;-->
      <!--        <van-badge>-->
      <!--          <div-->
      <!--              :class="{'active':firstTabIndex==4}"-->
      <!--              class="tab-item"-->
      <!--              @click="changeFirstTab(4)">-->
      <!--            USDT-->
      <!--          </div>-->
      <!--        </van-badge>-->
      <!--        &lt;!&ndash;    比特币 tab end   &ndash;&gt;-->
      <!--      </div>-->

      <div v-if="firstTabIndex<2" class="second-tab-wrap">
        <div :class="{'active':secondTabIndex==4}" class="tab-item" @click="changeSecondTab(4)">{{ t('全部') }}</div>
        <div :class="{'active':secondTabIndex==1}" class="tab-item" @click="changeSecondTab(1)">{{ t('进行中') }}</div>
        <div :class="{'active':secondTabIndex==2}" class="tab-item" @click="changeSecondTab(2)">{{ t('已完成') }}</div>
        <div :class="{'active':secondTabIndex==3}" class="tab-item" @click="changeSecondTab(3)">{{ t('已取消') }}</div>
      </div>
      <!--  快捷买单tab start -->
      <div v-if="firstTabIndex == 5" class="second-tab-wrap">
        <div :class="{'active':sixTabIndex===1}" class="tab-item" @click="changeSixTab(1)">
          Đang mua
        </div>
        <div :class="{'active':sixTabIndex===2}" class="tab-item" @click="changeSixTab(2)">
          Thành công
        </div>
        <div :class="{'active':sixTabIndex===3}" class="tab-item" @click="changeSixTab(3)">
          Thất bại
        </div>
      </div>
      <!--  快捷买单tab end -->

      <div v-if="firstTabIndex==2" class="second-tab-wrap">
        <div :class="{'active':threeTabIndex==''}" class="tab-item" @click="changeThreeTab('')">{{ t('全部') }}</div>
        <div :class="{'active':threeTabIndex==1}" class="tab-item" @click="changeThreeTab(1)">{{ t('出售中') }}</div>
        <div :class="{'active':threeTabIndex==5}" class="tab-item" @click="changeThreeTab(5)">{{ t('交易中') }}</div>
        <div :class="{'active':threeTabIndex==3}" class="tab-item" @click="changeThreeTab(3)">{{ t('已完成') }}</div>
        <div :class="{'active':threeTabIndex==2}" class="tab-item" @click="changeThreeTab(2)">{{ t('已取消') }}</div>
      </div>
      <div v-if="firstTabIndex==3" class="second-tab-wrap">
        <div :class="{'active':fourTabIndex==1}" class="tab-item" @click="changeFourTab(1)">{{ t('充值') }}</div>
        <div :class="{'active':fourTabIndex==2}" class="tab-item" @click="changeFourTab(2)">{{ t('提现') }}</div>
        <div :class="{'active':fourTabIndex==3}" class="tab-item" @click="changeFourTab(3)">{{ t('加款') }}</div>
        <div :class="{'active':fourTabIndex==4}" class="tab-item" @click="changeFourTab(4)">{{ t('扣款') }}</div>
      </div>
      <!--    比特币 下级 tab start   -->
      <div v-if="firstTabIndex==4" class="second-tab-wrap">
        <div :class="{'usdtActive':fiveTabIndex==1}" class="tab-item" @click="changeFiveTab(1)">
          {{ t('USDT充值') }}
        </div>
        <div :class="{'usdtActive':fiveTabIndex==2}" class="tab-item" @click="changeFiveTab(2)">
          {{ t('USDT提现') }}
        </div>
      </div>
      <!--    比特币 下级 tab end   -->

      <!--  快捷卖单tab start -->
      <div v-if="firstTabIndex == 6" class="second-tab-wrap">
        <div :class="{'active':sevenTabIndex===2}" class="tab-item" @click="changeSevenTab(2)">
          Được trả tiền
        </div>
        <div :class="{'active':sevenTabIndex===4}" class="tab-item" @click="changeSevenTab(4)">
          Xác nhận tiền xu
        </div>
        <div :class="{'active':sevenTabIndex===5}" class="tab-item" @click="changeSevenTab(5)">
          thất bại
        </div>
      </div>
      <!--  快捷卖单tab end -->

      <div class="date-wrap">
        <div class="date-box" @click="showDateCalendar=true">
          <div class="date-item-box">
            <van-image :src="themeImgs.commonCalendarIcon"/>
            <span class="date">{{ startDate }}</span>
            <van-image :src="themeImgs.commonArrowDownIcon"/>
          </div>
          <p>{{ t('至') }}</p>
          <div class="date-item-box">
            <van-image :src="themeImgs.commonCalendarIcon"/>
            <span class="date">{{ endDate }}</span>
            <van-image :src="themeImgs.commonArrowDownIcon"/>
          </div>
        </div>
        <van-field
            v-if="fourTabIndex<3"
            v-model="apiParams.order_sn"
            autocomplete="off"
            :placeholder="t('请输入搜索的订单编号')">
          <template #button>
            <van-button size="small" @click="handleSearch">{{ t('搜索') }}</van-button>
          </template>
        </van-field>
      </div>
    </div>

    <div class="order-main-wrap">
      <div class="order-main-box">
        <div v-if="orderList.length>0" class="order-content-wrap">
          <van-list
              v-model:loading="loadingMore"
              :finished="finishedMore"
              :immediate-check="false"
              :finished-text="t('没有更多了')"
              @load="onLoadMore"
          >
            <template v-if="firstTabIndex < 2 || firstTabIndex == 5 || firstTabIndex == 6">
              <router-link
                  v-for="(item,index) in orderList"
                  :key="index"
                  :to="{path: toDetail,query:{order_sn:item.order_sn}}"
                  class="order-item-box">
                <div class="ordersn-box">
                  <span>{{ t('订单编号') }}：{{ item.order_sn }}</span>
                  <van-image :src="themeImgs.commonCopyIcon" @click.prevent="handleCopy(item.order_sn)"/>
                </div>
                <div v-if="firstTabIndex != 5" class="ordersn-box">
                  <span>{{ t('挂单编号') }}：{{ item.order }}</span>
                  <van-image :src="themeImgs.commonCopyIcon" @click.prevent="handleCopy(item.order)"/>
                </div>
                <div class="item-box">
                  <div class="left-box">
                    <span class="amount">
                       <template v-if="firstTabIndex == 5">
                        {{ item.amount }}
                      </template>
                      <template v-else-if="firstTabIndex == 6">
                        {{ item.sell_amount }}
                      </template>
                      <template v-else>
                        {{ item.currency }}
                      </template>
<!--                      {{ firstTabIndex == 5 ? item.amount: item.currency }}-->
                    </span>
                    <span class="unit">
                      {{ locale !== 'vi' ? store.state.publicConfig.title : '₫' }}
                    </span>
                  </div>
                  <div class="right-box">
                    <span class="status">
                      <template v-if="firstTabIndex == 5">
                        {{ quickBuyOrderStatusFilter(item.status) }}
                      </template>
                      <template v-else-if="firstTabIndex == 6">
                        {{ quickSellOrderStatusFilter(item) }}
                      </template>
                      <template v-else>
                        {{ orderStatusFilter(Number(item.status)) }}
                      </template>
<!--                      {{ firstTabIndex == 5 ? quickBuyOrderStatusFilter(item.status) : orderStatusFilter(Number(item.status)) }}-->
                    </span>
                    <span class="date">{{ item.created_at }}</span>
                  </div>
                </div>
              </router-link>
            </template>


            <template v-if="firstTabIndex==2">
              <div v-for="(item,index) in orderList" :key="index" class="order-item-box">
                <div class="ordersn-box">
                  <span>{{ t('订单编号') }}：{{ item.order_sn }}</span>
                  <van-image :src="themeImgs.commonCopyIcon" @click="handleCopy(item.order_sn)"/>
                </div>
                <div class="item-box">
                  <div class="left-box">
                    <span class="amount">{{ item.currency }}</span>
                    <span class="unit" v-if=" locale !== 'vi'">{{ store.state.publicConfig.title }}</span>
                  </div>
                  <div class="right-box">
                    <div class="status-btn-box">
                      <span class="status">{{ hangOrderStatusFilter(Number(item.status)) }}</span>
                      <van-button v-if="item.status==1" :disabled="btnClock" class="cancle-btn" round type="danger"
                                  @click="handleCancelSell(item.id)">{{ t('下架') }}
                      </van-button>
                    </div>
                    <span class="date">{{ item.created_at }}</span>
                  </div>
                </div>
                <div class="ordersn-box">
                  <span>{{ t('总出售') }}：{{ item.count_currency }}</span>
                  <span>{{ t('已完成') }}：{{ item.traded_currency }}</span>
                  <span>{{ t('交易中') }}：{{ item.freeze_currency }}</span>
                </div>
              </div>
            </template>


            <template v-if="firstTabIndex==3">
              <div v-for="(item,index) in orderList" :key="index" class="order-item-box">
                <div v-if="fourTabIndex<3" class="ordersn-box">
                  <span>{{ t('订单编号') }}：{{ item.order }}</span>
                  <van-image :src="themeImgs.commonCopyIcon" @click="handleCopy(item.order_sn)"/>
                </div>
                <div class="item-box">
                  <div class="left-box">
                    <span class="amount">{{ fourTabIndex < 3 ? item.amount : item.currency }}</span>
                    <span class="unit" v-if="locale !== 'vi'">{{ store.state.publicConfig.title }}</span>
                  </div>
                  <div class="right-box">
                    <span v-if="fourTabIndex<3" class="status">{{ apiOrderStatusFilter(Number(item.status)) }}</span>
                    <span v-if="fourTabIndex==3" class="status">{{ t('人工加款') }}</span>
                    <span v-if="fourTabIndex==4" class="status">{{ t('人工扣款') }}</span>
                    <span class="date">{{ item.created_at }}</span>
                  </div>
                </div>
              </div>
            </template>


            <template v-if="firstTabIndex==4">
              <!--    比特币列表   start   -->
              <div v-for="(item,index) in orderList" :key="index" class="order-item-box">
                <div class="ordersn-box">
                                <span>
                                    {{ t('订单号') }}：{{ item.order_sn }}
                                    <van-image
                                        :src="themeImgs.commonCopyIcon"
                                        class="copy-address-icon"
                                        @click="handleCopy(item.order_sn)"
                                    />
                                </span>
                </div>
                <div class="item-box">
                  <div class="left-box">
                    <span class="amount">{{ item.amount }}</span>
                    <span class="currency">USDT</span>
                  </div>

                  <div class="right-box">
                    <div style="display: flex; align-items: center;">
                                        <span :class="{'error-status': item.status === 4}" class="status">
                                            {{ filterBitRechargeStatus(item.status) }}
                                        </span>
                      <van-icon
                          v-show="item.status === 4"
                          :name="themeImgs.orderBitFailIcon"
                          class="fail-icon"
                          @click="handleBitFailDialog(item.content)"
                      />
                    </div>
                    <span class="date">{{ item.created_at }}</span>
                  </div>
                </div>

                <div
                    v-show="fiveTabIndex === 1 || (fiveTabIndex === 2 && item.status === 3)"
                    class="middle-box"
                    @click="handleCertificate(item.pic_data)">
                  {{ t('查看凭证') }}
                </div>
              </div>
            </template>
          </van-list>
        </div>
        <van-empty
            v-else
            :image="themeImgs.commonEmptyImg"
            class="custom-image"
            :description="t('暂无数据') + '～'"
        />
      </div>
    </div>
    <!-- 日历插件 -->
    <van-calendar v-model:show="showDateCalendar" :max-date="maxDate" :min-date="minDate" :show-confirm="false" allow-same-day
                  type="range" @confirm="handleGetStartEndDate"/>
  </div>
</template>

<script setup>
import {computed, onMounted, reactive, ref, watch} from 'vue'
import {
  doCancelSellOrder,
  getApiOrderLists,
  getBitRechargeOrder,
  getBitWithdrawOrder,
  getBuyOrderLists,
  getOrderRemind,
  getSellOrderDealLists,
  getSellOrderLists,
  getQuickOrderLists,
  getQuickSellOrder,
} from '@/api/api'
import moment from 'moment'
import useClipboard from 'vue-clipboard3'
import {Dialog, ImagePreview, Toast} from 'vant'
import {onBeforeRouteLeave, useRoute, useRouter} from 'vue-router'
import {useStore} from 'vuex'
import { useI18n } from 'vue-i18n'
import OrderType from './components/OrderType.vue'

const { t, locale } = useI18n()
const route = useRoute()
const router = useRouter()

//  处理跳转详情链接
const toDetail = computed(() => {
  // console.log('firstTabIndex', firstTabIndex.value)
  if (firstTabIndex.value == 0) {
    return 'buyerOrderDetails'
  }
  if (firstTabIndex.value == 1) {
    return 'sellerOrderDetails'
  }
  //  跳转快捷卖单详情
  if (firstTabIndex.value == 6) {
    return 'quickSellOrderDetails'
  }

  return 'quickBuyOrderDetails'
})
const handleBitFailDialog = (content) => {
  Dialog({
    title: t('失败原因'),
    message: content,
    className: 'take-down-dialog'
  })
}
const store = useStore()

// 存储未处理买单的数量
const UntreatedBuyNum = ref()
// 存储未处理卖单的数量
const UntreatedSellNum = ref()
// 获取订单状态提醒
const handleGetOrderRemind = () => {
  //    name === bitcoin,比特币消息，不需要调用接口
  if (orderRemindObj.value.name !== 'bitcoin') {
    getOrderRemind().then(res => {
      if (res.result == 200000) {
        if (res.data.buy_count > 0) {
          UntreatedBuyNum.value = res.data.buy_count
        } else {
          UntreatedBuyNum.value = ""
        }
        if (res.data.sell_count > 0) {
          UntreatedSellNum.value = res.data.sell_count
        } else {
          UntreatedSellNum.value = ""
        }
      }
    })
  }
}
// 计算属性实现视图更新--新订单提醒
const orderRemindObj = computed(() => store.state.orderRemind)
watch(orderRemindObj, (newValue) => {
  handleGetOrderRemind()
  //  比特币订单的操作
  if (newValue.name === 'bitcoin') {
    if (newValue.type === 1) {
      fiveTabIndex.value = 1
      handleGetBitRechargeOrderList()
    }
    if (newValue.type === 2) {
      fiveTabIndex.value = 2
      handleGetBitWithdrawOrderList()
    }
  }
}, {
  deep: true // name是一个对象，需要进行深度监听
})
// 第一层tab索引值
const firstTabIndex = ref(localStorage.getItem('firstTabIndex') ? localStorage.getItem('firstTabIndex') : route.query.bitType ? 4 : 5)
// 第二层tab索引值
const secondTabIndex = ref(4)
// 第三层tab索引值
const threeTabIndex = ref('')
// 第四层tab索引值
const fourTabIndex = ref(1)

// 比特币tab索引值
const fiveTabIndex = ref(1)

//  快捷买单tab索引
const sixTabIndex = ref(1)

//  快捷卖单tab索引
const sevenTabIndex = ref(2)

//  点击第一层tab
const changeFirstTab = (item) => {
  firstTabIndex.value = item.key
  isFirstSelected.value = false
  secondTabIndex.value = 4
  threeTabIndex.value = ''
  fourTabIndex.value = 1
  fiveTabIndex.value = 1
  sixTabIndex.value = 1
  startDate.value = moment().format("YYYY-MM-DD")
  endDate.value = moment().format("YYYY-MM-DD")
  apiParams.pageSize = 20
  apiParams.order_sn = ''
  orderList.value = []
  finishedMore.value = false
  if (item.key === 4) {
    handleGetBitRechargeOrderList()
  } else if (item.key === 0) {
    handleGetBuyOrderList()
  } else if (item.key === 1) {
    handleGetSellOrderDealList()
  } else if (item.key === 2) {
    handleGetSellOrderList()
  } else if (item.key === 5) {
    handleGetVietnamQuickBuyOrderList()
  } else if (item.key === 6) {
    handleGetVietnamQuickSellOrderList()
  } else {
    handleGetApiOrderList()
  }
}
// 点击第二层tab
const changeSecondTab = id => {
  secondTabIndex.value = id
  startDate.value = moment().format("YYYY-MM-DD")
  endDate.value = moment().format("YYYY-MM-DD")
  apiParams.pageSize = 20
  apiParams.order_sn = ''
  orderList.value = []
  finishedMore.value = false
  if (firstTabIndex.value == 0) {
    handleGetBuyOrderList()
  } else if (firstTabIndex.value == 1) {
    handleGetSellOrderDealList()
  }
}
// 点击第三层tab
const changeThreeTab = id => {
  threeTabIndex.value = id
  startDate.value = moment().format("YYYY-MM-DD")
  endDate.value = moment().format("YYYY-MM-DD")
  apiParams.pageSize = 20
  apiParams.order_sn = ''
  orderList.value = []
  finishedMore.value = false
  handleGetSellOrderList()
}
// 点击第四层tab
const changeFourTab = id => {
  fourTabIndex.value = id
  apiParams.pageSize = 20
  apiParams.order_sn = ''
  orderList.value = []
  finishedMore.value = false
  handleGetApiOrderList()
}

//  点击比特币tab索引
const changeFiveTab = id => {
  fiveTabIndex.value = id
  startDate.value = moment().format("YYYY-MM-DD")
  endDate.value = moment().format("YYYY-MM-DD")
  apiParams.pageSize = 20
  apiParams.order_sn = ''
  orderList.value = []
  finishedMore.value = false
  if (id === 1) {
    handleGetBitRechargeOrderList()
  }
  if (id === 2) {
    handleGetBitWithdrawOrderList()
  }
}

//  点击快捷买单tab索引
const changeSixTab = id => {
  // console.log('id', id)
  sixTabIndex.value = id
  startDate.value = moment().format("YYYY-MM-DD")
  endDate.value = moment().format("YYYY-MM-DD")
  apiParams.pageSize = 20
  apiParams.order_sn = ''
  orderList.value = []
  finishedMore.value = false
  handleGetVietnamQuickBuyOrderList()
}

//  点击快捷卖单tab索引
const changeSevenTab = id => {
  console.log('id', id)
  sevenTabIndex.value = id
  startDate.value = moment().format("YYYY-MM-DD")
  endDate.value = moment().format("YYYY-MM-DD")
  apiParams.pageSize = 20
  apiParams.order_sn = ''
  orderList.value = []
  finishedMore.value = false
  handleGetVietnamQuickSellOrderList()
}

// 是否显示日历插件
const showDateCalendar = ref(false)
// 存储开始日期
const startDate = ref(moment().format("YYYY-MM-DD"))
// 存储结束日期
const endDate = ref(moment().format("YYYY-MM-DD"))
// 日期组件的最小日期--半年前
const minDate = new Date(new Date().setMonth(new Date().getMonth() - 6));
// 日期组件的最大日期--今天
const maxDate = new Date()
// 选择日期区间后
const handleGetStartEndDate = (date) => {
  orderList.value = []
  apiParams.pageSize = 20
  finishedMore.value = false
  const [start, end] = date
  startDate.value = moment(start).format("YYYY-MM-DD")
  endDate.value = moment(end).format("YYYY-MM-DD")
  showDateCalendar.value = false
  if (firstTabIndex.value == 4) {
    if (fiveTabIndex.value === 1) {
      handleGetBitRechargeOrderList()
    }
    if (fiveTabIndex.value === 2) {
      handleGetBitWithdrawOrderList()
    }
  } else if (firstTabIndex.value == 0) {
    handleGetBuyOrderList()
  } else if (firstTabIndex.value == 1) {
    handleGetSellOrderDealList()
  } else if (firstTabIndex.value == 2) {
    handleGetSellOrderList()
  } else if (firstTabIndex.value == 5) {
    handleGetVietnamQuickBuyOrderList()
  } else if (firstTabIndex.value == 6) {
    handleGetVietnamQuickSellOrderList()
  } else {
    handleGetApiOrderList()
  }
}
// 存储订单列表数据
const orderList = ref([])
// 存储接口默认请求参数
const apiParams = reactive({
  type: 1,
  order_sn: '',
  page: 1,
  pageSize: 20,
  start: `${startDate.value} 00:00:00`,
  end: `${endDate.value} 23:59:59`
})
// 获取买家订单列表数据
const handleGetBuyOrderList = (more) => {
  apiParams.type = secondTabIndex.value
  apiParams.start = `${startDate.value} 00:00:00`
  apiParams.end = `${endDate.value} 23:59:59`
  if (more) {
    apiParams.pageSize += 20
  }
  getBuyOrderLists(apiParams).then(res => {
    if (res.result === 200000) {
      if (!more) {
        orderList.value = res.data.list
      } else {
        orderList.value = res.data.list
        loadingMore.value = false
        if (res.data.list.length >= res.data.count) {
          finishedMore.value = true
        }
      }
    }
  }).catch(() => {
    orderList.value = []
    loadingMore.value = false
  })
}
// 获取卖家卖单列表数据
const handleGetSellOrderDealList = (more) => {
  apiParams.type = secondTabIndex.value
  apiParams.start = `${startDate.value} 00:00:00`
  apiParams.end = `${endDate.value} 23:59:59`
  if (more) {
    apiParams.pageSize += 20
  }
  getSellOrderDealLists(apiParams).then(res => {
    if (res.result == 200000) {
      if (!more) {
        orderList.value = res.data.list
      } else {
        orderList.value = res.data.list
        loadingMore.value = false
        if (res.data.list.length >= res.data.count) {
          finishedMore.value = true
        }
      }
    }
  }).catch(() => {
    orderList.value = []
    loadingMore.value = false
  })
}
// 获取卖家挂单列表数据
const handleGetSellOrderList = (more) => {
  apiParams.status = threeTabIndex.value
  apiParams.start = `${startDate.value} 00:00:00`
  apiParams.end = `${endDate.value} 23:59:59`
  delete apiParams.type
  if (more) {
    apiParams.pageSize += 20
  }
  getSellOrderLists(apiParams).then(res => {
    if (res.result == 200000) {
      if (!more) {
        orderList.value = res.data.list
      } else {
        orderList.value = res.data.list
        loadingMore.value = false
        if (res.data.list.length >= res.data.count) {
          finishedMore.value = true
        }
      }
    }
  }).catch(() => {
    orderList.value = []
    loadingMore.value = false
  })
}
// 获取API订单列表数据
const handleGetApiOrderList = (more) => {
  apiParams.type = fourTabIndex.value
  apiParams.start = `${startDate.value} 00:00:00`
  apiParams.end = `${endDate.value} 23:59:59`
  delete apiParams.status
  if (more) {
    apiParams.pageSize += 20
  }
  getApiOrderLists(apiParams).then(res => {
    if (res.result == 200000) {
      if (!more) {
        orderList.value = res.data.list.list
      } else {
        orderList.value = res.data.list.list
        loadingMore.value = false
        if (res.data.list.list.length >= res.data.list.count) {
          finishedMore.value = true
        }
      }
    }
  }).catch(() => {
    orderList.value = []
    loadingMore.value = false
  })
}
//  获取比特币充值订单列表数据
const handleGetBitRechargeOrderList = (more) => {
  apiParams.type = fiveTabIndex.value
  apiParams.start = `${startDate.value} 00:00:00`
  apiParams.end = `${endDate.value} 23:59:59`
  delete apiParams.status
  if (more) {
    apiParams.pageSize += 20
  }
  getBitRechargeOrder(apiParams).then(res => {
    if (res.result == 200000) {
      if (!more) {
        orderList.value = res.data.list
      } else {
        orderList.value = res.data.list
        loadingMore.value = false
        if (res.data.list.length >= res.data.count) {
          finishedMore.value = true
        }
      }
    }
  }).catch(() => {
    orderList.value = []
    loadingMore.value = false
  })
}
//  获取比特币提现订单列表数据
const handleGetBitWithdrawOrderList = (more) => {
  apiParams.type = fiveTabIndex.value
  apiParams.start = `${startDate.value} 00:00:00`
  apiParams.end = `${endDate.value} 23:59:59`
  delete apiParams.status
  if (more) {
    apiParams.pageSize += 20
  }
  getBitWithdrawOrder(apiParams).then(res => {
    if (res.result == 200000) {
      if (!more) {
        orderList.value = res.data.list
      } else {
        orderList.value = res.data.list
        loadingMore.value = false
        if (res.data.list.length >= res.data.count) {
          finishedMore.value = true
        }
      }
    }
  }).catch(() => {
    orderList.value = []
    loadingMore.value = false
  })
}

//  获取越南钱包-快捷买币列表
const handleGetVietnamQuickBuyOrderList = (more) => {
  apiParams.type = sixTabIndex.value
  apiParams.start = `${startDate.value} 00:00:00`
  apiParams.end = `${endDate.value} 23:59:59`
  delete apiParams.status
  if (more) {
    apiParams.pageSize += 20
  }
  getQuickOrderLists(apiParams).then(res => {
    if (res.result === 200000) {
      if (!more) {
        orderList.value = res.data.list
        console.log('orderList.value', orderList.value)
      } else {
        orderList.value = res.data.list
        loadingMore.value = false
        if (res.data.list.length >= res.data.count) {
          finishedMore.value = true
        }
      }
    }
  }).catch(() => {
    orderList.value = []
    loadingMore.value = false
  })
}

//  获取越南钱包-快捷卖币列表
const handleGetVietnamQuickSellOrderList = (more) => {
  console.log('more', more)
  apiParams.type = sevenTabIndex.value
  apiParams.start = `${startDate.value} 00:00:00`
  apiParams.end = `${endDate.value} 23:59:59`
  delete apiParams.status
  if (more) {
    apiParams.pageSize += 20
    finishedMore.value = true
  }
  getQuickSellOrder(apiParams).then(res => {
    if (res.result === 200000) {
      loadingMore.value = false
      if (!more) {
        orderList.value = res.data.list
      } else {
        orderList.value = res.data.list
        // if (res.data.list.length >= res.data.count) {
        //   finishedMore.value = true
        // }
      }
    }
  }).catch(() => {
    orderList.value = []
    loadingMore.value = false
  })
}

onMounted(() => {
  //  这里要默认调快捷买币的接口，还没有接口
  // handleGetOrderRemind()
  if (route.query.type) {
    firstTabIndex.value = route.query.type
  }
  //  比特币tab
  if (firstTabIndex.value == 4) {
    if (route.query.bitType) {
      fiveTabIndex.value = +route.query.bitType
    }

    if (route.query.bitType === '2') {
      handleGetBitWithdrawOrderList()
    } else {
      handleGetBitRechargeOrderList()
    }
    //    提现提交跳转进来
    if (route.query.bitType) {
      fiveTabIndex.value = parseInt(route.query.bitType)
    }
  } else if (firstTabIndex.value == 0) {
    handleGetBuyOrderList()
  } else if (firstTabIndex.value == 1) {
    handleGetSellOrderDealList()
  } else if (firstTabIndex.value == 2) {
    handleGetSellOrderList()
  } else if (firstTabIndex.value == 5) {
    handleGetVietnamQuickBuyOrderList()
  } else if (firstTabIndex.value == 6) {
    handleGetVietnamQuickSellOrderList()
  } else {
    handleGetApiOrderList()
  }
})

// 越南钱包-快捷卖单列表订单状态过滤器
const quickSellOrderStatusFilter = (item) => {
  let str = ''
  switch (item.status) {
    case 2:
      //  支付中
      str = 'Chờ trả'
      break
    case 3:
      str = 'Đã trả'
      break
    case 4:
      //  成功
      str = 'Xác nhận tiền'
      break
    case 5:
      //  失败
      // str = item.voucher ? 'Đơn hàng quá hạn' : 'hủy đơn hàng'
        str = 'Đơn quá hạn'
      break
  }
  return str
}

// 越南钱包-快捷买单列表订单状态过滤器
const quickBuyOrderStatusFilter = (status) => {
  switch (status) {
    case 1:
      //  支付中
      return 'Đang thanh toán'
    case 2:
      //  支付成功
      return 'Thanh toán thành công'
    default:
      //  失败
      return 'Thất bại'
  }
}

// 订单状态过滤器
const orderStatusFilter = (status) => {
  if (status < 5 || status == 10) {
    return t('进行中')
  }
  if (status == 5 || status == 9) {
    return t('已完成')
  }
  if (status == 6 || status == 7 || status == 8) {
    return t('已取消')
  }
  if (status == 12) {
    return t('人工退回')
  }
  if (status == 13) {
    return t('人工打币')
  }
  if (status == 14) {
    return t('系统冻结')
  }
}
// 挂单状态过滤器
const hangOrderStatusFilter = (status) => {
  if (status == 1) {
    return t('出售中')
  }
  if (status == 2) {
    return t('已取消')
  }
  if (status == 3) {
    return t('已完成')
  }
  if (status == 4) {
    return t('已冻结')
  }
  if (status == 5) {
    return t('交易中')
  }
}
// api订单状态过滤器
const apiOrderStatusFilter = (status) => {
  if (status == 1) {
    return t('待支付')
  }
  if (status == 2) {
    return t('成功')
  }
  if (status == 3) {
    return t('失败')
  }
}

//  比特币充值/提现订单状态过滤器
const filterBitRechargeStatus = (status) => {
  if ([1, 2].includes(status)) {
    return t('处理中')
  }
  if (status === 4) {
    return t('拒绝')
  }
  return t('已完成')
}

//  查看凭证
const handleCertificate = (url) => {
  if (!url) {
    return false
  }
  ImagePreview([url])
}
// 上拉加载
const loadingMore = ref(false);
const finishedMore = ref(false);
const onLoadMore = () => {
  if (firstTabIndex.value == 4) {
    if (fiveTabIndex.value == 1) {
      handleGetBitRechargeOrderList(true)
    }
    if (fiveTabIndex.value == 2) {
      handleGetBitWithdrawOrderList(true)
    }
  } else if (firstTabIndex.value == 0) {
    handleGetBuyOrderList(true)
  } else if (firstTabIndex.value == 1) {
    handleGetSellOrderDealList(true)
  } else if (firstTabIndex.value == 2) {
    handleGetSellOrderList(true)
  } else if (firstTabIndex.value == 5) {
    handleGetVietnamQuickBuyOrderList(true)
  } else if (firstTabIndex.value == 6) {
    handleGetVietnamQuickSellOrderList()
  } else {
    handleGetApiOrderList(true)
  }
};
// 复制
const {toClipboard} = useClipboard()
const handleCopy = async (content) => {
  try {
    await toClipboard(content)
    Toast.success(t('复制成功'))
  } catch (e) {
    console.error(e)
  }
}
// 搜索
const handleSearch = () => {
  orderList.value = []
  apiParams.page = 1
  apiParams.pageSize = 20
  finishedMore.value = false
  if (firstTabIndex.value == 4) {
    if (fiveTabIndex.value == 1) {
      handleGetBitRechargeOrderList()
    }
    if (fiveTabIndex.value == 2) {
      handleGetBitWithdrawOrderList()
    }
  } else if (firstTabIndex.value == 0) {
    handleGetBuyOrderList()
  } else if (firstTabIndex.value == 1) {
    handleGetSellOrderDealList()
  } else if (firstTabIndex.value == 2) {
    handleGetSellOrderList()
  } else if (firstTabIndex.value == 5) {
    handleGetVietnamQuickBuyOrderList()
  } else if (firstTabIndex.value == 6) {
    handleGetVietnamQuickSellOrderList()
  } else {
    handleGetApiOrderList()
  }
}
// 下架挂单
const handleCancelSell = id => {
  if (btnClock.value) return
  Dialog.confirm({
    title: t('温馨提示'),
    message: t('确认将该笔订单进行下架吗') + '？',
    className: 'take-down-dialog'
  }).then(() => {
    btnClock.value = true
    doCancelSellOrder({id: id}).then(res => {
      btnClock.value = false
      if (res.result == 200000) {
        Toast.success(t('下架成功'))
        handleGetSellOrderList()
      } else {
        Toast.fail(res.msg)
      }
    }).catch(error => {
      btnClock.value = false
      Toast.fail(t('操作失败，请检查网络重试或者联系客服'))
    })
  }).catch(() => {
    // on cancel
  });
}
onBeforeRouteLeave((to, from, next) => {
  if (to.path.includes('OrderDetails')) {
    window.localStorage.setItem('firstTabIndex', firstTabIndex.value)
    next()
  } else {
    window.localStorage.removeItem('firstTabIndex')
    next()
  }
})
// 按钮锁
const btnClock = ref(false)
// mapstate--皮肤对应的图片
const themeImgs = computed(() => store.state.themeImgs)

const onClickLeft = () => {
  router.push('/home')
}

const isFirstSelected = ref(false)

//  打开
const onOpen = () => {
  isFirstSelected.value = true
}

//  任意地方关闭
const handleWrapClick = () => {
  if (isFirstSelected.value) {
    isFirstSelected.value = false
  }
}
</script>

<style lang="less" scoped>
.order-wrap {
  height: 100%;
  overflow: hidden;

  .order-header-box {
    position: fixed;
    width: 100%;
    height: 224px;
    z-index: 1;
    background: var(--headBgColor);

    .second-tab-wrap {
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin-top: 10px;
      //background: var(--headBgColor);

      .tab-item {
        height: 36px;
        line-height: 36px;
        font-family: PingFang SC;
        font-size: 14px;
        font-weight: 400;
        //color: var(--textCommonColor);
        color: #979797;

        &.active {
          //color: var(--textColor);
          font-weight: 600;
          color: #000;
        }

        &.usdtActive {
          color: #2EBD85;
        }
      }
    }

    .date-wrap {
      padding: 0 15px;

      .date-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 36px;
        padding: 0 20px;
        margin: 12px auto 8px;
        background: linear-gradient(90deg, #00C800 0%, #FDD631 100%);
        border-radius: 17px;
        //color: var(--textCommonColor);
        color: #FFF;

        .date-item-box {
          display: flex;
          align-items: center;

          .van-image {
            width: 16px;
            height: 16px;
          }

          p {
            font-family: PingFangSC;
            font-size: 16px;
            //color: var(--textCommonColor);
            color: #FFF;
          }

          .date {
            margin: 0 4px 0 10px;
            font-size: 14px;
            font-family: 'DINOffcPro';
            //color: var(--textCommonColor);
          }
        }
      }

      .van-cell {
        height: 36px;
        padding: 0 0 0 10px;
        //background: var(--orderSearchNumInputBgColor);
        border-radius: 17px;
        background: rgba(215, 215, 215, 0.3);

        ::v-deep {
          .van-button {
            background: rgba(46, 189, 133, 1);
            font-family: PingFang SC;
            font-size: 14px;
            font-weight: 600;
            color: #FFF;
          }

          .van-field__control {
            height: 36px;
          }
        }
      }

      /*::v-deep {*/
      /*    .van-field__body {*/
      /*        height: 36px;*/
      /*    }*/
      /*    .van-field__control {*/
      /*        width: 248px;*/
      /*        height: 100%;*/
      /*    }*/
      /*    .van-field__clear {*/
      /*        width: 36px;*/
      /*        height: 36px;*/
      /*        display: flex;*/
      /*        align-items: center;*/
      /*        justify-content: center;*/
      /*        position: absolute;*/
      /*        right: 54px;*/
      /*        padding: 0;*/
      /*    }*/
      /*    .van-field__button {*/
      /*        position: absolute;*/
      /*        right: 0;*/
      /*        padding: 0;*/
      /*    }*/
      /*}*/
    }
  }
  .order-main-wrap {
    //height: calc(100vh - 226px);
    padding-top: 224px;
    height: 100%;
    overflow: auto;
    //position: fixed;
    //top: 56px;
    //bottom: 50px;
    //width: 100%;
    //background: var(--mainBgColor);

    //.first-tab-box {
    //  display: flex;
    //  align-items: center;
    //  justify-content: space-between;
    //  width: 327px;
    //  height: 32px;
    //  margin: 0 auto 14px;
    //  //background: var(--boxBgColor1);
    //  background: linear-gradient(90deg, #00C800 0%, #FDD631 100%);
    //  border-radius: 8px;
    //  //color: var(--textCommonColor);
    //  color: #FFF;
    //  font-family: PingFang SC;
    //  font-size: 16px;
    //  font-weight: 400;
    //
    //
    //  .van-badge__wrapper {
    //    text-align: center;
    //
    //    //:deep(.van-badge) {
    //    //  z-index: 1;
    //    //}
    //
    //    .tab-item {
    //      height: 32px;
    //      padding: 0 15px;
    //      border-radius: 6px;
    //      line-height: 32px;
    //    }
    //
    //    &:first-child {
    //      .tab-item {
    //        /*border-radius: 8px 0px 0px 8px;*/
    //      }
    //    }
    //
    //    &:last-child {
    //      .tab-item {
    //        /*border-radius: 0px 8px 8px 0px;*/
    //      }
    //    }
    //
    //    .tab-item.active {
    //      //background: var(--orderTabActiveBgColor);
    //      //color: var(--textColor);
    //      background: #09794D;
    //      color: #FFF;
    //    }
    //  }
    //}

    .order-main-box {
      //height: calc(100vh - 124px);
      //background: var(--mainBgColor);
      //overflow: hidden;
      height: 100%;
      padding: 0 24px 24px;
      .order-content-wrap {
        //height: calc(100vh - 226px);
        //padding: 0 24px 24px;
        //margin-top: 10px;
        //overflow: scroll;

        .order-item-box {
          display: block;
          padding: 10px 0;
          border-bottom: 1px solid rgba(227, 227, 227, 1);
          //color: var(--textCommonColor);
          color: #979797;
          font-family: PingFang SC;
          font-weight: 400;

          &:last-child {
            border-bottom: none;
          }

          .ordersn-box {
            display: flex;
            align-items: center;
            padding: 8px 0;

            span {
              //font-weight: 500;
              margin-right: 7px;
            }

            .van-image {
              width: 16px;
              height: 16px;
            }
          }

          .item-box {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .left-box {
              display: flex;
              align-items: center;

              span {
                line-height: 26px;
              }

              .amount {
                margin-right: 4px;
                font-family: DIN Offc Pro;
                font-size: 20px;
                font-weight: 400;
                color: #000;
              }
            }



            .right-box {
              display: flex;
              flex-direction: column;
              align-items: end;

              .status-btn-box {
                display: flex;
                align-items: center;
                margin-bottom: 10px;
              }

              .status {
                margin-right: 10px;
                font-size: 16px;

                &.error-status {
                  color: red;
                }
              }

              .cancle-btn {
                height: 30px;
                padding: 0 10px;
                font-size: 16px;
              }

              .date {
                opacity: 0.5;
              }

              .fail-icon {
                ::v-deep {
                  .van-icon__image {
                    width: auto;
                    height: auto;
                  }
                }
              }
            }
          }

          .middle-box {
            margin-top: 10px;
            text-align: center;
            color: var(--textColor);
          }
        }
      }
    }
  }

  @media only screen and (min-width: 750px) {
    .order-main-wrap {
      width: 375px;
    }
  }
}

.collection-img {
  width: 100%;

  img {
    width: 100%;
    height: auto;
  }
}
</style>
<style lang="less">
.take-down-dialog {
  .van-dialog__header {
    padding: 16px 0;
    font-weight: 500;
    font-size: 18px;
    color: #535C66;
  }

  .van-dialog__message {
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.6px;
    text-align: center;
    color: rgba(83, 92, 102, 0.9);
  }

  .van-button__text {
    font-family: PingFang SC;
    font-size: 17px;
    font-weight: 400;
    color: #535C66;
  }
}
</style>
