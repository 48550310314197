<script setup>
import {onMounted, ref, computed, onBeforeMount, onActivated} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import {useStore} from 'vuex'
import {getBanner} from '@/api/api'
import Footer from '@/components/Footer.vue'
import SideBar from '@/components/SideBar.vue'
import Banner from '@/viViews/Home/components/Banner.vue'
import Announcement from '@/viViews/Home/components/Announcement.vue'
import Trade from '@/viViews/Home/components/Trade.vue'
import BuySellBtn from '@/viViews/Home/components/BuySellBtn.vue'

const showSideBar = ref(false)

const router = useRouter()
const store = useStore()
// mapstate--皮肤对应的图片
const themeImgs = computed(() => store.state.themeImgs)
//  支付方式选择
const payTypeItems = [
  {
    id: 3,
    icon: themeImgs.value.commonPaymentIconGrayVn3,
    activeIcon: themeImgs.value.commonPaymentIconVn3,
    name: 'Bank',
  },
  {
    id: 2,
    icon: themeImgs.value.commonPaymentIconGrayVn2,
    activeIcon: themeImgs.value.commonPaymentIconVn2,
    name: 'Momo',
  },
  {
    id: 1,
    icon: themeImgs.value.commonPaymentIconGrayVn1,
    activeIcon: themeImgs.value.commonPaymentIconVn1,
    name: 'Zalo',
  }
]

const activeTab = ref(0)

const handleTab = (currentItem) => {
  activeTab.value = currentItem.name
}

// 存储banner数据
const bannerData = ref([])
const handleGetBanner = () => {
  getBanner().then(res => {
    if (res.result === 200000) {
      bannerData.value = res.data.list.filter((item) => item.location === '0')
    }
  })
}

const $route = useRoute()

onActivated(() => {
  handleGetBanner()
  activeTab.value = parseInt($route.query.key || 0)
})
</script>

<template>
  <div class="real-time-trade-wrap">
    <van-nav-bar
        :border="false"
        fixed
        left-arrow
        title="Trang biểu đồ giao dịch"
        @click-left="router.push('/home')"
    />
    <div class="real-time-trade-content">
      <Banner :list="bannerData" />
      <Announcement />
      <Trade :is-home="false" :type="activeTab" />
      <van-tabs
          v-model:active="activeTab"
          class="chart-type-box"
          background="none"
          :line-height="0"
          title-active-color="#000"
          title-inactive-color="rgba(0, 0, 0, 0.6)"
          @click-tab="handleTab">
        <van-tab v-for="(item, index) in payTypeItems">
          <template #title>
            <div
                class="pay-type-tab"
                :class="{'pay-type-tab-active': activeTab === index}">
              <van-icon
                  class="pay-icon"
                  :name="activeTab === index ? item.activeIcon : item.icon"
              />
              <span>{{ item.name }}</span>
            </div>
          </template>
        </van-tab>
      </van-tabs>

      <BuySellBtn />
    </div>
    <Footer/>
    <SideBar v-model:showSideBar="showSideBar" />
  </div>
</template>

<style scoped lang="less">
.real-time-trade-wrap {
  //height: 100%;
  //background: var(--mainBgColor);
  //overflow: hidden;
  .real-time-trade-content {
    //overflow-y: auto;
    //height: calc(100% - 118px);
    //height: 100%;
    padding: 44px 0 74px 0;

    .chart-type-box {
      margin-bottom: 12px;
      .pay-type-tab {
        display: flex;
        align-items: center;
        border-radius: 30px;
        padding: 5px 8px;
        span {
          margin-left: 5px;
        }
        :deep(.van-icon) {
          &.pay-icon .van-icon{
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }
}
</style>