<script setup>
import {ref, watch, defineProps, computed} from 'vue'
import {useStore} from 'vuex'
import { useRouter } from 'vue-router'
import {Toast, Dialog} from 'vant'
import Loading from './Loading.vue'
import { postVoucher, postBuyCancelOrder } from '@/api/api'
import PayOrder from "./PayOrder.vue";
import wsConnection from "@/utils/websocket";

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
  thirdOrder: {
    type: String,
    default: ''
  },
  message: {
    type: String,
    default: ''
  }
})

const store = useStore()

// mapstate--皮肤对应的图片
const themeImgs = computed(() => store.state.themeImgs)

const quickSellNotify = computed(() => store.state.quickSellNotify)

const isShow = ref(false)

//  买家拉单的数据
const sellPayInfo = ref({})

watch(() => quickSellNotify.value, () => {
  // console.log('quickSellNotify.value', quickSellNotify.value)
  isShow.value = !['0'].includes(quickSellNotify.value)
  if (quickSellNotify.value === '1') {
    const payInfo = sessionStorage.getItem('quickSellPayInfo')
    const imgPath = sessionStorage.getItem('voucherImgPath')
    sellPayInfo.value = {
      ...JSON.parse(payInfo),
      imgPath
    }
  }
  if (quickSellNotify.value === '4') {
    handleClose()
  }
}, { immediate: true })

const router = useRouter()

//  关闭窗口
const handleClose = () => {
  // store.commit('setQuickSellNotify', '0')
  clearSessionData()
}

//  取消订单支付
const cancelOrderPay = () => {
  const currentPayInfo = JSON.parse(sessionStorage.getItem('quickSellPayInfo'))
  postBuyCancelOrder({
    id: currentPayInfo.id
  }).then(res => {
    // console.log('取消支付', res)
    if (res.result === 200000) {
      Toast.success('thành công')
      handleClose()
    } else {
      Toast.fail(res.msg)
    }
  })
}

//  支付订单
const confirmOrderPay = () => {
  // store.commit('setQuickSellNotify', '3')
  // isSuccess.value = true
  const voucherPath = sessionStorage.getItem('voucherImgPath')
  // console.log('voucherPath', voucherPath)
  if (!voucherPath) {
    return Toast.fail('Tải lên chứng từ thanh toán')
  }
  postVoucher({
    order_sn: props.thirdOrder,
    voucher: voucherPath
  }).then(res => {
    console.log('res', res)
    if (res.result === 200000) {
      Toast.success('Thanh toán thành công')
      store.commit('setQuickSellNotify', '3')
    } else {
      return Toast.fail(res.msg)
    }
  })
}

//  清除一些信息
const clearSessionData = () => {
  sessionStorage.removeItem('voucherImgPath')
  sessionStorage.removeItem('checkoutCounterData')
  sessionStorage.removeItem('showCheckoutCounterMessage')
  sessionStorage.removeItem('quickSellPayInfo')
  if (sessionStorage.getItem('buyOrderCountDown'))  {
    sessionStorage.removeItem('buyOrderCountDown')
  }
  if (sessionStorage.getItem('voucherFileList')) {
    sessionStorage.removeItem('voucherFileList')
  }

  store.commit('setQuickSellNotify', '0')
  localStorage.removeItem("loginInfos")
  store.dispatch("saveLoginInfosActions", {})
  if(wsConnection.$ws) {
    wsConnection.$ws.close()
  }
  // 针对综合的退出
  if(window.parent.frames['agIframe']) {
    window.parent.postMessage('退出登录', store.state.loginInfos.originUrl)
  }
  router.replace('/checkoutCounterPayStatus')
}

//  提交
const onSubmit = (isSubmit) => {
  if (!isSubmit) {
    Dialog.confirm({
      title: 'Nhắc nhở quan trọng',
      message: 'Vui lòng bấm xác nhận để huỷ thanh toán, Nếu bạn huỷ lệnh nạp tiền sau khi đã chuyển tiền, hành động này sẽ gây mất tiền cho bạn.',
      className: 'second-dialog-box',
      showCancelButton: true,
      cancelButtonColor: '#535C66',
      cancelButtonText: 'Quay lại'
    }).then(() => {
      console.log('确认,取消支付')
      cancelOrderPay()
    }).catch(() => {
      console.log('取消，继续支付')
      confirmOrderPay()
    })
  } else {
    //  直接支付
    confirmOrderPay()
  }
}

</script>

<template>
  <van-dialog
      v-model:show="isShow"
      :class="quickSellNotify === '1' ? 'paying-box-dialog' : 'pay-review-box'"
      :show-confirm-button="true"
      :close-on-click-overlay="['2', '6'].includes(quickSellNotify)"
      @close="handleClose"
  >
    <PayOrder v-if="quickSellNotify === '1'" />

    <Loading
        v-if="['2', '3', '6'].includes(quickSellNotify)"
        :message="props.message"
    />
    <slot name="list"></slot>
    <template #footer>
      <div v-if="quickSellNotify === '1'" class="bottom-action-box">
        <div class="action-btn cancel-btn" @click="onSubmit(false)">
          Hủy bỏ
        </div>
        <div class="action-btn confirm-btn" @click="onSubmit(true)">
          xác nhận
        </div>
      </div>
    </template>
  </van-dialog>
</template>

<style scoped lang="less">
.bottom-action-box {
  width: 100%;
  position: sticky;
  height: 38px;
  bottom: 0;
  display: flex;
  z-index: 2;
  .action-btn {
    color: #fff;
    height: 100%;
    flex: 1;
    text-align: center;
    line-height: 38px;
    &.cancel-btn {
      background: #979797;
      border-top-left-radius: 8px;
    }
    &.confirm-btn {
      background: #2EBD85;
      border-top-right-radius: 8px;
    }
  }
}
</style>

<style lang="less">
.paying-box-dialog {
  max-height: 80vh;
  overflow-y: auto;
  //position: relative;
  //top: 35%;
}
.pay-review-box {
  min-height: 80vh;
  display: flex;
  flex-flow: column;
  justify-content: center;
}
.pay-success-dialog {
  max-height: 80vh;
}

.save-photo-icon .van-icon__image {
  width: 18px;
  height: 18px;
}

.certificate-btn {
  .upload-icon {
    width: 16px;
    height: 16px;

    svg {
      width: 100%;
      height: 100%;

      circle {
        fill: #2BC085;
      }
    }

  }
}


.second-dialog-box {
  .van-dialog__header {
    font-family: PingFang SC;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    color: #2EBD85;
  }
  .van-dialog__message--has-title {
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: rgba(0, 0, 0, 0.6);
  }
}

</style>
