<template>
  <div class="deviceManagement-wrap">
    <van-nav-bar
        :border="false"
        left-arrow
        title="设备管理"
        @click-left="onClickLeft"
    />
    <div class="deviceManagement-main-wrap">
      <div class="deviceManagement-main-box">
        <div class="item">
          <span class="label">其他设备</span>
          <span class="content">设备号：iPhone</span>
          <div class="btn-box">
            <span class="name">iPhone</span>
            <!-- <van-image :src="require('../../assets/images/my/delete_icon.png')" @click="showAction=true" /> -->
          </div>
        </div>
      </div>
    </div>
    <van-action-sheet
        v-model:show="showAction"
        :actions="actions"
        cancel-text="取消"
        close-on-click-action
        description="是否删除该设备？"
        @select="handleComfirmDelete"
    />
  </div>
</template>

<script>
import {ref} from 'vue'

export default {
  setup() {
    // 点击导航栏左侧返回键/取消按钮
    const onClickLeft = () => {
      history.back()
    }
    // 是否弹出
    const showAction = ref(false)
    const actions = [
      {name: '确定删除', color: '#ee0a24'},
    ];
    // 确定删除设备
    const handleComfirmDelete = () => {

    }
    return {
      onClickLeft,
      showAction,
      actions,
      handleComfirmDelete
    }
  }
}
</script>

<style lang="less" scoped>
.deviceManagement-wrap {
  .deviceManagement-main-wrap {
    height: calc(100vh - 54px);
    margin-top: 8px;
    padding: 24px 16px;
    background: #fff;

    .deviceManagement-main-box {
      padding: 0 16px;
      background: #FBF1F0;
      border-radius: 8px;

      .item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 44px;
        margin-bottom: 12px;

        .label {
          font-weight: 500;
          color: rgba(83, 92, 102, 0.9);
        }

        .content {
          font-weight: 500;
          color: #9C5E5C;
        }

        .btn-box {
          display: flex;
          align-items: center;

          .name {
            margin-right: 14px;
            font-weight: 600;
            color: #9C5E5C;
          }

          .van-image {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }
}
</style>
