<template>
  <div class="tradingHall-wrap">
    <div class="tradingHall-search">
      <van-image class="amount-icon" :src="themeImgs.tradingHallAmountIcon" />
      <van-field
          v-model="search.startAmount"
          :placeholder="t('请输入起始金额')"
          type="number"
          @input="(e) => inputChangeUnit(e, 'start')"
      />
      <i class="line"></i>
      <van-field
          v-model="search.endAmount"
          class="max-amount"
          :placeholder="t('请输入最大金额')"
          type="number"
          @input="(e) => inputChangeUnit(e, 'end')"
      />
    </div>

    <div class="tradingHall-main-box">
      <div id="tradingHallMainBox" class="content-box">
        <van-pull-refresh
            v-if="filteredData.length > 0"
            v-model="isRefreshLoading"
            :success-text="t('刷新成功')"
            @refresh="onRefresh"
        >
          <div v-for="(item,index) in filteredData" :key="index">
            <div class="list-box" :id="`listBox${index}`">
              <div class="account-box">
                <van-image :src="getAvatar(item.avatarNew)" round/>
                <span>{{ amountFilter(item.currency) }}</span>
              </div>

              <div class="paytype-box">

                <div class="icon-box">
                  <template v-if="item.pay_type.includes(3)">
                    <van-icon v-if="locale === 'vi'" :name="themeImgs.commonPaymentIconVn3" />
                    <van-icon v-else :name="themeImgs.commonPaymentIcon23" />
                  </template>

                  <template v-else>
                    <van-icon v-if="locale === 'vi'" :name="themeImgs.commonPaymentIconGrayVn3" />
                    <van-icon v-else :name="themeImgs.commonPaymentIconGray23" />
                  </template>
                </div>

                <div class="icon-box">
                  <template v-if="item.pay_type.includes(2)">
                    <van-icon v-if="locale === 'vi'" :name="themeImgs.commonPaymentIconVn2" />
                    <van-icon v-else :name="themeImgs.commonPaymentIcon22" />
                  </template>

                  <template v-else>
                    <van-icon v-if="locale === 'vi'" :name="themeImgs.commonPaymentIconGrayVn2" />
                    <van-icon v-else :name="themeImgs.commonPaymentIconGray22" />
                  </template>
                </div>

                <div class="icon-box">
                  <template v-if="item.pay_type.includes(1)">
                    <van-icon v-if="locale === 'vi'" :name="themeImgs.commonPaymentIconVn1" />
                    <van-icon v-else :name="themeImgs.commonPaymentIcon21" />
                  </template>

                  <template v-else>
                    <van-icon v-if="locale === 'vi'" :name="themeImgs.commonPaymentIconGrayVn1" />
                    <van-icon v-else :name="themeImgs.commonPaymentIconGray21" />
                  </template>
                </div>
              </div>

              <span v-if="locale !== 'vi' && siteName !== 'qtpay'"
                    :class="{'not':item.type == 2}"
                    class="demolition-sale-text"
              >
                  {{ item.type == 1 ? t('可拆售') : t('不可拆售') }}
                </span>

              <div class="buy-btn-box" @click="handleBuy(item, index)">{{ t('购买') }}</div>
            </div>

            <div class="tips-wrapper" :id="`tipsWrapper${index}`" v-show="showIndex === index">
              <div class="triangle"></div>

              <div class="deal-tips">
                <h1>{{ t('交易提示') }}！</h1>
                <p>
                  {{ hintMsg.tradingFloor }}
                </p>
                <div class="tips-btn">
                  <div class="tips-btn-cancel" @click="handleBuy(item, null)">{{ t('取消') }}</div>
                  <div class="tips-btn-confirm" @click="openUrlBuyCoin(item)">{{ t('下一步') }}</div>
                </div>
              </div>
            </div>
          </div>
        </van-pull-refresh>
        <van-empty
            v-if="filteredData.length < 1"
            :image="themeImgs.commonEmptyImg"
            :description="t('当前暂无交易数据') + '～'"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, onMounted, ref, watch, nextTick, onBeforeUnmount, onActivated} from 'vue'
import {useStore} from 'vuex'
import {useRouter} from "vue-router"
import {Toast} from 'vant'
import {getHint, getMemberAmount, getPaymentList, getSellerInfo, getSellList} from '@/api/api'
import {useGetAvatar} from "@hooks";
import { useI18n } from 'vue-i18n'
import bus from '@/utils/mitt'

const siteName = ref(siteChannel)
const { t, locale } = useI18n()
const store = useStore()
const token = computed(() => store.state.loginInfos.token)

const $router = useRouter()
const {getAvatar} = useGetAvatar()
const hintMsg = ref({})
// 点击导航栏左侧返回键
const onClickLeft = () => {
  $router.push('/home')
}

//  搜索金额条件
const search = ref({startAmount: '', endAmount: ''})
//  金额输入限制只允许输入整数
const inputChangeUnit = (e, type) => {
  //    e.target.value.match(/^\d*(\.?\d{0,2})/g)[0] || null
  const amount = e.target.value.match(/^\d+/g) ? e.target.value.match(/^\d+/g)[0] : null
  if (type === 'start') {
    search.value.startAmount = amount
  }
  if (type === 'end') {
    search.value.endAmount = amount
  }
}

// 获取可售余额
const handleGetMemberAmount = () => {
  getMemberAmount().then(res => {
    if (res.result == 200000) {
      availableBalance.value = res.data.info.currency_money
    }
  })
}
const handleGetHint = () => {
  getHint().then(res => {
    if (res.result == 200000) {
      hintMsg.value = res.data
    }
  })
}
onMounted(() => {
  if (token.value) {
    handleGetMemberAmount()
    handleGetPaymentList()
    handleGetHint()

    bus.off('getMyPaymentList')
    bus.on('getMyPaymentList', handleGetPaymentList)
  }
  handleGetSellList()
})

onActivated(() => {
  console.log('交易大厅')
})

onBeforeUnmount(() => {
  bus.off('getMyPaymentList')
})

// 存储可售余额
const availableBalance = ref(0)
// 数量箭头方向是否向上
const isAmountArrowUp = ref(false)
// 出售方式箭头方向是否向上
const isSellWayArrowUp = ref(false)
// 存储售卖列表数据
const sellLists = ref({})

//  执行搜索条件
const returnData = (data, s, e) => {
  const arr = []
  data.forEach(item => {
    const currency = Number(item.currency)
    //  只有开始金额
    if (s !== 0 && e < s) {
      if (s <= currency) {
        arr.push(item)
      }
    }
    //  只有结束金额
    if (s === 0 && e !== 0) {
      if (currency <= e) {
        arr.push(item)
      }
    }

    //  开始金额和结束都存在
    if (s !== 0 && e !== 0) {
      //    开始金额大于结束金额，换位置
      // if (s > e) {
      //   s += e
      //   e = s - e
      //   s -= e
      // }
      if (s <= currency && currency <= e) {
        arr.push(item)
      }
    }
  })
  return arr
}

//  返回数据
const filteredData = computed(() => {
  if (sellLists.value.length > 0) {
    let start = Number(search.value.startAmount) || 0
    let end = Number(search.value.endAmount) || 0

    if (start || end) {
      const data = returnData(sellLists.value, start, end)
      return data
    }
    return sellLists.value
  }
  return []
})

// 获取售卖列表
const handleGetSellList = () => {
  getSellList().then(res => {
    if (res.result == 200000) {
      sellLists.value = Object.values(res.data)
    }
  })
}
// 下拉刷新
const isRefreshLoading = ref(false)
const onRefresh = () => {
  handleGetSellList()
  isRefreshLoading.value = false
}
// 计算属性实现视图更新
const sellInfo = computed(() => store.state.sellInfos)
// watch监听买卖数据的变化,有消息时，不存在的数据，则新增数组对象，存在的数据，则进行相关操作
watch(sellInfo, (newValue) => {
  var bool = sellLists.value.some(item => {
    return item.id == newValue.id
  })
  if (bool) {
    sellLists.value.forEach((item, index) => {
      if (item.id == newValue.id) {
        // 修改
        if (newValue.info_status == 2) {
          item.currency = newValue.currency
        }
        // 删除
        if (newValue.info_status == 3) {
          sellLists.value.splice(index, 1);
        }
      }
    })
  } else {
    // 新增
    if (newValue.info_status !== 3) {
      // sellLists.value.push(newValue)
      sellLists.value.unshift(newValue)
    }
  }
}, {
  deep: true // name是一个对象，需要进行深度监听
})
// 封装排序函数
// function sortArr(attr,rev) {
//     return function(a,b) {
//         // rev为true的时候从大到小
//         if(!!rev) {
//             return b[attr] - a[attr]
//         } else {
//             return a[attr] - b[attr]
//         }
//     }
// }
// // 按出售数量排序
// const handleAmountSort = () => {
//     isAmountArrowUp.value = !isAmountArrowUp.value
//     sellLists.value = sellLists.value.sort(sortArr('currency',isAmountArrowUp.value))
// }
// 按出售方式排序
// const handleSellWaySort = () => {
//     isSellWayArrowUp.value = !isSellWayArrowUp.value
//     sellLists.value = sellLists.value.sort(sortArr('type',isSellWayArrowUp.value))
// }

// 存储卖家信息
const sellerInfos = ref({})
// // 获取卖家卖币信息
const handleGetSellerInfos = async (id) => {
  const {result, data, msg} = await getSellerInfo({id})

  if (result === 200000) {
    sellerInfos.value = data.list
    return true
  } else {
    Toast.fail(msg)
    return false
  }
}
let showIndex = ref(-1)
const handleBuy = (item, index) => {
  if (!token.value) {
    store.commit('setLoginRegisterInfo', { flag: true }) // 打开登录注册弹框
    return
  }
  openUrlBuyCoin(item)
  // const dom1 = document.getElementById('tradingHallMainBox')

  // if (index === null) {
  //   const dom2 = document.getElementById('listBox' + showIndex.value)
  //   showIndex.value = index
  //   scrollToElement(dom1, dom2)
  //   return;
  // }

  // 如果提示语是空，则直接跳转
  // if (hintMsg.value.tradingFloor) {
  //   showIndex.value = index
  //
  //   // if (index !== null) {
  //   //   nextTick(() => {
  //   //     const dom2 = document.getElementById('tipsWrapper' + index)
  //   //     scrollToElement(dom1, dom2)
  //   //   })
  //   // }
  //
  //   nextTick(() => {
  //     const dom2 = document.getElementById('listBox' + showIndex.value)
  //     showIndex.value = index
  //     scrollToElement(dom1, dom2)
  //   })
  //
  // } else {
  //   openUrlBuyCoin(item)
  // }
}
// 跳转买币页面
const openUrlBuyCoin = async item => {
  console.log(item)
  if (JSON.stringify(paymentList.value) == '{}') {
    // $router.push('/addPayment')
    store.commit('setPaymentDialogFlag', true)

    Toast.fail(t('请添加付款方式'))
    return
  } else {
    let buyerPayArr = []
    Object.values(paymentList.value).forEach(item => {
      buyerPayArr.push(item.type)
    })
    // 获取卖家的收款方式type值
    let sellerPayArr = item.pay_type.split(',').map(Number)
    // 判断买家方式是否包含于卖家方式中
    let bool = sellerPayArr.find(item => buyerPayArr.includes(item))
    if (!bool) {
      Toast.fail(t('请添加对应的付款方式'))
      // $router.push('/addPayment')
      store.commit('setPaymentDialogFlag', true)
      return
    }
  }
  if (!await handleGetSellerInfos(item.id)) return


  $router.push({
    path: 'buyCoin',
    query: {
      sellerInfos: JSON.stringify(sellerInfos.value)
    }
  })

  showIndex.value = -1
}
// 存储收付款列表数据
const paymentList = ref({})

// 获取收付款列表数据
const handleGetPaymentList = () => {
  getPaymentList().then(res => {
    if (res.result == 200000) {
      paymentList.value = res.data
    }
  })
}

// mapstate--皮肤对应的图片
const themeImgs = computed(() => store.state.themeImgs)

const scrollToElement = (container, target) => {
  const containerRect = container.getBoundingClientRect();
  const targetRect = target.getBoundingClientRect();
  const scrollTop = targetRect.top - containerRect.top + container.scrollTop;
  container.scrollTop = scrollTop;
}

const amountFilter = (val) => {
  const _val = Number(val)
  if (_val < 1000) {
    return _val
  } else {
    return (_val /1000).toFixed(2) + 'K'
  }
}
</script>

<style lang="less" scoped>
.tradingHall-wrap {
  height: calc(100% - 273px);
  //background: var(--mainBgColor);

  .tradingHall-search {
    display: flex;
    align-items: center;
    //width: 360px;
    height: 36px;
    padding-left: 17px;
    margin: 0 auto 16px;
    background: #DFFBF0;
    border-radius: 6px;
    overflow: hidden;

    .amount-icon {
      flex-shrink: 0;
      width: 14px;
      height: 14px;
      margin-right: 8px;
    }

    .line {
      width: 20px;
      height: 2px;
      background: #535C66;
    }

    ::v-deep {
      .van-cell {
        justify-content: center;
        //width: 130px;
        height: 34px;
        padding: 0;
        background: #DFFBF0;

        &.max-amount {
          padding-left: 25px;
        }

        .van-field__control {
          //width: 160px;
          height: 34px;
          //text-align: center;
          font-family: Inter;
          font-size: 14px;
          font-weight: 400;
          line-height: 14px;

          &::placeholder {
            font-family: PingFang SC;
            color: rgba(0, 0, 0, 0.3);
          }
        }
      }
    }
  }

  .tradingHall-main-box {
    height: calc(100% - 52px);
    //padding: 0 12px;
    background: var(--tradingBoxBgColor);
    border-radius: 8px;

    .content-box {
      height: 100%;
      //height: calc(100% - 41px);
      overflow-y: scroll;

      .van-pull-refresh {
        min-height: 100%;
      }

      .list-box {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 72px;
        padding: 0 8px 0 11px;
        margin-bottom: 10px;
        background: rgba(218, 218, 218, .2);
        border-radius: 8px;

        .account-box {
          position: absolute;
          left: 11px;
          display: flex;
          align-items: center;

          :deep(.van-image) {
            width: 40px;
            height: 40px;
            margin-right: 8px;
          }

          span {
            font-family: Roboto;
            font-size: 16px;
            font-weight: 500;
            color: rgba(0, 0, 0, 1);
          }
        }

        .paytype-box {
          display: flex;
          align-items: center;
          width: 73px;
          //flex: 0 0 37%;

          .icon-box {
            width: 20px;
            height: 20px;
            margin-right: 6px;

            &:last-of-type {
              margin-right: 0;
            }
          }

          :deep(.van-icon) {
            .van-icon__image {
              width: 20px;
              height: 20px;
            }
          }
        }

        .demolition-sale-text {
          width: 48px;
          margin-left: 6px;
          font-family: Roboto;
          font-size: 12px;
          font-weight: 400;
          //&.not {
          //  color: var(--textCommonColor);
          //}
        }

        .buy-btn-box {
          width: 54px;
          height: 30px;
          margin-left: 20px;
          border-radius: 6px;
          font-family: Inter;
          font-size: 14px;
          font-weight: 500;
          color: #FFF;
          line-height: 30px;
          text-align: center;
          background: rgba(46, 189, 133, 1);
        }
      }

      .deal-tips {
        width: 95%;
        height: auto;
        margin: 0 auto;
        padding: 18px;
        background: var(--tradingDealTipsBgColor);
        border-radius: 18px;

        h1 {
          font-weight: 700;
          //color: var(--textColor);
          color: #2EBD85;
          font-size: 18px;
        }

        p {
          margin-top: 18px;
          font-size: 14px;
        }

        .tips-btn {
          display: flex;
          text-align: center;
          justify-content: right;
          margin-top: 36px;

          .tips-btn-confirm {
            flex: 0 0 62px;
            height: 30px;
            border-radius: 6px;
            background: rgba(46, 189, 133, 1);
            line-height: 30px;
            text-align: center;
            margin-left: 20px;
            font-family: Inter;
            font-size: 14px;
            font-weight: 500;
            color: #FFF;
          }

          .tips-btn-cancel {
            flex: 0 0 62px;
            width: 62px;
            height: 30px;
            border-radius: 8px;
            line-height: 30px;
            border: 1px solid;
            border-color: var(--btnBgColor);
            text-align: center;
            color: var(--textColor);
            font-weight: 500;
            font-size: 14px;
          }
        }
      }

      .tips-wrapper {
        margin-bottom: 10px;

        .triangle {
          position: relative;
          content: '';
          bottom: 0;
          left: 80%;
          width: 0;
          height: 0;
          border-right: 10px solid transparent;
          border-left: 10px solid transparent;
          border-bottom: 10px solid;
          border-bottom-color: var(--tradingDealTipsBgColor);
        }
      }
    }
  }
}
</style>
